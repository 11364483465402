<template>
    <div>
        <Breadcrumbs main="HR" submain="Training Management" title="Training Type"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">

                        <div class="card-header">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i> 
                                <h7>Add New Training Type</h7>
                            </button>
                        </div>
            
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="training_type_list"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(status)="data">
                                        <span v-if="data.item.status == true" @click="updateTrainingTypeStatus(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="success">Active</b-badge>
                                        </span>
                                        <span v-if="data.item.status == false"  @click="updateTrainingTypeStatus(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="danger">Inactive</b-badge>
                                        </span>
                                    </template>

                                    <template #cell(description)="data">
                                        <span>
                                            <div>{{ data.item.description.substring(0, 100) }}</div>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteTrainigType(data.item.id)" title="Delete"></feather>
                                            <div @click="editType(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Training Type Create Modal -->
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Add Training Type"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    label="Title"
                    label-for="name-input"
                >
                    <b-form-input v-model="training_type.name" placeholder="Enter training type"></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Description"
                    label-for="name-input"
                >
                    <b-form-textarea id="textarea-auto-height" rows="3"  max-rows="8" v-model="training_type.description" placeholder="Enter training description"></b-form-textarea>
                </b-form-group>

                <b-form-group
                    label="Status"
                    label-for="name-input"
                >
                <b-form-select v-model="training_type.status" :options="statusOptions"></b-form-select>
                </b-form-group>
            </form>
        </b-modal>

        <!-- Training Type Edit Modal -->
        <b-modal
            id="modal-prevent-closing-edit"
            ref="modal"
            title="Edit Training Type"
            @show="resetEditModal"
            @hidden="resetEditModal"
            @ok="handleEditOk"
        >
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <b-form-group
                    label="Title"
                    label-for="name-input"
                >
                    <b-form-input v-model="edit_training_type.name" placeholder="Enter training type"></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Description"
                    label-for="name-input"
                >
                    <b-form-textarea id="textarea-auto-height" rows="3"  max-rows="8" v-model="edit_training_type.description" placeholder="Enter training description"></b-form-textarea>
                </b-form-group>

                <b-form-group
                    label="Status"
                    label-for="name-input"
                >
                <b-form-select v-model="edit_training_type.status" :options="statusOptions"></b-form-select>
                </b-form-group>
            </form>
        </b-modal>


         <!-- Delete Modal -->
         <b-modal 
            id="modal-center" 
            centered 
            hide-header
            @ok="handleDeleteOk"
        >
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this training type?</div>
        </b-modal>
    </div>
</template>
  
<script>

import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'name', label: 'Title', sortable: true },
                { key: 'description', label: 'Description', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'action', label: 'Action', sortable: false }
            ],
    
        
            statusOptions: [{
                    text: 'Active',
                    value: true
                }, 
                {
                    text: 'Inactive',
                    value: false
                } 
            ],
        
            training_type: {
                name: '',
                status: '',
                description: ''
            },

            edit_training_type:{
                id:'',
                name: '',
                status: '',
                description: ''
            },

            delete_value:null,
            udate_value:null,

            filter: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState({
            training_type_list: state => state.training.training_type
        }),
        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },

    mounted() {
        this.$store.dispatch("training/fetchTrainingType");
        this.totalRows = this.items.length;
    },

    methods: {
        onFiltered(filteredItems) {
            
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.training_type.name = ''
            this.training_type.description = ''
            this.training_type.status = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        
        handleSubmit() {
            this.$store.dispatch("training/addTrainingType", this.training_type).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.training_type.name = '',
                    this.training_type.description = '',
                    this.training_type.state = ''
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

          
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editType(type) {
            this.edit_training_type.id = type.id
            this.edit_training_type.name = type.name
            this.edit_training_type.description = type.description
            this.edit_training_type.status = type.status
        },

        resetEditModal() {
          this.edit_training_type.name = '',
          this.edit_training_type.description = '',
          this.edit_training_type.status= ''
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },
        
        handleEditSubmit() {
            this.$store.dispatch("training/updateTrainingType", {

            id: this.edit_training_type.id,
            name: this.edit_training_type.name,
            description: this.edit_training_type.description,
            status:this.edit_training_type.status

            }).then(response => {

            if (response.code == 200) {
                Swal.fire({
                    title: "Success!",
                    text: response.response,
                    icon: "success"
                });

                    this.edit_item.phone = '',
                    this.edit_item.company = '',
                    this.edit_item.address = ''

            } else {
                Swal.fire({
                    title: "Error!",
                    text: response.response,
                    icon: "error"
                });
            }
})
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
        deleteTrainigType(id){
            this.delete_value = id
        },
        handleDeleteOk(){
            this.$store.dispatch("training/deleteTrainigType", this.delete_value).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        updateTrainingTypeStatus(id){
            this.udate_value = id
            this.$store.dispatch("training/updateTrainingTypeStatus", this.udate_value).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.udate_value = null
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
    }
};
</script>
  