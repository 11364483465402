<template>
    <div>
        <Breadcrumbs main="Procurement" submain="RFP" title="RFP Category"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">

                        <div class="card-header header-mobile"> 
                            <router-link to="/procurement/rfp/list">
                                <button class="btn btn-primary" type="submit" style="display: flex; gap: 12px; align-items: center;">
                                    <i class="fa fa-light fa-arrow-left"></i> 
                                    <h7>Back</h7>
                                </button>
                            </router-link>

                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i> 
                                <h7>Add New Category</h7>
                            </button>
                        </div>
            
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteCategory(data.item.id)" title="Delete"></feather>
                                            <!-- <div @click="editCategory(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div> -->
                                        </span>
                                    </template>
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- RFP Category Create Modal -->
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Add RFP Category"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    label="Title"
                    label-for="name-input"
                >
                    <b-form-input v-model="category.title" placeholder="Enter category title"></b-form-input>
                </b-form-group>
            </form>
        </b-modal>

        <!-- RFP Category Edit Modal -->
        <b-modal
            id="modal-prevent-closing-edit"
            ref="modal"
            title="Edit RFP Category"
            @show="resetEditModal"
            @hidden="resetEditModal"
            @ok="handleEditOk"
        >
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <b-form-group
                    label="Title"
                    label-for="name-input"
                >
                    <b-form-input v-model="edit_category.title" placeholder="Enter category title"></b-form-input>
                </b-form-group>
            </form>
        </b-modal>

        <!-- Delete Modal -->
        <b-modal 
            id="modal-center" 
            centered 
            hide-header
            @ok="handleDeleteOk"
        >
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this category?</div>
        </b-modal>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'title', label: 'Title', sortable: true },
                { key: 'action', label: 'Action', sortable: false }
            ],
        
            category: {
                title: ''
            },

            edit_category: {
                title: ''
            },

            filter: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState({
            items: state => state.rfp.rfp_category,
        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },

    mounted() {
        this.$store.dispatch("rfp/fetchRFPCategory")

        this.totalRows = this.items.length;
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.category.title = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        
        handleSubmit() {
            this.$store.dispatch("rfp/addRFPCategory", this.category).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editCategory(type) {
            this.edit_category.title = type.title
        },

        resetEditModal() {
            this.edit_category.title = ''
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        
        handleEditSubmit() {
            alert(this.edit_category)

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        deleteCategory(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("rfp/deleteRFPCategory", this.delete_value).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
    }
};
</script>

<style scoped>
.header-mobile {
    display: flex; 
    justify-content: space-between; 
    align-items: center;
}


@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex; 
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}
</style>
  