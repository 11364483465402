<template>
    <div>
        <Breadcrumbs main="HR" submain="Asset Management" title="Asset Type" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
    
                        <div class="card-header" style="max-width: fit-content">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i>
                                <h7>Add New Asset Type</h7>
                            </button>
                        </div>
    
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
    
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
    
                                </b-col>
    
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
    
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="asset" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
    
    
                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteAssetType(data.item.id)" title="Delete"></feather>
                                            <div @click="editType(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
    
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    
        <!-- Leave Type Create Modal -->
        <b-modal id="modal-prevent-closing" ref="modal" title="Add Leave Type" @show="resetModal" @hidden="resetModal" @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Title" label-for="name-input">
                    <b-form-input v-model="asset_type.name" placeholder="Enter Asset Type"></b-form-input>
                </b-form-group>
              
            </form>
        </b-modal>
    
        <!-- Asset Type Edit Modal -->
    
        <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Asset Type" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <b-form-group label="Title" label-for="name-input">
                    <b-form-input v-model="edit_asset_type.name" placeholder="Enter Asset Type"></b-form-input>
                </b-form-group>    
            </form>
        </b-modal>
        
        <!-- Asset type delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this asset type?</div>
        </b-modal>
    </div>
    </template>
    
    <script>
    import Datepicker from 'vuejs-datepicker';
    import Swal from 'sweetalert2/dist/sweetalert2.js'
    import 'sweetalert2/src/sweetalert2.scss'
    import moment from "moment";
    import {
        mapState
    } from 'vuex';
    
    export default {
        data() {
            return {
    
                tablefields: [{
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'name',
                        label: 'Name',
                        sortable: true
                    },
                   
                    {
                        key: 'action',
                        label: 'Action',
                        sortable: false
                    }
                ],
    

                delete_value: '',
    
                asset_type: {
                    name: '',
                  
                },
    
                edit_asset_type: {
                    id: '',
                    name: '',
                  
                },
    
                filter: null,
    
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 15],
    
                temp_quotation: {}
            };
        },
    
        computed: {
            ...mapState({
                asset: state => state.asset.asset_type,
    
            }),
            sortOptions() {
                // Create an options list from our fields
                return this.tablefields
                    .filter(f => f.sortable)
                    .map(f => {
                        return {
                            text: f.label,
                            value: f.key
                        };
                    });
            }
        },
    
        mounted() {
            this.$store.dispatch("asset/fetchAssetType").then(() => {
                this.items = this.asset
                this.totalRows = this.items.length;
    
            });
    
        },
    
        methods: {
    
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
    
            resetModal() {
                this.asset_type.name = ''
              ''
            },
    
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
    
            handleSubmit() {
    
                this.$store.dispatch("asset/addAssetType", this.asset_type).then(response => {
    
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.asset_type.name=''
                         
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
    
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            },
    
            editType(type) {
              

                this.edit_asset_type.id = type.id
                this.edit_asset_type.name = type.name
            },
    
            resetEditModal() {
                this.edit_asset_type.id = ''
                this.edit_asset_type.name = ''
            },
    
            handleEditOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleEditSubmit()
            },
    
            handleEditSubmit() {
                this.$store.dispatch("asset/updateAssetType", {
                    id: this.edit_asset_type.id,
                    name: this.edit_asset_type.name,
                  
    
                }).then(response => {
    
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.edit_leave.name = '',
                            this.edit_leave.days = '',
                            this.edit_leave.active = ''
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
    
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing-edit')
                })
            },
    
            deleteAssetType(id) {
                this.delete_value = id
            },
            handleDeleteOk() {
                this.$store.dispatch("asset/deleteAssetType", this.delete_value).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.delete_value = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }
        }
    };
    </script>
    