import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import department from './department';
import dist from "vue-sweetalert2/dist";


const keyList = {
    smsUpdate : 'DEPARTMENT_UPDATE',
    smsDelete : 'DEPARTMENT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        designation: [],
        designation_detail: {},
        designation_by_department:[],

        designationLoading: false,
        designationError: false,


        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_DESIGNATION (state, designation) {
            state.designation = designation;
        },
        SET_DESIGNATION_BY_DEPARTMENT (state, designation) {
            state.designation_by_department = designation;
        },
        SET_DESIGNATION_DETAILS (state, designation) {
            state.designation_detail = designation;
        },



        DESIGNATION_LOADING (state, loading) {
            state.designationLoading = loading;
        },
        DESIGNATION_ERROR (state, loading) {
            state.designationError = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateDesignation ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,

                async () => {
                    response= await axios.patch(V1API.update_designation+payload.id,{title:payload.title, department:payload.department},
                        config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchDesignations');

                }
            )
            return response
        },



        async deleteDesignation ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_designaton + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchDesignations');

            return response
        },



        async fetchDesignations ({ commit }) {
            commit('DESIGNATION_LOADING', true);
            commit('DESIGNATION_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_all_designations, config);
                let response = result.data.data

                commit('SET_DESIGNATION', response);

            } catch(error) {
                commit('DESIGNATION_ERROR', true);
            }
            commit('DESIGNATION_LOADING', false);
        },
      
        async fetchDesignationsbyDepartment ({ commit },payload) {
            commit('DESIGNATION_LOADING', true);
            commit('DESIGNATION_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_designation_by_depratment+payload, config);
                let response = result.data.data

                commit('SET_DESIGNATION_BY_DEPARTMENT', response);

                return response

            } catch(error) {
                commit('DESIGNATION_ERROR', true);
            }
            commit('DESIGNATION_LOADING', false);
        },    

        async fetchDesignationDetails ({ commit }, payload) {
            commit('DESIGNATION_LOADING', true);
            commit('DESIGNATION_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.designation_permission+'/'+payload, config);
                let response = result.data.data

                commit('SET_DESIGNATION_DETAILS', response);

            } catch(error) {
                commit('DESIGNATION_ERROR', true);
            }
            commit('DESIGNATION_LOADING', false);
        },



        async toggleDesignationPermission ({ commit, dispatch }, payload) {
            commit('DESIGNATION_LOADING', true);
            commit('DESIGNATION_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.toggle_designation_permission+'/'+payload.designation+'/'+payload.permission, config);
                let response = result.data.data

                dispatch("fetchDesignationDetails", payload.designation);

            } catch(error) {
                commit('DESIGNATION_ERROR', true);
            }
            commit('DESIGNATION_LOADING', false);
        },





        async addDesignation ({commit, dispatch}, payload) {
            commit('DESIGNATION_LOADING', true);
            commit('DESIGNATION_ERROR', false);
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            try{
                let response = await axios.post(V1API.create_designation, payload, config).then(result=>{

                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchDesignations')
                return response

            }catch (e) {

                commit('DESIGNATION_ERROR', true);
            }finally {

                commit('DESIGNATION_LOADING', false);
            }
        }
    },

    getters: {}
}
