import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'BILL_UPDATE',
    smsDelete : 'BILL_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        bill: [],
        bill_detail: {},
        billPaymentList:[],
        bill_replies:{},
        
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_BILL (state, item) {
            state.bill = item;
        },
        SET_BILL_REPLY(state, replies) {
            state.bill_replies = replies;
        },

        SET_BILL_PAYEMNT_LIST_BY_BILL(state,list){
            state.billPaymentList = list
        },

        SET_BILL_DETAIL (state, item) {
            state.bill_detail = item;
        },

        SUBMIT_LOADING (state, loading) {
            state.projectLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateBill ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try {
                payload.bill.details=JSON.stringify(payload.bill.details)
                let data = new FormData()
                let payloadData = buildFormData(data, payload.bill);
                let response = await axios.patch(V1API.edit_bill + payload.id, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchBill')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteBill ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_bill + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchBill');

            return response
        },

        async fetchBill ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_bill, config);
                let response = result.data

                commit('SET_BILL', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },

        async fetchBillDetail ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_bill_details + payload, config);
                let response = result.data

                commit('SET_BILL_DETAIL', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },

        async addBill ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{

                payload.details=JSON.stringify(payload.details)

                let data2= new FormData()
                let payloadData = buildFormData(data2, payload);

                let response = await axios.post(V1API.create_bill, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchBill')
                return response

            }catch (e) {
                console.log(e)
                commit('SUBMIT_ERROR', true);
            }finally {
                // console.log('jgdfjg - 2')
                commit('SUBMIT_LOADING', false);
            }
        },
        async addBillAttachment ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };
            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.attachment);
        
                let response = await axios.post(V1API.add_bill_attachment, payloadData, config).then(result=>{
                  
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch("fetchBillDetail",payload.bill_id)
                return response

            }catch (e) {
               
                commit('SUBMIT_ERROR', true);
            }finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },
        async deleteAttachmentFromBill ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_bill_attahment + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchBillDetail',payload.bill_id)
            
            return response
        },

        // Bill Record
        async recordPayment ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.record_bill_payment, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
              
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async fetchBillPaymentByBill ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.bill_payment_list_by_bill + payload, config);
                let response = result.data

                commit('SET_BILL_PAYEMNT_LIST_BY_BILL', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },

        async updatePaymentStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.bill_payment_list_status_change+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data

                })
                dispatch("fetchBillPaymentByBill",payload.detail_id)
                return response
            } catch (e) {

                commit('SUBMIT_ERROR', true);
            } finally {

                commit('SUBMIT_LOADING', false);
            }
        },


                // Bill Reply


                async addBillReply ({commit, dispatch}, payload) {
                    commit('SUBMIT_LOADING', true);
                    commit('SUBMIT_ERROR', false);
                    let config = {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("access_token"),
                        },
                    };
                    try{
                        let response = await axios.post(V1API.bill_add_reply, {
        
                            bill: payload.bill,
                            reply:payload.reply,
                        }, config).then(result=>{
                            let data = result.data
                            return data
        
                        });
        
                       
                        dispatch('fetchBillReplies',payload.bill)
                        commit('SUBMIT_SUCCESS', true);
                        
                        
                        return response
        
                    }catch (e) {
                        commit('SUBMIT_ERROR', true);
                    }finally {
                        commit('SUBMIT_LOADING', false);
                    }
                },
        
        
                async fetchBillReplies ({ commit }, payload) {
                    commit('SUBMIT_LOADING', true);
                    commit('SUBMIT_ERROR', false);
                    let config = {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("access_token"),
                        }
                    };
                    try{
                        let result = await axios.get(V1API.get_replies_of_bill + payload, config);
                        let response = result.data
                        commit('SET_BILL_REPLY', response.data);
        
                    } catch(error) {
                        commit('SUBMIT_ERROR', true);
                    }
                    commit('SUBMIT_LOADING', false);
                },
        
    },

    getters: {}
}
