import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        company: [],
        client: [],
        clien_details:{},
        projectLoading: false,
        projectError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_COMPANY (state, company) {
            state.company = company;
        },

        SET_CLIENT (state, company) {
            state.client = company;
        },
        SET_CLIENT_DETAILS(state,details){
            state.clien_details = details;
        },

        COMPANY_LOADING (state, loading) {
            state.projectLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateProject ({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.projectUpdate,
                async () => {

                    let magic=encode({name:payload.name})
                    response= await axios.post(V1API.area_edit+'/'+payload.area_id,magic.encodedPayload,magic.config).then(result=>{
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchCompany');

                }
            )
            return response
        },

        async deleteClient ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_client + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchClient');
            
            return response
        },

        async fetchCompany ({ commit }) {
            commit('COMPANY_LOADING', true);
            commit('COMPANY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_company, config);
                let response = result.data

                commit('SET_COMPANY', response.data);

            } catch(error) {
                commit('COMPANY_ERROR', true);
            }
            commit('COMPANY_LOADING', false);
        },

        async fetchClient ({ commit }) {
            commit('COMPANY_LOADING', true);
            commit('COMPANY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_client, config);
                let response = result.data

                commit('SET_CLIENT', response.data);

            } catch(error) {
                commit('COMPANY_ERROR', true);
            }
            commit('COMPANY_LOADING', false);
        },

        async updateClient ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
               
                async () => {
                    response= await axios.patch(V1API.update_client+payload.id,payload.client_edit,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchClient');

                }
            )
            return response
        },
        async fetchClientDetails ({ commit },payload) {
            commit('COMPANY_LOADING', true);
            commit('COMPANY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.client_details +payload, config);
                let response = result.data

                commit('SET_CLIENT_DETAILS', response.data);

            } catch(error) {
                commit('COMPANY_ERROR', true);
            }
            commit('COMPANY_LOADING', false);
        },




        async addCompany ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_company, {
                    name: payload
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchCompany')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addClient ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_client, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchClient')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }
    },

    getters: {}
}
