<template>
  <div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Balance Sheet"/>

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card" >
            <div class="card-body">
              <div class="col-sm-12 text-center">
                <h5>Balance Sheet</h5>
                <br><br>
                <div class="row">
                  <div class="col-2"></div>
                  <div class="col-8 ">
                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                         Assets
                      </div>
                      <div class="col-6 text-right">

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        {{ balanceSheet.non_current_assets.title }}
                      </div>
                      <div class="col-6 text-right">

                      </div>
                    </div>
                    <div class="row" v-for=" note in balanceSheet.non_current_assets.notes">

                      <div class="col-6 text-left">
                        {{ note.title }}
                      </div>
                      <div class="col-6 text-right">
                        {{ note.amount }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        Total {{ balanceSheet.non_current_assets.title }}
                      </div>
                      <div class="col-6 text-right" style="font-weight: bold">
                        {{balanceSheet.non_current_assets.amount}}
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        {{ balanceSheet.current_assets.title }}
                      </div>
                      <div class="col-6 text-right">

                      </div>
                    </div>
                    <div class="row" v-for=" note in balanceSheet.current_assets.notes">

                      <div class="col-6 text-left">
                        {{ note.title }}
                      </div>
                      <div class="col-6 text-right">
                        {{ note.amount }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        Total {{ balanceSheet.current_assets.title }}
                      </div>
                      <div class="col-6 text-right" style="font-weight: bold">
                        {{balanceSheet.current_assets.amount}}
                      </div>

                    </div>

                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        Total Assets
                      </div>
                      <div class="col-6 text-right"  style="font-weight: bold">
                        {{balanceSheet.current_assets.amount+balanceSheet.non_current_assets.amount}}
                      </div>

                    </div>
                    <br>

                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        Equity and liabilities
                      </div>
                      <div class="col-6 text-right">

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        {{ balanceSheet.equity.title }}
                      </div>
                      <div class="col-6 text-right">

                      </div>
                    </div>

                    <div class="row" v-for=" note in balanceSheet.equity.notes">

                      <div class="col-6 text-left">
                        {{ note.title }}
                      </div>
                      <div class="col-6 text-right">
                        {{ note.amount }}
                      </div>
                    </div>
                    <div class="row">

                      <div class="col-6 text-left">
                        Retained Earnings
                      </div>
                      <div class="col-6 text-right">
                        {{ incomeStatementCalculationDetails.net_income }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        Total {{ balanceSheet.equity.title }}
                      </div>
                      <div class="col-6 text-right"  style="font-weight: bold">
                        {{balanceSheet.equity.amount+incomeStatementCalculationDetails.net_income}}
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        {{ balanceSheet.non_current_liabilities.title }}
                      </div>
                      <div class="col-6 text-right">

                      </div>
                    </div>
                    <div class="row" v-for=" note in balanceSheet.non_current_liabilities.notes">

                      <div class="col-6 text-left">
                        {{ note.title }}
                      </div>
                      <div class="col-6 text-right">
                        {{ note.amount }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        Total {{ balanceSheet.non_current_liabilities.title }}
                      </div>
                      <div class="col-6 text-right" style="font-weight: bold">
                        {{balanceSheet.non_current_liabilities.amount}}
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        {{ balanceSheet.Current_liabilities.title }}
                      </div>
                      <div class="col-6 text-right">

                      </div>
                    </div>
                    <div class="row" v-for=" note in balanceSheet.Current_liabilities.notes">

                      <div class="col-6 text-left">
                        {{ note.title }}
                      </div>
                      <div class="col-6 text-right">
                        {{ note.amount }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        Total {{ balanceSheet.Current_liabilities.title }}
                      </div>
                      <div class="col-6 text-right" style="font-weight: bold">
                        {{balanceSheet.Current_liabilities.amount}}
                      </div>

                    </div>

                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        Total Liabilities
                      </div>
                      <div class="col-6 text-right">
                        {{balanceSheet.Current_liabilities.amount+balanceSheet.non_current_liabilities.amount}}
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-left" style="font-weight: bolder">
                        Total Equity and Liabilities
                      </div>
                      <div class="col-6 text-right" style="font-weight: bolder">
                        {{balanceSheet.equity.amount+incomeStatementCalculationDetails.net_income+balanceSheet.Current_liabilities.amount+balanceSheet.non_current_liabilities.amount}}
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-10 text-left">

                      </div>

                      <div class="col-2">
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                        <hr style="border-style: solid; border-width: 0.01rem; border-color: black;" >
                      </div>
                    </div>
                  </div>

                  <div class="col-2"></div>
                </div>



              </div>

            </div>

          </div>

        </div>
      </div>


  </div>

    <b-modal
        id="modal-center"
        centered
        hide-header
        @ok="handleDeleteOk"
    >
      <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>
    </b-modal>
  <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {mapState} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
  components: {
    Datepicker
  },

  data() {
    return {
      tablefields: [
        {key: 'index', label: '#'},
        {key: 'lid', label: 'Ledger ID', sortable: true},
        {key: 'title', label: 'Title', sortable: true},
        {key: 'group', label: 'Group', sortable: true},
        {key: 'group.pre_defined.title', label: 'Pre-Defined Categories', sortable: true},
        {key: 'action', label: 'Action', sortable: false}
      ],

      items: [],
      structure_item:
          {
            category: null,
            group: null,
            ledger: null,
            amount: null,
            is_debit: true
          },
      groups: [],
      gross_profit:0.0,
      total_operating_expenses:0.0,
      operating_income:0.0,
      income_before_taxes:0.0,
      net_income:0.0,


      ledgers: [],
      delete_value: "",
      delete_slug: "",
      // options: ["Digital Marketing", "Front-end", "Back-end"],
      //
      filter: "",

      totalRows: 1,
      currentPage: 2,
      perPage: 10,
      pageOptions: [5, 10, 15]
    };
  },

  computed: {
    ...mapState('account', ["balanceSheet","balanceSheetDetails","balanceSheetLoading","balanceSheetError","incomeStatementCalculationDetails", "incomeStatementLoading", "incomeStatementError"]),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  created() {
    this.$store.dispatch('account/fetchIncomeStatement')
    this.$store.dispatch('account/fetchBalanceSheet').then(result => {
      this.items = this.balanceSheet
      this.totalRows = this.items.length
    })


  },
  watch: {

    balanceSheet(oldValue, newValue) {

      this.items = this.balanceSheet
      this.totalRows = this.items.length

    },
    incomeStatementCalculationDetails(oldValue, newValue) {



    },

  },


  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

  },

  methods: {
    getElement(slug) {
      let index = this.balanceSheet.map(object => object.slug).indexOf(slug)
      return this.balanceSheet[index]
    },
    // calculateGrossProfit(value1, value2) {
    //   this.gross_profit=value1-value2
    //   return this.gross_profit
    // },
    // calculateTotalOperatingExpense(value1, value2) {
    //   this.total_operating_expenses=value1+value2
    //   return this.total_operating_expenses
    // },
    // calculateOperatingIncome() {
    //   this.operating_income=this.gross_profit-this.total_operating_expenses
    //   return this.operating_income
    // },
    // calculateIncomeBeforeTaxes(value1,value2,value3,value4) {
    //   this.income_before_taxes=this.operating_income+value1+value2+value3+value4
    //   console.log(this.income_before_taxes)
    //   return this.income_before_taxes
    // },
    // calculateNetIncome(value) {
    //   this.net_income=this.income_before_taxes-value
    //   return this.net_income
    // },
  }
};
</script>
