<template>
<div>
    <Breadcrumbs main="CRM" title="Clients" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header" style="max-width: fit-content">
                        <router-link to="/crm/client/create">
                            <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">

                                <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                    <i class="fa fa-light fa-plus"></i>
                                    <h7>Add New Client</h7>
                                </div>

                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">
                            <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">

                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template #cell(first_name)="data">
                                    <router-link :to="'/crm/client/details/'+ data.item.id" class="link-style"> <span>{{ data.item.first_name }}</span></router-link>
                                </template>

                                <template #cell(company)="row">
                                    <span>{{ row.value.name }}</span>
                                </template>

                                <template #cell(status)="row">
                                    <span v-if="row.value == true">
                                        <b-badge pill variant="success">Active</b-badge>
                                    </span>
                                    <span v-if="row.value == false">
                                        <b-badge pill variant="danger">Inactive</b-badge>
                                    </span>
                                </template>

                                <template #cell(action)="row">
                                    <span style="display: flex; gap: 12px;">
                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteClient(row.item.id)" title="Delete"></feather>
                                        <div @click="editType(row.item)">
                                            <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                        </div>
                                    </span>

                                </template>
                            </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Client Edit Modal -->

    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Client" size="lg" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Name (Contact Person)</label>
                        <input class="form-control" type="text" placeholder="Enter contact person's name *" v-model="edit_client.first_name">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Company</label>
                            <!-- <button class="btn-success ml-3" v-b-modal.modal-prevent-closing>Add New</button> -->
                       
                        <select class="form-control digits" v-model="edit_client.company">
                            <option v-for="(company, index) in companies" :key="index" :value="company.id">{{ company.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Designation</label>
                        <input class="form-control" type="text" placeholder="Enter designation *" v-model="edit_client.designation">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Phone Number</label>
                        <input class="form-control" type="text" placeholder="Enter phone number *" v-model="edit_client.phone">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Address</label>
                        <input class="form-control" type="text" placeholder="Enter client address" v-model="edit_client.address">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Email</label>
                        <input class="form-control" type="text" placeholder="Enter email address *" v-model="edit_client.email">
                    </div>
                </div>
               
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Client Status</label>
                        <select class="form-control digits" v-model="edit_client.status">
                            <option v-for="(status, index) in statuses" :key="index" :value="status.value">{{ status.title }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this client?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'first_name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'company',
                    label: 'Company',
                    sortable: true,
                },
                {
                    key: 'designation',
                    label: 'Designation',
                    sortable: true,
                },
                {
                    key: 'phone',
                    label: 'Phone Number',
                    sortable: true,
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true,
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true,
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true,
                },
            ],
            edit_client: {
                id: '',
                first_name: '',
                company: null,
                designation: '',
                phone: '',
                email: '',
                status: true,
                address:null,
            },
            statuses: [{
                    title: 'Active',
                    value: true
                },
                {
                    title: 'Inactive',
                    value: false
                }
            ],

            delete_value: null,

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],
        };
    },

    computed: {
        ...mapState({
            items: state => state.client.client,
            companies: state => state.client.company
        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {
        this.$store.dispatch("client/fetchCompany")
        this.$store.dispatch("client/fetchClient").then(() => {
           
            this.totalRows = this.items.length;
        });
        
    },

    methods: {
        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {
            this.$store.dispatch("client/updateClient", {
                id: this.edit_client.id,
                client_edit:this.edit_client,
               

            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });


                        this.edit_client.id = '',
                        this.edit_client.first_name = '',
                        this.edit_client.company = '',
                        this.edit_client.designation = '',
                        this.edit_client.phone = '',
                        this.edit_client.email = '',
                        this.edit_client.status = ''
                        this.edit_client.address = ''
                        

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        editType(type) {
            this.edit_client.id = type.id
            this.edit_client.first_name = type.first_name
            this.edit_client.company = type.company.id
            this.edit_client.designation = type.designation
            this.edit_client.phone = type.phone
            this.edit_client.email = type.email
            this.edit_client.status = type.status
            this.edit_client.address = type.address
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteClient(id) {
            this.delete_value = id
        },

        handleOk() {
            this.$store.dispatch("client/deleteClient", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
