import Vue from "vue";
import Router from "vue-router";
import firebase from "firebase";
import Userauth from "../auth/js/index";

import Body from "../components/body";
import Auth from "../auth/auth";

/* Auth */
import login from "../auth/login";
import Register from "../auth/register";
import resetPassword from '../auth/reset-password.vue'

// import Register from "../pages/authentication/register_image2";


/* maintenance */
import Maintenance from "../pages/maintenance";

/* Error Pages */
import Error400 from "../errors/error_400";
import Error401 from "../errors/error_401";
import Error403 from "../errors/error_403";
import Error404 from "../errors/error_404";
import Error500 from "../errors/error_500";
import Error503 from "../errors/error_503";

/* comingsoon */
import ComingsoonSimple from "../pages/comingsoon/comingsoon_simple";
import ComingsoonImage from "../pages/comingsoon/comingsoon_image";
import ComingsoonVideo from "../pages/comingsoon/comingsoon_video";



/* Authentication */
import LoginOne from "../pages/authentication/login_one";
import LoginTwo from "../pages/authentication/login_two";
import LoginValidate from "../pages/authentication/login_with_validation";
import RegisterImage from "../pages/authentication/register_image";
import RegisterImage2 from "../pages/authentication/register_image2";
import Unlock from "../pages/authentication/unlock";
import ForgetPassword from "../pages/authentication/forget_password";
import ResetPassword from "../pages/authentication/reset_password";



//Notifier
import Dashboard from "../notifier/dashboard/default"
import SendSms from "../notifier/smsManagement/sendSms"
import DynamicSms from "../notifier/smsManagement/dynamicSms"
import SmsTemplate from "../notifier/smsTemplate/smsTemplate"
import PriceAndCoverage from "../notifier/priceAndCoverage/priceAndCoverage"
import ContactGroup from "../notifier/addressBook/contactGroup"
import ManageContact from "../notifier/addressBook/manageContact"
import Billing from "../notifier/billing/billing"
import TodaysReport from "../notifier/deliveryReport/todaysDeliveryReport"
import AllReport from "../notifier/deliveryReport/allTimeDeliveryReport"
import ChangePassword from "../notifier/user/changePassword"
import ApiDocumentation from "../notifier/documentation/apiDocumentation"


// SMICEE

// Project
import CreateProject from "../pages/smicee/project/create-project.vue";
import EditProject from "../pages/smicee/project/edit-project.vue";

import ProjectList from "../pages/smicee/project/project-list.vue";
import ProjectDetail from "../pages/smicee/project/project-details.vue";
import MyProject from "../pages/smicee/project/my-project.vue";
import MyTask from "../pages/smicee/project/my-task.vue";

// My Workspace
import ApplyLeave from "../pages/smicee/my-workspace/apply-for-leave.vue";
import CreateLeave from "../pages/smicee/my-workspace/create-leave.vue";
import MyAssets from "../pages/smicee/my-workspace/my-assets.vue";
import MyUpdates from "../pages/smicee/my-workspace/my-updates/myUpdates.vue";
import MyContact from "../pages/smicee/my-workspace/my-contact.vue";
import Grievance from "../pages/smicee/my-workspace/grievance.vue";
import MyTrainings from "../pages/smicee/my-workspace/my-trainings.vue";
import MyTickets from "../pages/smicee/my-workspace/ticket/my-tickets.vue";
import RaiseTicket from "../pages/smicee/my-workspace/ticket/raise-ticket.vue";
import MyAssetDetail from '../pages/smicee/my-workspace/asset-details.vue'
import MyProfile from '../pages/smicee/my-workspace/my-profile.vue'
import EditMyProfile from '../pages/smicee/my-workspace/profile-edit.vue'

import LeaveDetails from "../pages/smicee/my-workspace/my-updates/leave-details.vue"
import RequistionDetails from "../pages/smicee/my-workspace/my-updates/requisition-details.vue"
import TicketDetails from "../pages/smicee/my-workspace/my-updates/ticket-details.vue"
import BudgetDetails from "../pages/smicee/my-workspace/my-updates/budget-details.vue"
import GrievanceDetails from "../pages/smicee/my-workspace/my-updates/grievance-details.vue"

import MyRequisition from '../pages/smicee/my-workspace/requisitions/requisition_list.vue'
import MyRequisitionCreate from '../pages/smicee/my-workspace/requisitions/requisition_create.vue'
import MyRequisitionEdit from '../pages/smicee/my-workspace/requisitions/requisition_edit.vue'

import EODCreate from '../pages/smicee/my-workspace/eod/create_eod.vue'
import MyEODList from '../pages/smicee/my-workspace/eod/eod_list.vue'
import EODDetails from '../pages/smicee/my-workspace/eod/eod_details.vue'

// CRM --> Leads
import LeadList from "../pages/smicee/crm/lead-list.vue";

// CRM --> Client
import CreateClient from "../pages/smicee/crm/create-client.vue";
import ClientList from "../pages/smicee/crm/client-list.vue";
import ClientDetails from '../pages/smicee/crm/client-details.vue'

//EOD
import EODList from "../pages/smicee/eod/eod_list.vue"
import EOD_Details from "../pages/smicee/eod/eod_details.vue"

// Procurement
import Vendor from "../pages/smicee/vendor/vendor-list.vue";
import VendorContacts from '../pages/smicee/vendor-contact/vendor-contact.vue'
import VendorDetails from '../pages/smicee/vendor/vendor-details.vue'
import VendorCategory from '../pages/smicee/vendor/vendor-category.vue'

import CreateRFP from "../pages/smicee/rfp/create-rfp.vue";
import CreateRFPbyProject from "../pages/smicee/rfp/create-rfp[project].vue";
import EditRFP from "../pages/smicee/rfp/edit-rfp.vue";
import RFPList from "../pages/smicee/rfp/rfp-list.vue";
import RFPDetail from "../pages/smicee/rfp/rfp-view.vue";
import RFPCategory from "../pages/smicee/rfp/category-list.vue";
import RFPdFormCreate from '../pages/smicee/rfp/rfp-form-create.vue';
import RFPdFormEdit from '../pages/smicee/rfp/rfp-form-edit.vue';
import RFPFormSubmission from '../pages/smicee/rfp/rfp-form-submission.vue';
import RFPSubmissionDetails from '../pages/smicee/rfp/response-details.vue'
import RFPResponses from '../pages/smicee/rfp/rfp-responses.vue'




//HR --> Timesheet
import TimeSheetSummary from '../pages/smicee/hr/Timesheet/time-sheet-summary.vue'
import workHistoryTimeSheet from '../pages/smicee/hr/Timesheet/workHistory[id].vue'
import employeekHistoryTimeSheet from '../pages/smicee/hr/Timesheet/userHistory[date_time].vue'


// HR --> Leave Management
import LeaveRequests from "../pages/smicee/hr/leave/leave-requests.vue";
import LeaveType from "../pages/smicee/hr/leave/leave-type.vue";

// HR --> Training Management
import TrainingType from "../pages/smicee/hr/training/training-type.vue";
import TrainingList from "../pages/smicee/hr/training/training-list.vue";
import TrainerList from "../pages/smicee/hr/training/trainers-list.vue";

// HR --> Policy
import Policy from "../pages/smicee/hr/policy/policy.vue";
import PolicyCreate from "../pages/smicee/hr/policy/policy-create.vue";
import PolicyEdit from "../pages/smicee/hr/policy/policy-edit.vue";
import AddPolicyForm from "../pages/smicee/hr/policy/add-form.vue";
import EditPolicyForm from "../pages/smicee/hr/policy/edit-form.vue";
import ViewPolicyForm from "../pages/smicee/hr/policy/view-form.vue";
import PolicyResponses from "../pages/smicee/hr/policy/responses.vue";

//Onboarding
import OnBoarding from '../pages/smicee/hr/onboarding/onboarding.vue';

// HR --> Compliance
import Compliance from "../pages/smicee/hr/compliance/compliance-list.vue";
import ComplianceCreate from "../pages/smicee/hr/compliance/compliance-create.vue";
import ComplianceEdit from "../pages/smicee/hr/compliance/compliance-edit.vue";
import AddComplianceForm from "../pages/smicee/hr/compliance/add-form.vue";
import EditComplianceForm from "../pages/smicee/hr/compliance/edit-form.vue";
import ViewComplianceForm from "../pages/smicee/hr/compliance/view-form.vue";
import ComplianceResponses from "../pages/smicee/hr/compliance/responses.vue";

// HR --> Payroll
import PayrollItems from "../pages/smicee/hr/payroll/payroll-items.vue";
import DesignationPayroll from "../pages/smicee/hr/payroll/designation-payroll.vue";
import Payroll from "../pages/smicee/hr/payroll/payroll.vue";
import PayrollHistory from "../pages/smicee/hr/payroll/payroll-history.vue";

// // HR --> Organizations
import Department from "../pages/smicee/hr/organizations/department.vue";
import Designation from "../pages/smicee/hr/organizations/designation.vue";
import Employee from "../pages/smicee/hr/organizations/employee.vue";
import OTPList from "../pages/smicee/hr/organizations/otp_list.vue"
import EmployeeCreate from "../pages/smicee/hr/organizations/create_employee.vue";
import OrganizationSetting from "../pages/smicee/hr/organizations/organization_setting.vue";
import EmployeeDetails from "../pages/smicee/hr/organizations/employee_details.vue";
import EmployeeEdit from "../pages/smicee/hr/organizations/edit_employee.vue";

// HR --> Asset
import AssetList from "../pages/smicee/hr/asset/asset-list.vue";
import AssetType from "../pages/smicee/hr/asset/asset-type.vue";

import AssetCreate from "../pages/smicee/hr/asset/asset-create.vue";
import AssetEdit from "../pages/smicee/hr/asset/asset-edit.vue";
import AssetAllocations from "../pages/smicee/hr/asset/asset-allocations.vue";

import AssetDetail from "../pages/smicee/hr/asset/asset-details.vue";

// HR --> Asset
import HolidayList from "../pages/smicee/hr/holidays/holiday-list.vue";

// HR --> Grievance
import HRGrievance from "../pages/smicee/hr/grievance/grievance.vue";

// HR --> Ticket
import HRTicket from "../pages/smicee/hr/ticket/ticket-list.vue";

// Finance --> Items
import ItemList from "../pages/smicee/items/item-list.vue";

// Finance --> Budget
import EstimatedBudgets from "../pages/smicee/budget/estimated-budgets.vue";
import BudgetRequest from "../pages/smicee/budget/budget-requests.vue";
import RequestForBudget from "../pages/smicee/budget/request-for-budget.vue";

// Finance --> Quotation
import CreateQuotation from "../pages/smicee/quotation/create-quotation.vue";
import QuotationList from "../pages/smicee/quotation/quotation-list.vue";
import QuotationView from "../pages/smicee/quotation/quotation-view.vue";
import QuotationEdit from "../pages/smicee/quotation/quotation-edit.vue";

// Finance --> Invoice
import CreateInvoice from "../pages/smicee/invoice/create-invoice.vue";
import InvoiceList from "../pages/smicee/invoice/invoice-list.vue";
import InvoiceView from "../pages/smicee/invoice/invoice-view.vue";
import InvoiceEdit from "../pages/smicee/invoice/invoice-edit.vue";

// Finance --> Bill
import CreateBill from "../pages/smicee/bill/create-bill.vue";
import CreateBillByProject from "../pages/smicee/bill/create-bill[project].vue";
import BillList from "../pages/smicee/bill/bill-list.vue";
import BillEdit from "../pages/smicee/bill/bill-edit.vue";
import BillView from "../pages/smicee/bill/bill-view.vue";

// Finance --> Purchase Order
import POCreate from "../pages/smicee/purchase-order/create-po.vue";
import POList from "../pages/smicee/purchase-order/po-list.vue";
import POEdit from "../pages/smicee/purchase-order/po-edit.vue";
import POView from "../pages/smicee/purchase-order/po-view.vue";

// Finance --> Accounts
import AccountsCategory from "../pages/smicee/accounts/category.vue";
import AccountsGroup from "../pages/smicee/accounts/group.vue";
import Ledger from "../pages/smicee/accounts/ledger.vue";
import LedgerDetail from "../pages/smicee/accounts/ledger-detail.vue";
import Transactions from "../pages/smicee/accounts/transactions.vue";
import TransactionsSingle from "../pages/smicee/accounts/transaction_single.vue";
import Wallet from "../pages/smicee/accounts/wallet.vue";

// Payments
import RequisitionPayments from "../pages/smicee/accounts/payments/requisition-payments.vue";
import InvoicePayments from "../pages/smicee/accounts/payments/invoice-payments.vue";
import BillPayments from "../pages/smicee/accounts/payments/bill-payments.vue";

// Requisition
import Requisition from "../pages/smicee/requisition/list.vue";
import RequisitionCreate from "../pages/smicee/requisition/create.vue";

import RequisitionCreatebyProject from "../pages/smicee/requisition/create[project].vue";

import RequisitionEdit from "../pages/smicee/requisition/edit.vue";
import RequisitionType from '../pages/smicee/requisition/requisition-type.vue'

// Job
import JobDashboard from "../pages/smicee/job/dashboard.vue";
import Jobs from "../pages/smicee/job/jobs.vue";
import JobDetail from "../pages/smicee/job/job-details.vue";
import CreateJob from "../pages/smicee/job/create-job.vue";
import UpdateJob from '../pages/smicee/job/edit-job.vue'
import Candidates from "../pages/smicee/job/candidates.vue";
import CandidateDetails from "../pages/smicee/job/candidate-details.vue";
import Messages from "../pages/smicee/job/messages.vue";
import Evaluation from "../pages/smicee/job/evaluation.vue";
import QuestionSet from "../pages/smicee/job/question-set.vue";
import CreateQuestionSet from "../pages/smicee/job/create-question-set.vue";
import JobList from '../pages/smicee/job_portal/job_list.vue'
import TrialBalance from "@/pages/smicee/accounts/trial-balance.vue";
import IncomeStructure from "@/pages/smicee/accounts/income-structure.vue";
import IncomeStatement from "@/pages/smicee/accounts/income-statement.vue";
import Notes from "@/pages/smicee/accounts/notes.vue";
import noteDetail from "@/pages/smicee/accounts/note-detail.vue";
import BalanceSheet from "@/pages/smicee/accounts/balanace-sheet.vue";
import DesignationDetails from "@/pages/smicee/hr/organizations/designationDetails.vue";

//Vendor
import vendorRfq from '../pages/smicee/vendor-portal/vendor-rfq/rfq-list.vue';
import requestQuotation from '../pages/smicee/vendor-portal/vendor-rfq/requestQuotation.vue';
import MyRfqResponse from '../pages/smicee/vendor-portal/vendor-rfq/my-response.vue';
import requestQuotationList from '../pages/smicee/vendor-portal/vendor-rfq/request_list.vue';

import vendorProjectList from '../pages/smicee/vendor-portal/vendor-projects/vendor-project-list.vue';
import vendorProjectDetails from '../pages/smicee/vendor-portal/vendor-projects/vendor-project-details.vue';

import vendorPurchaseOrder from '../pages/smicee/vendor-portal/vendor-po/vendor-po.vue';
import VendorBill from '../pages/smicee/vendor-portal/vendor-bill/vendor-bill.vue'


Vue.use(Router);

const routes = [
    // SMICEE STARTS

    // My Workspace
    {
        path: "/my-workspace",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "my-requisition",
                name: "MyRequisition",
                component: MyRequisition,
                meta: {
                    title: "My Requisitions",
                }
            },
            {
                path: "requisition-create",
                name: "MyRequisitionCreate",
                component: MyRequisitionCreate,
                meta: {
                    title: "Requisition Create",
                }
            },
            {
                path: "requisition-view/:id",
                name: "MyRequisitionEdit",
                component: MyRequisitionEdit,
                meta: {
                    title: "Requisition Edit",
                }
            },
            {
                path: "apply-for-leave",
                name: "ApplyLeave",
                component: ApplyLeave,
                meta: {
                    title: "Apply for Leave",
                }
            },
            {
                path: "apply-for-leave/create",
                name: "CreateLeave",
                component: CreateLeave,
                meta: {
                    title: "Apply for Leave",
                }
            },
            {
                path: "my-assets",
                name: "MyAssets",
                component: MyAssets,
                meta: {
                    title: "My Assets",
                }
            },
            {
                path: "my-asset/details/:id",
                name: "MyAssetDetail",
                component: MyAssetDetail,
                meta: {
                    title: "Asset Detail",
                }
            },
            {
                path: "my-updates",
                name: "MyUpdates",
                component: MyUpdates,
                meta: {
                    title: "My Updates",
                }
            },
            {
                path: "my-contact",
                name: "MyContact",
                component: MyContact,
                meta: {
                    title: "My Contact",
                }
            },
            {
                path: "leave-details/:id",
                name: "LeaveDetails",
                component: LeaveDetails,
                meta: {
                    title: "Leave Details",
                }
            },
            {
                path: "requisition-details/:id",
                name: "RequistionDetails",
                component: RequistionDetails,
                meta: {
                    title: "Requistion Details",
                }
            },
            {
                path: "ticket-details/:id",
                name: "TicketDetails",
                component: TicketDetails,
                meta: {
                    title: "Ticket Details",
                }
            },
            {
                path: "budget-details/:id",
                name: "BudgetDetails",
                component: BudgetDetails,
                meta: {
                    title: "Budget Details",
                }
            },
            {
                path: "grievance-details/:id",
                name: "GrievanceDetails",
                component: GrievanceDetails,
                meta: {
                    title: "Grievance Details",
                }
            },
            {
                path: "trainings",
                name: "MyTrainings",
                component: MyTrainings,
                meta: {
                    title: "My Trainings",
                }
            },
            {
                path: "grievance",
                name: "Grievance",
                component: Grievance,
                meta: {
                    title: "Grievance",
                }
            },
            {
                path: "ticket/my-tickets",
                name: "MyTickets",
                component: MyTickets,
                meta: {
                    title: "My Tickets",
                }
            },
            {
                path: "ticket/raise-a-ticket",
                name: "RaiseTicket",
                component: RaiseTicket,
                meta: {
                    title: "Raise a Ticket",
                }
            },
            {
                path: "eod/eod-report",
                name: "EODCreate",
                component: EODCreate,
                meta: {
                    title: "My EOD",
                }
            },
            {
                path: "eod/my-eod-report",
                name: "MyEODList",
                component: MyEODList,
                meta: {
                    title: "My EOD",
                }
            },
            {
                path: "eod/eod-report/:id",
                name: "EODDetails",
                component: EODDetails,
                meta: {
                    title: "My EOD",
                }
            }
        ]
    },

    // Project
    {
        path: "/project",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "create",
                name: "CreateProject",
                component: CreateProject,
                meta: {
                    title: "Create Project",
                },
            },
            {
                path: "edit/:id",
                name: "EditProject",
                component: EditProject,
                meta: {
                    title: "Edit Project",
                },
            },
            {
                path: "list",
                name: "ProjectList",
                component: ProjectList,
                meta: {
                    title: "Projects",
                },
            },
            {
                path: "my-project",
                name: "myProjects",
                component: MyProject,
                meta: {
                    title: "My Projects",
                },
            },
            {
                path: "my-task",
                name: "myTask",
                component: MyTask,
                meta: {
                    title: "My Task",
                },
            },
            {
                path: "/project/details/:id",
                name: "ProjectDetail",
                component: ProjectDetail,
                meta: {
                    title: "Project Detail",
                }
            },
        ]
    },

    // CRM
    {
        path: "/crm",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "leads/list",
                name: "LeadList",
                component: LeadList,
                meta: {
                    title: "Leads",
                },
            },
            {
                path: "client/create",
                name: "CreateClient",
                component: CreateClient,
                meta: {
                    title: "Create Client",
                },
            },
            {
                path: "client/list",
                name: "ClientList",
                component: ClientList,
                meta: {
                    title: "Clients",
                },

            },
            {
                path: "client/details/:id",
                name: "ClientDetails",
                component: ClientDetails,
                meta: {
                    title: "Client Details",
                },

            },
        ]
    },

    //EOD
    {
        path: "/eod",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "list",
                name: "EODList",
                component: EODList,
                meta: {
                    title: "EOD Reports",
                },
            },

            {
                path: "details/:id",
                name: "EOD_Details",
                component: EOD_Details,
                meta: {
                    title: "EOD Reports",
                },
            },
        ]
    },

    // Procurement
    {
        path: "/procurement",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "vendor/contact/list",
                name: "VendorContacts",
                component: VendorContacts,
                meta: {
                    title: "Vendor Contacts",
                },
            },
            {
                path: "vendor/list",
                name: "Vendor",
                component: Vendor,
                meta: {
                    title: "Vendor",
                },
            },

            {
                path: "vendor/category",
                name: "VendorCategory",
                component: VendorCategory,
                meta: {
                    title: "Vendor Category",
                },
            },
            {
                path: "vendor/details/:id",
                name: "VendorDetails",
                component: VendorDetails,
                meta: {
                    title: "Vendor Details",
                },
            },
            {
                path: "rfp/category/list",
                name: "RFPCategory",
                component: RFPCategory,
                meta: {
                    title: "RFP Category",
                },

            },
            {
                path: "rfp/create/:id",
                name: "CreateRFP",
                component: CreateRFP,
                meta: {
                    title: "Create RFP",
                },
            },
            {
                path: "rfp/create-form/:id",
                name: "RFPdFormCreate",
                component: RFPdFormCreate,
                meta: {
                    title: "Create RFP Form",
                },
            },
            {
                path: "rfp/edit-form/:id",
                name: "RFPdFormEdit",
                component: RFPdFormEdit,
                meta: {
                    title: "Edit RFP Form",
                },
            },

            {
                path: "rfp/submit-form/:id",
                name: "RFPFormSubmission",
                component: RFPFormSubmission,
                meta: {
                    title: "RFP Form Submission",
                },
            },
            {
                path: "rfp/submission/:id",
                name: "RFPSubmissionDetails",
                component: RFPSubmissionDetails,
                meta: {
                    title: "RFP Form Submission",
                },
            },



            {
                path: "rfp/responses/:id",
                name: "RFPResponses",
                component: RFPResponses,
                meta: {
                    title: "RFP Responses",
                },
            },



            // {
            //     path: "rfp/create/:project",
            //     name: "CreateRFP",
            //     component:   CreateRFPbyProject,
            //     meta: {
            //         title: "Create RFP",
            //     },
            // },


            {
                path: "rfp/edit/:id",
                name: "EditRFP",
                component: EditRFP,
                meta: {
                    title: "Edit RFP",
                },
            },
            {
                path: "rfp/list",
                name: "RFPList",
                component: RFPList,
                meta: {
                    title: "RFP",
                },
            },
            {
                path: "rfp/:id",
                name: "RFPDetail",
                component: RFPDetail,
                meta: {
                    title: "RFP View",
                }
            },
        ]
    },

    //Vendor
    {
        path: "/vendor",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "rfq",
                name: "vendorRfq",
                component: vendorRfq,
                meta: {
                    title: "Request for Quotation",
                },
            },
            {
                path: "request/quotation/:id",
                name: "requestQuotation",
                component: requestQuotation,
                meta: {
                    title: "Request Quotation",
                },
            },
            {
                path: "rfq/response/:id",
                name: "MyRfqResponse",
                component: MyRfqResponse,
                meta: {
                    title: "RFQ Response",
                },
            },
            {
                path: "rfq/my-rfq",
                name: "requestQuotationList",
                component: requestQuotationList,
                meta: {
                    title: "Request for Quotation",
                },
            },
            {
                path: "projects",
                name: "vendorProjectList",
                component: vendorProjectList,
                meta: {
                    title: "Projects",
                },
            },
            {
                path: "projects/:id",
                name: "vendorProjectDetails",
                component: vendorProjectDetails,
                meta: {
                    title: "Project",
                },
            },
            {
                path: "purchase_order",
                name: "vendorPurchaseOrder",
                component: vendorPurchaseOrder,
                meta: {
                    title: "Purchase Order",
                },
            },
            {
                path: "bill",
                name: "VendorBill",
                component: VendorBill,
                meta: {
                    title: "Bills",
                },
            },












        ]
    },

    // HR
    {
        path: "/hr",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            // Timesheet
            {
                path: "time-sheet",
                name: "TimeSheetSummary",
                component: TimeSheetSummary,
                meta: {
                    title: "Time Sheet",
                },
            },
            {
                path: "time-sheet/:id",
                name: "workHistoryTimeSheet",
                component: workHistoryTimeSheet,
                meta: {
                    title: "Time Sheet",
                },
            },

            {
                path: "time-sheet/:id/:date/:time",
                name: "employeekHistoryTimeSheet",
                component: employeekHistoryTimeSheet,
                meta: {
                    title: "Time Sheet",
                },
            },




            {
                path: "leave-management/leave-requests",
                name: "LeaveRequests",
                component: LeaveRequests,
                meta: {
                    title: "Leave Requests",
                },
            },
            {
                path: "leave-management/leave-type",
                name: "LeaveType",
                component: LeaveType,
                meta: {
                    title: "Leave Type",
                },
            },
            {
                path: "on-boarding",
                name: "OnBoarding",
                component: OnBoarding,
                meta: {
                    title: "On Boarding",
                },
            },


            {
                path: "training-management/training/list",
                name: "TrainingList",
                component: TrainingList,
                meta: {
                    title: "Trainings",
                },
            },
            {
                path: "training-management/training-type",
                name: "TrainingType",
                component: TrainingType,
                meta: {
                    title: "Training Type",
                },
            },
            {
                path: "training-management/trainer/list",
                name: "TrainerList",
                component: TrainerList,
                meta: {
                    title: "Trainers",
                },
            },
            {
                path: "grievance/list",
                name: "HRGrievance",
                component: HRGrievance,
                meta: {
                    title: "Grievance",
                },
            },
            {
                path: "ticket/list",
                name: "HRTicket",
                component: HRTicket,
                meta: {
                    title: "Ticket",
                },
            },
            {
                path: "policy",
                name: "Policy",
                component: Policy,
                meta: {
                    title: "Policy",
                },
            },
            {
                path: "policy/create",
                name: "PolicyCreate",
                component: PolicyCreate,
                meta: {
                    title: "Policy Create",
                },
            },
            {
                path: "policy/edit/:id",
                name: "PolicyEdit",
                component: PolicyEdit,
                meta: {
                    title: "Policy Edit",
                },
            },
            {
                path: "policy/responses/:id",
                name: "PolicyResponses",
                component: PolicyResponses,
                meta: {
                    title: "Policy Responses",
                },
            },
            {
                path: "policy/add-form/:id",
                name: "AddPolicyForm",
                component: AddPolicyForm,
                meta: {
                    title: "Policy Form",
                },
            },

            {
                path: "policy/edit-form/:id",
                name: "EditPolicyForm",
                component:EditPolicyForm,
                meta: {
                    title: "Policy Form",
                },
            },


            {
                path: "policy/view-form/:id",
                name: "ViewPolicyForm",
                component: ViewPolicyForm,
                meta: {
                    title: "View Form",
                },
            },
            {
                path: "compliance",
                name: "Compliance",
                component: Compliance,
                meta: {
                    title: "Compliance",
                },
            },
            {
                path: "compliance/create",
                name: "ComplianceCreate",
                component: ComplianceCreate,
                meta: {
                    title: "Compliance Create",
                },
            },
            {
                path: "compliance/edit/:id",
                name: "ComplianceEdit",
                component: ComplianceEdit,
                meta: {
                    title: "Compliance Edit",
                },
            },
            {
                path: "compliance/responses/:id",
                name: "ComplianceResponses",
                component: ComplianceResponses,
                meta: {
                    title: "Compliance Responses",
                },
            },
            {
                path: "compliance/add-form/:id",
                name: "AddComplianceForm",
                component: AddComplianceForm,
                meta: {
                    title: "Compliance Form",
                },
            },
            {
                path: "compliance/edit-form/:id",
                name: "EditComplianceForm",
                component: EditComplianceForm,
                meta: {
                    title: "Compliance Form",
                },
            },
            {
                path: "compliance/view-form/:id",
                name: "ViewComplianceForm",
                component: ViewComplianceForm,
                meta: {
                    title: "View Form",
                },
            },

            {
                path: "asset-management/asset/list",
                name: "AssetList",
                component: AssetList,
                meta: {
                    title: "Assets",
                },
            },
            {
                path: "asset-management/asset-type",
                name: "AssetType",
                component: AssetType,
                meta: {
                    title: "Assets",
                },
            },
            {
                path: "asset-management/asset/create",
                name: "AssetCreate",
                component: AssetCreate,
                meta: {
                    title: "Asset Create",
                },
            },
            {
                path: "asset-management/asset/edit/:id",
                name: "AssetEdit",
                component: AssetEdit,
                meta: {
                    title: "Asset Edit",
                },
            },
            {
                path: "asset-management/asset-allocations/list",
                name: "AssetAllocations",
                component: AssetAllocations,
                meta: {
                    title: "Asset Allocations",
                },
            },
            {
                path: "asset-management/asset/details/:id",
                name: "AssetDetail",
                component: AssetDetail,
                meta: {
                    title: "Asset Detail",
                },
            },
            {
                path: "holiday-list",
                name: "HolidayList",
                component: HolidayList,
                meta: {
                    title: "Holidays",
                },
            },
            {
                path: "eod-list",
                name: "EODList",
                component: EODList,
                meta: {
                    title: "EOD Reports",
                },
            },




            {
                path: "payroll/payroll-items/list",
                name: "PayrollItems",
                component: PayrollItems,
                meta: {
                    title: "Payroll Items",
                },
            },
            {
                path: "payroll/designation-payroll/list",
                name: "DesignationPayroll",
                component: DesignationPayroll,
                meta: {
                    title: "Designation Payroll",
                },
            },
            {
                path: "payroll/payroll-list",
                name: "Payroll",
                component: Payroll,
                meta: {
                    title: "Payroll",
                },
            },
            {
                path: "payroll/payroll-history/:id",
                name: "PayrollHistory",
                component: PayrollHistory,
                meta: {
                    title: "Payroll History",
                },
            },
            {
                path: "organization/department",
                name: "Organization - Departments",
                component: Department,
                meta: {
                    title: "Departments",
                },
            },
            {
                path: "organization/designation",
                name: "Organization - Designations",
                component: Designation,
                meta: {
                    title: "Designation",
                },
            },
            {
                path: "organization/designation/details/:id",
                name: "DesignationDetails",
                component: DesignationDetails,
                meta: {
                    title: "Designation Details",
                },
            },
            {
                path: "organization/employee",
                name: "Organization - Employee",
                component: Employee,
                meta: {
                    title: "Employee",
                },
            },
            {
                path: "organization/change-password-requests",
                name: "Organization - Change Request",
                component: OTPList,
                meta: {
                    title: "Change Password Requests",
                },
            },



            {
                path: "organization/employee/create",
                name: "Organization - Employee",
                component: EmployeeCreate,
                meta: {
                    title: "Employee Create",
                },
            },
            {
                path: "organization/setting/create",
                name: "Organization - Setting",
                component: OrganizationSetting,
                meta: {
                    title: "Organization Settings",
                },
            },


            {
                path: "organization/employee/edit/:id",
                name: "Organization - Employee",
                component: EmployeeEdit,
                meta: {
                    title: "Employee Edit",
                },
            },

            {
                path: "organization/employee/details/:id",
                name: "Organization - Employee Details",
                component: EmployeeDetails,
                meta: {
                    title: "Employee Details",
                },
            },



        ]
    },

    // Finance
    {
        path: "/finance-and-accounts",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "finance/items/list",
                name: "ItemList",
                component: ItemList,
                meta: {
                    title: "Items",
                }
            },
            {
                path: "finance/quotation/create/:project",
                name: "CreateQuotation",
                component: CreateQuotation,
                meta: {
                    title: "Create Quotation",
                }
            },
            {
                path: "finance/quotation/list",
                name: "QuotationList",
                component: QuotationList,
                meta: {
                    title: "Quotation List",
                }
            },
            {
                path: "finance/quotation/edit/:id",
                name: "QuotationEdit",
                component: QuotationEdit,
                meta: {
                    title: "Quotation Edit",
                }
            },
            {
                path: "finance/quotation/:id",
                name: "QuotationView",
                component: QuotationView,
                meta: {
                    title: "Quotation View",
                }
            },
            {
                path: "finance/invoice/create/:id",
                name: "CreateInvoice",
                component: CreateInvoice,
                meta: {
                    title: "Create Invoice",
                }
            },
            {
                path: "finance/invoice/list",
                name: "InvoiceList",
                component: InvoiceList,
                meta: {
                    title: "Invoice List",
                }
            },
            {
                path: "finance/invoice/edit/:id",
                name: "InvoiceEdit",
                component: InvoiceEdit,
                meta: {
                    title: "Invoice Edit",
                }
            },
            {
                path: "finance/invoice/:id",
                name: "InvoiceView",
                component: InvoiceView,
                meta: {
                    title: "Invoice View",
                }
            },
            {
                path: "finance/bill/create",
                name: "CreateBill",
                component: CreateBill,
                meta: {
                    title: "Create Bill",
                }
            },
            {
                path: "finance/bill/create/:project",
                name: "CreateBillbyProject",
                component:  CreateBillByProject,
                meta: {
                    title: "Create Bill",
                }
            },
            {
                path: "finance/bill/list",
                name: "BillList",
                component: BillList,
                meta: {
                    title: "Bill List",
                }
            },
            {
                path: "finance/bill/edit/:id",
                name: "BillEdit",
                component: BillEdit,
                meta: {
                    title: "Bill Edit",
                }
            },
            {
                path: "finance/bill/:id",
                name: "BillView",
                component: BillView,
                meta: {
                    title: "Bill View",
                }
            },
            {
                path: "finance/purchase-order/list",
                name: "POList",
                component: POList,
                meta: {
                    title: "Purchase Order List",
                }
            },
            {
                path: "finance/purchase-order/create/:project",
                name: "POCreate",
                component: POCreate,
                meta: {
                    title: "Purchase Order Create",
                }
            },
            {
                path: "finance/purchase-order/:id",
                name: "POView",
                component: POView,
                meta: {
                    title: "Purchase Order View",
                }
            },
            {
                path: "finance/purchase-order/edit/:id",
                name: "POEdit",
                component: POEdit,
                meta: {
                    title: "Purchase Order Edit",
                }
            },
            {
                path: "budget/estimated-budget/list",
                name: "EstimatedBudgets",
                component: EstimatedBudgets,
                meta: {
                    title: "Estimated Budgets",
                }
            },
            {
                path: "budget/budget-request/list",
                name: "BudgetRequest",
                component: BudgetRequest,
                meta: {
                    title: "Budget Request",
                }
            },
            {
                path: "budget/request-for-budget/:project",
                name: "RequestForBudget",
                component: RequestForBudget,
                meta: {
                    title: "Request for Budget",
                }
            },
            {
                path: "account/requisition/list",
                name: "Requisition",
                component: Requisition,
                meta: {
                    title: "Requisition",
                }
            },
            {
                path: "account/requisition/type",
                name: "RequisitionType",
                component: RequisitionType,
                meta: {
                    title: "RequisitionType",
                }
            },
            {
                path: "account/requisition/create",
                name: "RequisitionCreate",
                component: RequisitionCreate,
                meta: {
                    title: "Requisition Create",
                }
            },
            {
                path: "account/requisition/create/:project",
                name: "RequisitionCreatebyProject",
                component: RequisitionCreatebyProject,
                meta: {
                    title: "Requisition Create",
                }
            },
            {
                path: "account/requisition/view/:id",
                name: "RequisitionEdit",
                component: RequisitionEdit,
                meta: {
                    title: "Requisition Edit",
                }
            },
            {
                path: "account/category",
                name: "AccountsCategory",
                component: AccountsCategory,
                meta: {
                    title: "Accounts Category",
                }
            },
            {
                path: "account/group",
                name: "AccountsGroup",
                component: AccountsGroup,
                meta: {
                    title: "Accounts Group",
                }
            },
            {
                path: "account/ledger",
                name: "Ledger",
                component: Ledger,
                meta: {
                    title: "Ledger",
                }
            },
            {
                path: "account/ledger/:id",
                name: "LedgerDetail",
                component: LedgerDetail,
                meta: {
                    title: "Ledger Detail",
                }
            },
            {
                path: "account/transaction/list",
                name: "Transactions",
                component: Transactions,
                meta: {
                    title: "Transactions",
                }
            },
            {
                path: "account/transaction/list/:id",
                name: "TransactionsSingle",
                component: TransactionsSingle,
                meta: {
                    title: "Transactions",
                }
            },
            {
                path: "account/wallet",
                name: "Wallet",
                component: Wallet,
                meta: {
                    title: "Wallet",
                }
            },
            {
                path: "account/trial-balance",
                name: "Trial-Balance",
                component: TrialBalance,
                meta: {
                    title: "Trial-Balance",
                }
            },
            {
                path: "account/income-statement/structure",
                name: "Structure",
                component: IncomeStructure,
                meta: {
                    title: "Structure",
                }
            },
            {
                path: "account/income-statement/statement",
                name: "Statement",
                component: IncomeStatement,
                meta: {
                    title: "Statement",
                }
            },
            {
                path: "account/notes",
                name: "Note",
                component: Notes,
                meta: {
                    title: "Notes",
                }
            },
            {
                path: "account/note-details/:id",
                name: "NoteDetails",
                component: noteDetail,
                meta: {
                    title: "Note Details",
                }
            },
            {
                path: "account/balance-sheet/statement",
                name: "Balance-Sheet",
                component: BalanceSheet,
                meta: {
                    title: "Balance Sheet",
                }
            },
            {
                path: "payments/requisition/list",
                name: "RequisitionPayments",
                component: RequisitionPayments,
                meta: {
                    title: "Requisition Payments",
                }
            },
            {
                path: "payments/invoice/list",
                name: "InvoicePayments",
                component: InvoicePayments,
                meta: {
                    title: "Invoice Payments",
                }
            },
            {
                path: "payments/bill/list",
                name: "BillPayments",
                component: BillPayments,
                meta: {
                    title: "Bill Payments",
                }
            }
        ]
    },

    // Job
    {
        path: "/job",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "dashboard",
                name: "JobDashboard",
                component: JobDashboard,
                meta: {
                    title: "Job Dashboard",
                },
            },
            {
                path: "job/list",
                name: "Jobs",
                component: Jobs,
                meta: {
                    title: "Jobs",
                },
            },
            {
                path: "job/detail/:id",
                name: "JobDetail",
                component: JobDetail,
                meta: {
                    title: "Job Detail",
                },
            },
            {
                path: "job/create",
                name: "CreateJob",
                component: CreateJob,
                meta: {
                    title: "Create Job",
                },
            },
            {
                path: "job/edit/:id",
                name: "UpdateJob",
                component: UpdateJob,
                meta: {
                    title: "Edit Job",
                },
            },
            {
                path: "canditate/list",
                name: "Candidates",
                component: Candidates,
                meta: {
                    title: "Candidates",
                },
            },
            {
                path: "canditate/detail/:id",
                name: "CandidateDetails",
                component: CandidateDetails,
                meta: {
                    title: "Candidate Details",
                },
            },
            {
                path: "messages",
                name: "Messages",
                component: Messages,
                meta: {
                    title: "Messages",
                },
            },
            {
                path: "evaluation",
                name: "Evaluation",
                component: Evaluation,
                meta: {
                    title: "Evaluation",
                },
            },
            {
                path: "question-set",
                name: "QuestionSet",
                component: QuestionSet,
                meta: {
                    title: "Question Set",
                },
            },
            {
                path: "question-set/create",
                name: "CreateQuestionSet",
                component: CreateQuestionSet,
                meta: {
                    title: "Create Question Set",
                },
            },
        ]
    },

    {
        path: "/jobportal",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "jobportal/dashboard",
                name: "JobPortal",
                component: JobList,
                meta: {
                    title: "Job Portal",
                },
            },

        ]
    },



    //Notifier
    {
        path: "/auth",
        component: Auth,
        children: [
            {
                path: "login",
                name: "Login 1",
                component: login,
                meta: {
                    title: " login",
                },
            },
            {
                path: "register",
                name: "register 1",
                component: Register,
                meta: {
                    title: " Register | Ambala",
                },
            },
            {
                path: "reset-password",
                name: "Reset Password",
                component: resetPassword,
                meta: {
                    title: " Reset Password",
                },
            },



        ],
    },
    { path: "", redirect: { name: "Transactions" } },
    { path: "/dashboard", redirect: { name: "Transactions" } },
    // {
    //     path: "/dashboard",
    //     component: Body,
    //     meta: {
    //         requiresAuth: true,
    //     },
    //     children: [
    //         {
    //             path: "",
    //             name: "dashboard",
    //             component: Dashboard,
    //             meta: {
    //                 title: "Dashboard",
    //             },
    //         },
    //     ],
    // },
    {
        path: "/messaging",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "send",
                name: "send",
                component: SendSms,
                meta: {
                    title: "Send SMS",
                },
            },
            {
                path: "dynamic",
                name: "dynamic",
                component: DynamicSms,
                meta: {
                    title: "Dynamic SMS",
                },
            },
        ],
    },
    {
        path: "/sms",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "template",
                name: "smsTemplate",
                component: SmsTemplate,
                meta: {
                    title: "SMS TEMPLATE",
                },
            },
            {
                path: "price-and-coverage",
                name: "priceAndCoverage",
                component: PriceAndCoverage,
                meta: {
                    title: "Price & Coverage",
                },
            },
            {
                path: "billing",
                name: "billing",
                component: Billing,
                meta: {
                    title: "Billing",
                },
            },
        ],
    },
    {
        path: "/address-book",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "contact-group",
                name: "contactGroup",
                component: ContactGroup,
                meta: {
                    title: "Contact Group",
                },
            },
            {
                path: "manage-contact",
                name: "manageContact",
                component: ManageContact,
                meta: {
                    title: "Manage Contact",
                },
            },
        ],
    },
    {
        path: "/report",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "today",
                name: "today",
                component: TodaysReport,
                meta: {
                    title: "Today's Report",
                },
            },
            {
                path: "all",
                name: "all",
                component: AllReport,
                meta: {
                    title: "All Time Report",
                },
            },
        ],
    },
    {
        path: "/user",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [

            {
                path: "profile",
                name: "MyProfile",
                component: MyProfile,
                meta: {
                    title: "My Profile",
                },
            },
            {
                path: "profile/edit/:id",
                name: "EditMyProfile",
                component: EditMyProfile,
                meta: {
                    title: "My Profile",
                },
            },
            {
                path: "change-password",
                name: "changePassword",
                component: ChangePassword,
                meta: {
                    title: "Change Password",
                },
            },
            {
                path: "all",
                name: "all",
                component: AllReport,
                meta: {
                    title: "All Time Report",
                },
            },
        ],
    },
    {
        path: "/developers",
        component: Body,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: "api-documentation",
                name: "apiDocumentation",
                component: ApiDocumentation,
                meta: {
                    title: "API Documentation",
                },
            },

        ],
    },
    //Notifier -end


    // {
    //     path: "/dashboard",
    //     component: Body,
    //     meta: {
    //         requiresAuth: true,
    //     },
    //     children: [
    //         {
    //             path: "default",
    //             name: "default",
    //             component: Default,
    //             meta: {
    //                 title: "Default Dashboard | Cuba - Premium Admin Template",
    //             },
    //         },
    //         {
    //             path: "ecommerce",
    //             name: "ecommerce",
    //             component: Ecommerce,
    //             meta: {
    //                 title: "Ecommerce Dashboard | Cuba - Premium Admin Template",
    //             },
    //         },
    //     ],
    // },

    {
        path: "/error-400",
        name: "Error400",
        component: Error400,
        meta: {
            title: "Error400 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/error-401",
        name: "Error401",
        component: Error401,
        meta: {
            title: "Error401 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/error-403",
        name: "Error403",
        component: Error403,
        meta: {
            title: "Error403 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/error-404",
        name: "Error404",
        component: Error404,
        meta: {
            title: "Error404 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/error-500",
        name: "Error500",
        component: Error500,
        meta: {
            title: "Error500 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/error-503",
        name: "Error503",
        component: Error503,
        meta: {
            title: "Error503 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/maintenance",
        name: "Maintenance",
        component: Maintenance,
        meta: {
            title: "Maintenance | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/comingsoon/comingsoon-simple",
        name: "ComingsoonSimple",
        component: ComingsoonSimple,
        meta: {
            title: "ComingsoonSimple | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/comingsoon/comingsoon-image",
        name: "ComingsoonImage",
        component: ComingsoonImage,
        meta: {
            title: "ComingsoonImage | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/comingsoon/comingsoon-video",
        name: "ComingsoonVideo",
        component: ComingsoonVideo,
        meta: {
            title: "ComingsoonVideo | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/login/one",
        name: "LoginOne",
        component: LoginOne,
        meta: {
            title: "LoginOne | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/login/two",
        name: "LoginTwo",
        component: LoginTwo,
        meta: {
            title: "LoginTwo | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/login/validate",
        name: "LoginValidate",
        component: LoginValidate,
        meta: {
            title: "LoginValidate | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/register/image",
        name: "RegisterImage",
        component: RegisterImage,
        meta: {
            title: "RegisterImage | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/register/image2",
        name: "RegisterImage2",
        component: RegisterImage2,
        meta: {
            title: "RegisterImage2 | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/unlockuser",
        name: "Unlock",
        component: Unlock,
        meta: {
            title: "Unlock | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/forgetpassword",
        name: "ForgetPassword",
        component: ForgetPassword,
        meta: {
            title: "ForgetPassword | Cuba - Premium Admin Template",
        },
    },
    {
        path: "/authentication/resetpassword",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {
            title: "ResetPassword | Cuba - Premium Admin Template",
        },
    },
];

const router = new Router({
    routes,
    base: process.env.BASE_URL,
    mode: "history",
    linkActiveClass: "active",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {


    firebase.auth().onAuthStateChanged(() => {
        if (to.meta.title) document.title = to.meta.title;
        const CurrentUser = firebase.auth().currentUser;
        const path = ["/auth/login", "/auth/register","/auth/reset-password","/auth/reset"];
        const JWTuser = localStorage.getItem("user");
        if (
            path.includes(to.path) ||
            to.path === "/callback" ||
            CurrentUser ||
            Userauth.isAuthenticatedUser() ||
            JWTuser
        ) {
            return next();
        }
        next("/auth/login");
    });
});

export default router;
