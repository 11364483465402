<template>
<div>
    <Breadcrumbs main="My Workspace" submain="EOD" title="EOD Report Submit" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">

      
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Report Details</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter Report Details..." rows="4" v-model="eod.report"></textarea>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Reported To</label>
                                        <multiselect v-model="reported_to" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="handleDraftSubmit" class="btn btn-info mr-3">Save as Draft</button>
                                        <button @click="handleSubmit" class="btn btn-success mr-3">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </px-card>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6" >
                <div class="card">
                    <div class="card-body">
                        <h6 class="mb-3">Completed Tasks</h6>
                        <div v-for="task in complete_tasks" :key="task.id" class="my-2">
                            <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 12px; margin-bottom: 6px;">
                                <div style="display: flex; gap: 8px;">
                                    <h6>{{ complete_tasks.indexOf(task) + 1 }}.</h6>
                                    <h6>{{ task.title }}</h6>
                                </div>
                                <b-badge variant="danger">{{ task.end_date }}</b-badge>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>

           
            <div class="col-sm-6" >
                <div class="card">
                    <div class="card-body">
                        <h6 class="mb-3">Completed Tickets</h6>
                        <div v-for="ticket in complete_tickets" :key="ticket.id" > 
                            <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 12px; margin-bottom: 6px;">
                                <div style="display: flex; gap: 8px;">
                                    <h6>{{ complete_tickets.indexOf(ticket) + 1 }}.</h6>
                                    <h6>{{ ticket.subject }}</h6>
                                </div>

                                <span v-if="ticket.priority == 'LOW'">
                                    <b-badge pill variant="info">{{ ticket.priority }}</b-badge>
                                </span>
                                <span v-if="ticket.priority == 'MEDIUM'">
                                    <b-badge pill variant="warning">{{ ticket.priority }}</b-badge>
                                </span>
                                <span v-if="ticket.priority == 'HIGH'">
                                    <b-badge pill variant="danger">{{ ticket.priority }}</b-badge>
                                </span>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>

        
    </div>
    <!-- Container-fluid Ends-->

</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    myHost
} from '../../../../_helpers/host';

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,

            update_priority_id: '',
            selectedPriority: '',
            priorities: ['HIGH', 'MEDIUM', 'LOW'],

            assignedMembers: [],

            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            temp_id: '',

            statuses: ['NEW', 'PENDING', 'APPROVED', 'DONE'],
            priorities: ['HIGH', 'MEDIUM', 'LOW'],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {},

            ////

            eod: {
                report: '',
                reported_to: null,
            },
            reported_to: [],
            draft_id: '',
        };
    },

    computed: {
        ...mapState({
            user_list: state => state.employee.employee_list,
            draft: state => state.eod.my_draft,
            complete_tasks: state => state.eod.my_complete_task,
            complete_tickets: state => state.eod.my_complete_tickets,

        }),
        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },
    watch: {
        draft(oldValue, newValue) {
            this.eod.report = this.draft.report
            this.draft_id = this.draft.id
        },

    },

    mounted() {
        this.$store.dispatch("employee/fetchEmployeeList");

        this.$store.dispatch("eod/fetchMyDraft");
        this.$store.dispatch("eod/fetchEODCompleteTask");

    },

    methods: {


        handleSubmit() {
            if (!Array.isArray(this.eod.report)) {
                this.eod.reported_to = [];
            }
            this.reported_to.forEach(item => {
                this.eod.reported_to.push(item.id);
            })

            this.$store.dispatch("eod/addEOD", this.eod).then(response => {
                if (response.code == 200) {
                    this.$store.dispatch("eod/deleteEODDraft", {
                        id: this.draft_id,
                    }).then(response => {
                        if (response.code == 200) {

                        } else {

                        }

                    })
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.reported_to = []
                    this.eod.report = ''
                    this.eod.reported_to = []

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

        },

        handleDraftSubmit() {

            this.eod.reported_to = [];

            this.$store.dispatch("eod/addEODDraft", this.eod).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.reported_to = []
                    this.eod.report = ''
                    this.eod.reported_to = []

                } else if (response.code == 400) {
                    this.$store.dispatch("eod/updateEODDraft", {
                        id: this.draft_id,
                        report: this.eod.report
                    }).then(response => {
                        if (response.code == 200) {
                            Swal.fire({
                                title: "Success!",
                                text: response.response,
                                icon: "success"
                            });

                            this.delete_value = null

                        } else {
                            Swal.fire({
                                title: "Error!",
                                text: response.response,
                                icon: "error"
                            });
                        }
                    })
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

        },

    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}


.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
