<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Category"/>

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i>
                                <h7>Add New Category</h7>
                            </button>
                        </div>

                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                  <template #cell(precedence)="data">

                                     <span @click="getPriority(data.item.precedence, data.item.id)" >

                                         <span v-b-modal.modal-prevent-closing-priority >{{data.item.precedence}}</span>
                                    </span>


                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                                                                       <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(data.item.id)" title="Delete"></feather>

                                            <feather @click="editCategory(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Create Category -->
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Create Category"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    label="Title"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="category.title"
                        placeholder="Enter category title"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Debit Logic"
                    label-for="name-input"
                    invalid-feedback="Debit Logic is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="category.debit_logic"
                        placeholder="Enter debit logic"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Credit Logic"
                    label-for="name-input"
                    invalid-feedback="Credit Logic is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="category.credit_logic"
                        placeholder="Enter credit logic"
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>

        <!-- Edit Category -->
        <b-modal
            id="modal-prevent-closing-edit"
            ref="modal"
            title="Edit Category"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleEditOk"
        >
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <b-form-group
                    label="Title"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="edit_category.title"
                        placeholder="Enter category title"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Debit Logic"
                    label-for="name-input"
                    invalid-feedback="Debit Logic is required"
                >
                    <b-form-input
                        id="name-input"
                        placeholder="Enter debit logic"
                        v-model="edit_category.debit_logic"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Credit Logic"
                    label-for="name-input"
                    invalid-feedback="Credit Logic is required"
                >
                    <b-form-input
                        id="name-input"
                        placeholder="Enter credit logic"
                        v-model="edit_category.credit_logic"
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
      <b-modal id="modal-prevent-closing-priority" ref="modal" title="Update Category Precedence" @show="resetModal" @hidden="resetModal" @ok="handlePriorityOk">
        <form ref="form" @submit.stop.prevent="handlePrioritySubmit">
          <b-form-group label="Serial" label-for="name-input">
            <b-form-input v-model="serial"></b-form-input>
          </b-form-group>
        </form>
      </b-modal>

      <b-modal
          id="modal-center"
          centered
          hide-header
          @ok="handleDeleteOk"
      >
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>
      </b-modal>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'title', label: 'Category', sortable: true },
                { key: 'debit_logic', label: 'Debit Logic', sortable: true },
                { key: 'credit_logic', label: 'Credit Logic', sortable: true },
                { key: 'precedence', label: 'Precedence', sortable: true },
                { key: 'action', label: 'Action', sortable: false }
            ],

             items: [
            //     { title: 'Digital Marketing', debit_logic: 'As of March major browsers', credit_logic: 'Lorem ipsum dolor sit amet' },
            //     { title: 'Front-end', debit_logic: 'Including Safari 12.1 and iOS Safari 12.3 support', credit_logic: 'Mauris fringilla, purus at sodales iaculis' },
            //     { title: 'Back-end', debit_logic: 'Major browsers now including Safari 12.1', credit_logic: 'Phasellus egestas ipsum nunc' }
             ],
          serial:0,
          category_id:0,

            category: {
                title: '',
                debit_logic: '',
                credit_logic: ''
            },

            edit_category: {
                title: '',
                credit_logic: '',
                debit_logic: ''
            },

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

  computed: {
    ...mapState('account', ["accountCategories", "accountCategoryLoading", "accountCategoryError", "submitError", "submitSuccess", "submitLoading"]),
    sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },
    created() {
      this.$store.dispatch('account/fetchAccountCategory')
      this.items = this.accountCategories
      this.totalRows=this.items.length

    },
    watch: {
      accountCategories(oldValue, newValue) {

        this.items = this.accountCategories
        this.totalRows=this.items.length
      },


    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },

    methods: {
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },

      resetModal() {
        this.category.title = ''
        this.category.credit_logic = ""
        this.category.debit_logic = ''
      },

      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },

      handleEditOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleEditSubmit()
      },

      handleSubmit() {
        this.$store.dispatch("account/addAccountCategory", this.category).then(response => {
          if(response.code == 200) {
            Swal.fire({
              title: "Success!",
              text: response.response,
              icon: "success"
            });

            this.category.title =""
            this.category.credit_logic =""
            this.category.debit_logic = ""

          } else {
            Swal.fire({
              title: "Error!",
              text: response.response,
              icon: "error"
            });
          }
        })

        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      },

      handlePriorityOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handlePrioritySubmit()
      },

      handlePrioritySubmit() {
        this.$store.dispatch("account/updateSerialCategory", {
          id: this.category_id,
          serial: this.serial
        }).then(response => {
          if (response.code == 200) {
            Swal.fire({
              title: "Success!",
              text: response.response,
              icon: "success"
            });

            this.delete_value = null

          } else {
            Swal.fire({
              title: "Error!",
              text: response.response,
              icon: "error"
            });
          }
        })
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing-priority')
        })
      },
      getPriority(serial, id) {
        this.category_id = id
        this.serial = serial
      },

      editCategory(item) {
        console.log(item)
        this.edit_category.id = item.id
        this.edit_category.title =item.title
        this.edit_category.credit_logic = item.credit_logic
        this.edit_category.debit_logic = item.debit_logic

      },

      // resetEditModal() {
      //     this.edit_item.name = ""
      //     this.edit_item.id = null
      //     this.edit_item.rate = null
      //     this.edit_item.description = ""
      // },

      handleEditSubmit() {
        this.$store.dispatch("account/updateAccountCategory", this.edit_category).then(response => {
          if(response.code == 200) {
            Swal.fire({
              title: "Success!",
              text: response.response,
              icon: "success"
            });


            this.edit_category.id = ""
            this.edit_category.title =""
            this.edit_category.credit_logic =""
            this.edit_category.debit_logic = ""

          } else {
            Swal.fire({
              title: "Error!",
              text: response.response,
              icon: "error"
            });
          }
        })

        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing-edit')
        })
      },

      deleteItem(id) {
        this.delete_value = id
      },

      handleDeleteOk() {
        this.$store.dispatch("account/deleteAccountCategory", this.delete_value).then(response => {
          if(response.code == 200) {
            Swal.fire({
              title: "Success!",
              text: response.response,
              icon: "success"
            });

            this.delete_value = null

          } else {
            Swal.fire({
              title: "Error!",
              text: response.response,
              icon: "error"
            });
          }
        })
      }
    }
};
</script>
