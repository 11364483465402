<template>
    <div>
        <Breadcrumbs main="HR" submain="Training Management" title="Trainings"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">

                        <div class="card-header">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i> 
                                <h7>Add New Training</h7>
                            </button>
                        </div>
            
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="training_list"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(training_type)="data">
                                        <span>
                                            {{ data.item.training_type.name }}
                                        </span>
                                    </template>

                                    <template #cell(trainer)="data">
                                        <span>
                                            {{ data.item.trainer.name }}
                                        </span>
                                    </template>

                                    <template #cell(status)="data">
                                        <span v-if="data.item.status == true" @click="updateTrainingStatus(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="success">Active</b-badge>
                                        </span>
                                        <span v-if="data.item.status == false"  @click="updateTrainingStatus(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="danger">Inactive</b-badge>
                                        </span>
                                    </template> 

                                    <template #cell(training_employees)="data">
                                        <span>
                                            <div v-if="data.item.training_employees !== null && data.item.training_employees.length > 0" v-b-modal.modal-prevent-closing-employee title="Assign Employee" @click="getTraining(data.item)">
                                                <div v-for="user in data.item.training_employees" :key="user.id">
                                                    <p>{{ user.user.user_name }}</p>
                                                </div>
                                            </div>
                                            <div v-b-modal.modal-prevent-closing-employee title="Assign Employee" @click="getTraining(data.item)">
                                                <b-badge pill variant="primary">Assign Employee</b-badge>
                                            </div>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteTraining(data.item.id)" title="Delete"></feather>
                                            <div @click="editTraining(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Assign Employee Modal -->
        <b-modal id="modal-prevent-closing-employee" hide-footer title="Assign Employee">
            <div style="display: flex; flex-direction: column; gap: 15px;">
                <div v-if="training_employee.training_employees !== null">
                    <div class="row">
                        <div class="text-center col-sm-6 w-full" v-for="training in training_employee.training_employees" :key="training.id" v-b-tooltip.hover v-b-modal.modal-center-assigned-employee-delete @click="deleteAssignedEmployee(training.training_has_employee_id)" title="Delete">
                            <div class="w-100 mb-2 badge-style">
                                <h7>{{ training.user.user_name }}</h7>
                                <b-icon icon="x" style="color: white;"></b-icon>
                            </div>
                        </div>
                    </div>
                </div>

                <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="user_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
                <div class="d-flex justify-content-end ">
                    <button @click="assignMember" class="btn btn-primary" style="width: 170px;">Assign Employee</button>
                </div>
            </div>
        </b-modal>

        <!-- Training Create Modal -->
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Add Training"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="form-group">
                    <label>Training Type</label>
                    <select class="form-control digits" v-model="training.training_type">
                        <option v-for="(type, index) in training_type_list" :key="index" :value="type.id">{{ type.name }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Trainer</label>
                    <select class="form-control digits" v-model="training.trainer">
                        <option v-for="(type, index) in trainer_list" :key="index" :value="type.id">{{ type.name }}</option>
                    </select>
                </div>

                <b-form-group
                    label="Training Cost"
                    label-for="name-input"
                >
                    <b-form-input v-model="training.cost" placeholder="Enter training cost"></b-form-input>
                </b-form-group>
                

                <b-form-group
                    label="Starting date"
                    label-for="name-input"
                >
                    <b-form-datepicker id="example-datepicker-1" v-model="training.start_date"></b-form-datepicker>
                </b-form-group>

                <b-form-group
                    label="End date"
                    label-for="name-input"
                >
                    <b-form-datepicker id="example-datepicker" v-model="training.end_date"></b-form-datepicker>
                </b-form-group>

                <b-form-group
                    label="Status"
                    label-for="name-input"
                >
                <b-form-select v-model="training.status" :options="statusOptions"></b-form-select>
                </b-form-group>
            </form>
        </b-modal>

        <!-- Training Edit Modal -->
        <b-modal
            id="modal-prevent-closing-edit"
            ref="modal"
            title="Edit Training"
            @show="resetEditModal"
            @hidden="resetEditModal"
            @ok="handleEditOk"
        >
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <div class="form-group">
                    <label>Training Type</label>
                    <select class="form-control digits" v-model="edit_training.training_type">
                        <option v-for="(type, index) in training_type_list" :key="index" :value="type.id">{{ type.name }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Trainer</label>
                    <select class="form-control digits" v-model="edit_training.trainer">
                        <option v-for="(type, index) in trainer_list" :key="index" :value="type.id">{{ type.name }}</option>
                    </select>
                </div>

                <b-form-group
                    label="Training Cost"
                    label-for="name-input"
                >
                    <b-form-input v-model="edit_training.cost" placeholder="Enter training cost"></b-form-input>
                </b-form-group>
                

                <b-form-group
                    label="Starting date"
                    label-for="name-input"
                >
                    <b-form-datepicker id="example-datepicker-1" v-model="edit_training.start_date"></b-form-datepicker>
                </b-form-group>

                <b-form-group
                    label="End date"
                    label-for="name-input"
                >
                    <b-form-datepicker id="example-datepicker" v-model="edit_training.end_date"></b-form-datepicker>
                </b-form-group>

                <b-form-group
                    label="Status"
                    label-for="name-input"
                >
                <b-form-select v-model="edit_training.status" :options="statusOptions"></b-form-select>
                </b-form-group>
            </form>
        </b-modal>

        <!-- Status Update Modal -->
        <!-- <b-modal
            id="modal-prevent-closing-status"
            ref="modal"
            title="Update Training Status"
            @show="resetStatusModal"
            @hidden="resetStatusModal"
            @ok="handleStatusOk"
        >
            <form ref="form" @submit.stop.prevent="handleStatusSubmit">
                <b-form-group
                    label="Status"
                    label-for="name-input"
                >
                    <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
                </b-form-group>
            </form>
        </b-modal> -->

        <!-- Delete Modal -->
        <b-modal 
            id="modal-center" 
            centered 
            hide-header
            @ok="handleDeleteOk"
        >
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this training?</div>
        </b-modal>

        <!-- Assigned Employee Delete Modal -->
        <b-modal id="modal-center-assigned-employee-delete" centered hide-header @ok="handleEmployeeDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this employee?</div>
        </b-modal>
    </div> 
</template>
  
<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },

    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'training_type', label: 'Training Type', sortable: true },
                { key: 'trainer', label: 'Trainer', sortable: true },
                { key: 'cost', label: 'Cost', sortable: true },
                { key: 'start_date', label: 'Start Date', sortable: true },
                { key: 'end_date', label: 'End Date', sortable: true },
                { key: 'training_employees', label: 'Employees', sortable: true },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'action', label: 'Action', sortable: false }
            ],
            
            statusOptions: [
                {
                    text: 'Active',
                    value: true
                }, 
                {
                    text: 'Inactive',
                    value: false
                } 
            ],

            training: {
                training_type: null,
                trainer: null,
                cost:'',
                start_date: null,
                end_date: null,
                status: ''
            },

            edit_training: {
                id:null,
                training_type: null,
                trainer: null,
                cost:'',
                start_date: null,
                end_date: null,
                status: ''
            },

            delete_value:'',
            update_value:'',

            training_employee: {},
            training_id: null,
            assignedMembers: [],
            member: [],
            assigned_employee_id: null,

            filter: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            trainer_list: state => state.training.trainer,
            training_type_list: state => state.training.training_type,
            training_list: state => state.training.training_list,
            user_list: state => state.employee.employee_list
        }),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },

    mounted() {
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("training/fetchTrainingType");
        this.$store.dispatch("training/fetchTrainer");
        this.$store.dispatch("training/fetchTraining");
        this.totalRows = this.items.length;
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        getTraining(training) {
            this.training_id = training.id,
            this.training_employee = training
        },

        assignMember() {
            const idArray = this.assignedMembers.map(item => this.member.push({
                user_id: item.id,
                training_id: this.training_id
            }));

            this.$store.dispatch("training/assignEmployee", this.member).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.assignedMembers = [],
                    this.member = []

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-employee')
            })
        },

        deleteAssignedEmployee(id) {
            this.assigned_employee_id = id
        },

        handleEmployeeDeleteOk() {
            this.$store.dispatch("training/deleteAssignedEmployee", this.assigned_employee_id).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.assigned_employee_id = null
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-prevent-closing-employee')
                    })

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-center-assigned-employee-delete')
            })
        },

        getStatus(status) {
            this.selectedStatus = status
        },

        resetModal() {
            this.training.training_type = ''
            this.training.trainer= ''
            this.training.cost= ''
            this.training.start_date= ''
            this.training.end_date= ''
            this.training.status= ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        
        handleSubmit() {
            this.$store.dispatch("training/addTraining", this.training).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.training.training_type = ''
                    this.training.trainer= ''
                    this.training.cost= ''
                    this.training.start_date= ''
                    this.training.end_date= ''
                    this.training.status= ''
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editTraining(training) {
            this.edit_training.id = training.id
            this.edit_training.training_type = training.training_type.id
            this.edit_training.trainer = training.trainer.id
            this.edit_training.start_date = training.start_date
            this.edit_training.end_date = training.end_date
            this.edit_training.status = training.status
            this.edit_training.cost = training.cost 
        },

        resetEditModal() {
            this.edit_training.training_type = ''
            this.edit_training.trainer= ''
            this.edit_training.cost= ''
            this.edit_training.start_date= ''
            this.edit_training.end_date= ''
            this.edit_training.status= ''
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },
        
        handleEditSubmit() {
            this.$store.dispatch("training/updateTraining", {
                id: this.edit_training.id,
                training_type: this.edit_training.training_type,
                trainer: this.edit_training.trainer,
                cost:this.edit_training.cost,
                start_date: this.edit_training.start_date,
                end_date:this.edit_training.end_date,
                status:this.edit_training.status
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.edit_training.training_type = ''
                    this.edit_training.trainer= ''
                    this.edit_training.cost= ''
                    this.edit_training.start_date= ''
                    this.edit_training.end_date= ''
                    this.edit_training.status= ''
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        resetStatusModal() {
            this.selectedStatus = ''
        },

        // handleStatusOk(bvModalEvent) {
        //     bvModalEvent.preventDefault()
        //     this.handleSubmit()
        // },
        
        // handleStatusSubmit() {
        //     alert(this.selectedStatus)

        //     // Hide the modal manually
        //     this.$nextTick(() => {
        //         this.$bvModal.hide('modal-prevent-closing-status')
        //     })
        // },

        deleteTraining(id){
            this.delete_value = id
        },

        updateTrainingStatus(id){
            this.udate_value = id
            this.$store.dispatch("training/updateTrainingStatus", this.udate_value).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.udate_value = null
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        handleDeleteOk(){
            this.$store.dispatch("training/deleteTraining", this.delete_value).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.badge-style:hover {
    background-color: #ff595d;
}
</style>
  