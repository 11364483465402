<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" submain="Budget" title="Estimated Budgets"/>

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header header-mobile">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i>
                                <h7>Add New Estimated Budget</h7>
                            </button>
                        </div>

                        <div class="card-body">
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :current-page="currentPage"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <!-- <template #cell(status)="row">
                                        <span v-if="row.value == 'Active'">
                                            <b-badge pill variant="success">{{ row.value }}</b-badge>
                                        </span>
                                        <span v-if="row.value == 'Inactive'">
                                            <b-badge pill variant="danger">{{ row.value }}</b-badge>
                                        </span>
                                    </template> -->

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(data.item.id)" title="Delete"></feather>

                                            <feather @click="editItem(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>


                                        </span>
                                    </template>
                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Create Budget Category -->
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Create Estimated Budget"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    label="Budget Category"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="item.title"
                        placeholder="Enter budget category"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Amount"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="item.rate"
                        placeholder="Enter budget amount"
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>

        <!-- Edit Budget Category -->
        <b-modal
            id="modal-prevent-closing-edit"
            ref="modal"
            title="Edit Estimated Budget"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleEditOk"
        >
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <b-form-group
                    label="Budget Category"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="edit_item.title"
                        placeholder="Enter budget category"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Amount"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="edit_item.rate"
                        placeholder="Enter budget rate"
                    ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
      <b-modal
          id="modal-center"
          centered
          hide-header
          @ok="handleDeleteOk"
      >
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>
      </b-modal>
    </div>
</template>

<script>

import {mapState} from "vuex";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'title', label: 'Budget Category', sortable: true },
                { key: 'rate', label: 'Amount', sortable: true },
                { key: 'action', label: 'Action', sortable: false }
            ],

            items: [
                { title: 'Digital Marketing', rate: '35000', },
                { title: 'Front-end', rate: '350', },
                { title: 'Back-end', rate: '3500', }
            ],

            item: {
                title: '',
                rate: ''
            },

            edit_item: {
                title: '',
                rate: ''
            },

          delete_value:null,
            options: [
                { value: 'PENDING', text: 'Pending' },
                { value: 'APPROVED', text: 'Approved' },
                { value: 'REJECTED', text: 'Rejected' }
            ],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15]
        };
    },

  computed: {
    ...mapState('budget', ["estimateBudgets","budgetError","budgetLoading", "submitError", "submitSuccess", "submitLoading"]),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  created() {
    this.$store.dispatch('budget/fetchBudget').then(result => {
      this.items = this.estimateBudgets
    })
  },
  watch: {
    estimateBudgets(oldValue, newValue) {

      this.items = this.estimateBudgets

    }
  },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {

        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleSubmit() {
          this.$store.dispatch("budget/addBudget", this.item).then(response => {
            if(response.code == 200) {
              Swal.fire({
                title: "Success!",
                text: response.response,
                icon: "success"
              });

              this.item.title = '',
                  this.item.rate = null

            } else {
              Swal.fire({
                title: "Error!",
                text: response.response,
                icon: "error"
              });
            }
          })
            this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editItem(item) {
            this.edit_item.id = item.id
            this.edit_item.title = item.title
            this.edit_item.rate = item.rate
        },

        handleEditSubmit() {
          this.$store.dispatch("budget/updateBudget", this.edit_item).then(response => {
            if(response.code == 200) {
              Swal.fire({
                title: "Success!",
                text: response.response,
                icon: "success"
              });

              this.edit_item.id = '',
              this.edit_item.title = '',
                  this.edit_item.rate = null

            } else {
              Swal.fire({
                title: "Error!",
                text: response.response,
                icon: "error"
              });
            }
          })

            // Hide the modal manually
            this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
        deleteItem(id) {
          this.delete_value = id
        },

        handleDeleteOk() {
          this.$store.dispatch("budget/deleteBudget", this.delete_value).then(response => {
            if(response.code == 200) {
              Swal.fire({
                title: "Success!",
                text: response.response,
                icon: "success"
              });

              this.delete_value = null

            } else {
              Swal.fire({
                title: "Error!",
                text: response.response,
                icon: "error"
              });
            }
          })
        }

    }
};
</script>

<style scoped>
.header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


@media screen and (max-width: 460px) {
    .header-mobile {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: start;
    }
}
</style>
