<template>
    <div>
        <Breadcrumbs main="Vendor" title="RFQ" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                       
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                                    <div class="d-flex flex-column flex-xl-row " style="gap: 10px;">
                                        <b-input-group class="datatable-btn">
                                            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <!-- {{ rfp_category_list }} -->
    
                                        <div class="d-flex mt-3 mt-sm-0">
                                            <!-- <div class="mr-3 mr-md-4 field-width">
                                                <b-form-select v-model="rfp_category_id" :options="rfp_category_list"></b-form-select>
                                            </div> -->
    
                                            <select class="form-control" v-model="rfp_category_id">
                                                <option value="" disabled selected>Sort by category</option>
                                                <option v-for="(item, index) in rfp_category_list" :key="index" :value="item.id">{{ item.title }}</option>
                                            </select>
                                        </div>
                                    </div>
    
                                </b-col>
    
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
    
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="displayedRFPs" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
    
                                    <template #cell(rfp_id)="data">
                                        <span>
                                            <router-link :to="`/procurement/rfp/${data.item.id}`" class="link-style">
                                                {{ data.item.rfp_id }}
                                            </router-link>
                                        </span>
                                    </template>
    
                                    <template #cell(rfp_category)="data">
                                        <span v-if="data.item.rfp_category !== null">
                                            {{ data.item.rfp_category.title }}
                                        </span>
                                    </template>
    
                                    <template #cell(status)="row">
                                        <span v-if="row.value == 'COMPLETED'">
                                            <b-badge pill variant="success" >{{ row.value }}</b-badge>
                                        </span>
                                        <span  v-if="row.value == 'WORKING'">
                                            <b-badge pill variant="info" >{{ row.value }}</b-badge>
                                        </span>
                                        <span  v-if="row.value == 'APPROVED'">
                                            <b-badge pill variant="primary" >{{ row.value }}</b-badge>
                                        </span>
                                    </template>
    
                                    <template #cell(form_submit)="data">
                                        <span v-if="data.item.form_generated == true">
                                            <router-link :to="`/procurement/rfp/submit-form/${data.item.id}`">
                                                <b-button variant="secondary" style="width: 125px;">View Form</b-button>
                                            </router-link>
                                        </span>
    
                                    </template>
    
                                    <!-- <template #cell(request)="data">
                                       
                                        <span >
                                            <router-link :to="`/vendor/request/quotation/${data.item.id}`">
                                                <b-button variant="primary" style="width: 125px;">Request</b-button>
                                            </router-link>
                                        </span>
                                    </template> -->
    
                                    <template #cell(active_status)="data">
    
                                        <div v-if="data.item.active == true" @click="activeStatus(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="success" v-b-tooltip.hover title="Toggle status">Active</b-badge>
                                        </div>
                                        <div v-if="data.item.active == false" @click="activeStatus(data.item.id)" style="cursor: pointer;">
                                            <b-badge pill variant="danger" v-b-tooltip.hover title="Toggle status">Inactive</b-badge>
                                        </div>
                                    </template>
    
                            
                                </b-table>
                            </div>
    
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    
        <!-- Delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this RFP?</div>
        </b-modal>
    
        <!-- Update Status -->
        <b-modal id="modal-prevent-closing-status" ref="modal" title="Update RFP Status" @show="resetModal" @hidden="resetModal" @ok="handleStatusOk">
            <form ref="form" @submit.stop.prevent="handleStatusSubmit">
                <b-form-group label="Status" label-for="name-input">
                    <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
                </b-form-group>
            </form>
        </b-modal>
    </div>
    </template>
    
    <script>
    import {
        mapState
    } from 'vuex';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';

    
    export default {
        data() {
            return {
                tablefields: [{
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'rfp_id',
                        label: 'RFP ID',
                        sortable: true
                    },
                    {
                        key: 'title',
                        label: 'Title',
                        sortable: true
                    },
                    {
                        key: 'rfp_category',
                        label: 'Category',
                        sortable: true
                    },
                    {
                        key: 'deadline',
                        label: 'Deadline',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: true
                    },
    
                    
                    {
                        key: 'form_submit',
                        label: 'Form',
                        sortable: true,
                        class: 'text-center',
                    },
                    // {
                    //     key: 'request',
                    //     label: 'Request',
                    //     sortable: true,
                    //     class: 'text-center'
    
                    // },
                 
                  
                 
                ],
    
                filter: null,
                rfp_category_id: '',
                delete_value: null,
                rfp_update_id: '',
    
                statuses: ['COMPLETED', 'WORKING', 'APPROVED'],
                selectedStatus: '',
    
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 15]
            };
        },
    
        computed: {
            ...mapState({
                items: state => state.rfp.rfp,
                rfp_category_list: state => state.rfp.rfp_category,
            }),
    
            rfp() {
                return this.$store.state.rfp.rfp;
            },
    
            filteredRFPs() {
                return this.items.filter(rfp => rfp.rfp_category.id === this.rfp_category_id);
            },
    
            displayedRFPs() {
                return this.rfp_category_id ? this.filteredRFPs : this.rfp;
            },
    
            sortOptions() {
                return this.tablefields
                    .filter(f => f.sortable)
                    .map(f => {
                        return {
                            text: f.label,
                            value: f.key
                        };
                    });
            }
        },
    
        mounted() {
            this.$store.dispatch("rfp/fetchRFPCategory");
            this.$store.dispatch("rfp/fetchRFP").then(() => {
                this.totalRows = this.items.length;
            });
    
        },
    
        methods: {
    
            getStatus(status, id) {
                this.rfp_update_id = id
                this.selectedStatus = status
            },
    
            sortByCategory() {
    
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
    
            deleteRFP(id) {
                this.delete_value = id
            },
    
            handleDeleteOk() {
                this.$store.dispatch("rfp/deleteRFP", this.delete_value).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.delete_value = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            activeStatus(id) {
    
                this.$store.dispatch("rfp/toggleRFPStatus", id).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.udate_value = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },
    
            handleStatusOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleStatusSubmit()
            },
    
            handleStatusSubmit() {
    
                alert(JSON.stringify(this.rfp_update_id))
                alert(JSON.stringify(this.selectedStatus))
                this.$store.dispatch("rfp/updateRfpStatus", {
                    id: this.rfp_update_id,
                    status: this.selectedStatus
    
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.delete_value = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing-status')
                })
            },
    
        }
    };
    </script>
    
    <style scoped>
    .header-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    @media screen and (max-width: 460px) {
        .header-mobile {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: start;
        }
    }
    </style>
    