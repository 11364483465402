<template>
    <div>
        <Breadcrumbs main="HR" submain="Payroll" title="Payroll"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <button class="btn btn-primary" gross_salary="submit" v-b-modal.modal-prevent-closing-xl style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i> 
                                <h7>Add New Payroll</h7>
                            </button>
                        </div>
            
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Search..."></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template> 

                                    <template #cell(employee)="data">
                                        <router-link class="link-style" :to="{ path: '/hr/payroll/payroll-history/' + data.item.employee.id }">
                                            {{ data.item.employee.first_name + ' ' + data.item.employee.last_name }}
                                        </router-link> 
                                    </template>

                                    <template #cell(department)="data"> 
                                        <p v-if="data.item.designation">{{ data.item.designation.department.name }}</p>
                                    </template>

                                    <template #cell(designation)="data"> 
                                        <p v-if="data.item.designation">{{ data.item.designation.title }}</p> 
                                    </template>

                                    <template #cell(shift)="data">
                                        <div>
                                            <div @click="shiftPayroll(data.item)">
                                                <b-button v-b-modal.modal-prevent-closing-edit-xl variant="primary">Shift</b-button>
                                            </div>
                                        </div>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;"> 
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover name="Delete" v-b-modal.modal-center @click="deleteItems(data.item.id)"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Payroll Create Modal -->
        <b-modal
            id="modal-prevent-closing-xl"
            size="xl"
            ref="modal"
            title="Add Payroll"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="form-inside-container">
                    <div class="header-width">
                        <b-form-group
                            label="Department"
                            label-for="name-input"
                        > 
                            <b-form-select v-model="designation_payroll.department" @change="getDesignationByDepartment">
                                <b-form-select-option v-for="option in departments" :value="option.id">{{ option.name }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="header-width">
                        <b-form-group
                            label="Designation"
                            label-for="name-input"
                        >
                            <b-form-select v-model="designation_payroll.designation" @change="getDesignationPayroll">
                                <b-form-select-option v-for="option in designations" :value="option.id">{{ option.title }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>
             
                    <div class="header-width">
                        <b-form-group
                            label="Employee"
                            label-for="name-input"
                        >
                            <b-form-select v-model="designation_payroll.employee">
                                <b-form-select-option v-for="option in employees" :value="option.id">{{ option.user_name }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </div>

                <div class="form-inside-container">
                    <div class="header-width item-padding">
                        <h6>Inclusions (+)</h6>
                        <div>
                            <div style="display: flex; flex-direction: column; gap: 10px;">
                                <div v-for="(inc, index) in create_inclusions" :key="index">
                                    <div style="display: flex; align-items: center; width: 100%;">
                                        <div style="display: flex; flex-direction: row; gap: 10px; width: 90%;">
                                            <b-form-select v-model="inc.item">
                                                <b-form-select-option v-for="option in inclusion_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                            </b-form-select>
                                            <b-form-input v-model="inc.amount" type="number" placeholder="Enter amount"></b-form-input>
                                        </div>

                                        <div style="width: 50px; margin-left: 10px; display: flex; gap: 4px; align-items: center;">
                                            <!-- Add Svg Icon -->
                                            <svg @click="addField(inc.id, create_inclusions)" width="24" height="24" class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>
                                            </svg>

                                            <!-- Remove Svg Icon -->
                                            <svg v-if="create_inclusions.length > 1 && !inc.id" @click="removeField(index, create_inclusions)" width="24" height="24"       class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="header-width item-padding">
                       <h6>Deductions (-)</h6>
                       <div>
                            <div style="display: flex; flex-direction: column; gap: 10px;">
                                <div v-for="(deduction, index) in create_deductions" :key="index">
                                    <div style="display: flex; align-items: center; width: 100%;">
                                        <div style="display: flex; flex-direction: row; gap: 10px; width: 90%;">
                                            <b-form-select v-model="deduction.item">
                                                <b-form-select-option v-for="option in deduction_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                            </b-form-select>
                                            <b-form-input v-model="deduction.amount" type="number" placeholder="Enter amount"></b-form-input>
                                        </div>

                                        <div style="width: 50px; margin-left: 10px; display: flex; gap: 4px; align-items: center;">
                                            <!-- Add Svg Icon -->
                                            <svg @click="addDedField(deduction.id, create_deductions)" width="24" height="24" class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>
                                            </svg>

                                            <!-- Remove Svg Icon -->
                                            <svg v-if="create_deductions.length > 1 && !deduction.id" @click="removeDedField(index, create_deductions)" width="24" height="24"       class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="display: flex; align-items: center; margin-top: 20px;">
                    <h6 style="margin-top: 7px;">Gross Salary</h6>
                    <b-form-input v-model="create_gross_salary" disabled style="width: 300px; margin-left: 16px;"></b-form-input>
                </div>
            </form>
        </b-modal>

        <!-- Payroll Shift Modal -->
        <b-modal
            id="modal-prevent-closing-edit-xl"
            size="xl"
            ref="modal"
            title="Shift Payroll"
            @show="resetEditModal"
            @hidden="resetEditModal"
            @ok="handleEditOk"
        >
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <div class="form-inside-container">
                    <div class="header-width">
                        <b-form-group
                            label="Department"
                            label-for="name-input"
                        >
                            <b-form-select v-model="shift_designation_payroll.department" disabled>
                                <b-form-select-option v-for="option in departments" :value="option.id">{{ option.name }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="header-width">
                        <b-form-group
                            label="Designation"
                            label-for="name-input"
                        >
                            <b-form-select v-model="shift_designation_payroll.designation" disabled>
                                <b-form-select-option v-for="option in designations" :value="option.id">{{ option.title }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="header-width">
                        <b-form-group
                            label="Employee"
                            label-for="name-input"
                        >
                            <b-form-select v-model="shift_designation_payroll.employee" disabled>
                                <b-form-select-option v-for="option in employees" :value="option.id">{{ option.user_name }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </div>

                <div class="form-inside-container">
                    <div class="header-width item-padding">
                        <h6>Inclusions (+)</h6>
                        <div>
                            <div style="display: flex; flex-direction: column; gap: 10px;">
                                <div v-for="(inc, index) in shift_inclusions" :key="index">
                                    <div style="display: flex; align-items: center; width: 100%;">
                                        <div style="display: flex; flex-direction: row; gap: 10px; width: 90%;">
                                            <b-form-select v-model="inc.item">
                                                <b-form-select-option v-for="option in inclusion_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                            </b-form-select>
                                            <b-form-input v-model="inc.amount" type="number" placeholder="Enter amount"></b-form-input>
                                        </div>

                                        <div style="width: 50px; margin-left: 10px; display: flex; gap: 4px; align-items: center;">
                                            <!-- Add Svg Icon -->
                                            <svg @click="addField(inc.id, shift_inclusions)" width="24" height="24" class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>
                                            </svg>

                                            <!-- Remove Svg Icon -->
                                            <svg v-if="shift_inclusions.length > 1 && !inc.id" @click="removeField(index, shift_inclusions)" width="24" height="24"       class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                            </svg>

                                            <!-- Delete Svg Icon -->
                                            <svg v-if="shift_inclusions.length > 1 && inc.id" @click="deleteInclusion(inc.id)" width="24" height="24"       class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="header-width item-padding">
                       <h6> Deductions (-)</h6>
                       <div>
                            <div style="display: flex; flex-direction: column; gap: 10px;">
                                <div v-for="(deduction, index) in shift_deductions" :key="index">
                                    <div style="display: flex; align-items: center; width: 100%;">
                                        <div style="display: flex; flex-direction: row; gap: 10px; width: 90%;">
                                            <b-form-select v-model="deduction.item">
                                                <b-form-select-option v-for="option in deduction_items" :value="option.id">{{ option.name }}</b-form-select-option>
                                            </b-form-select>
                                            <b-form-input v-model="deduction.amount" type="number" placeholder="Enter amount"></b-form-input>
                                        </div>

                                        <div style="width: 50px; margin-left: 10px; display: flex; gap: 4px; align-items: center;">
                                            <!-- Add Svg Icon -->
                                            <svg @click="addDedField(deduction.id, shift_deductions)" width="24" height="24" class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>
                                            </svg>

                                            <!-- Remove Svg Icon -->
                                            <svg v-if="shift_deductions.length > 1 && !deduction.id" @click="removeDedField(index, shift_deductions)" width="24" height="24"       class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                            </svg>

                                            <!-- Delete Svg Icon -->
                                            <svg v-if="shift_deductions.length > 1 && deduction.id" @click="deleteDeduction(deduction.id)" width="24" height="24"       class="cursor-pointer">
                                                <path fill="none" d="M0 0h24v24H0z" />
                                                <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="display: flex; align-items: center; margin-top: 20px;">
                    <h6 style="margin-top: 7px;">Gross Salary</h6>
                    <b-form-input v-model="shift_gross_salary" disabled style="width: 300px; margin-left: 16px;"></b-form-input>
                </div>
            </form>
        </b-modal>

        <!-- Payroll delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this payroll?</div>
        </b-modal>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; 
import { computed } from 'vue';

export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'employee', label: 'Employee', sortable: true },
                { key: 'department', label: 'Department', sortable: true },
                { key: 'designation', label: 'Designation', sortable: true },
                { key: 'gross_salary', label: 'Gross Salary', sortable: true },
                { key: 'shift', label: 'Shift', sortable: false },
                { key: 'action', label: 'Action', sortable: false }
            ],

            designations: [],

            designation_payroll: {
                designation: null,
                department: null,
                employee: null
            },
           
            create_inclusions: [
                {
                    item: '',
                    amount: 0
                }
            ],

            create_deductions: [
                {
                    item: '',
                    amount: 0
                }
            ],

            create_gross_salary: computed(() => {
                let total = 0;
                let inclusion_total = 0;
                let deduction_total = 0;

                if (this.create_inclusions.length >= 1) {
                    this.create_inclusions.map((item) => {
                        inclusion_total = parseInt(inclusion_total) + parseInt(item.amount);
                    });
                } else {
                    inclusion_total = 0
                }

                if (this.create_deductions.length >= 1) {
                    this.create_deductions.map((item) => {
                        deduction_total = parseInt(deduction_total) + parseInt(item.amount);
                    });
                } else {
                    deduction_total = 0
                }

                total = parseInt(inclusion_total) - parseInt(deduction_total)

                return total;
            }),

            delete_value: null,

            shift_designation_payroll: {
                employee: null,
                designation: null,
                department: null
            },

            shift_inclusions: [
                {
                    item: '',
                    amount: 0
                }
            ],

            shift_deductions: [
                {
                    item: '',
                    amount: 0
                }
            ],

            shift_gross_salary: computed(() => {
                let total = 0;
                let inclusion_total = 0;
                let deduction_total = 0;

                if (this.shift_inclusions.length >= 1) {
                    this.shift_inclusions.map((item) => {
                        inclusion_total = parseInt(inclusion_total) + parseInt(item.amount);
                    });
                } else {
                    inclusion_total = 0
                }

                if (this.shift_deductions.length >= 1) {
                    this.shift_deductions.map((item) => {
                        deduction_total = parseInt(deduction_total) + parseInt(item.amount);
                    });
                } else {
                    deduction_total = 0
                }

                total = parseInt(inclusion_total) - parseInt(deduction_total)

                return total;
            }),

            filter: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            items: state => state.payroll.payroll_list,
            departments: state => state.department.departments,
            designation_list: state => state.designation.designation,
            employees: state => state.employee.employee_list,
            inclusion_items: state => state.payroll.inclusion_items,
            deduction_items: state => state.payroll.deduction_items
        }),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },

    mounted() {
        this.$store.dispatch("payroll/fetchPayrollList").then(() => {
            this.totalRows = this.items.length;
        });

        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("designation/fetchDesignations")
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("payroll/fetchInclusivePayrollItems")
        this.$store.dispatch("payroll/fetchDeductivePayrollItems")
    },

    watch: {
        designation_list(oldValue, newValue) {
            this.designations = this.designation_list
        }
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        addField(value, fieldType) {
            fieldType.push({ value: "", item: '', amount: 0 });
        },

        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        addDedField(value, fieldType) {
            fieldType.push({ value: "", item: '', amount: 0 });
        },

        removeDedField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        getDesignationByDepartment() {
            this.$store.dispatch("designation/fetchDesignationsbyDepartment", this.designation_payroll.department).then(result => {
                this.designations = result
            })
        },

        getDesignationPayroll() {
            this.$store.dispatch("payroll/fetchPayrollByDesignation", this.designation_payroll.designation).then(result => {
                if(result.inclusion !== null) {
                    this.create_inclusions = result.inclusion
                } else {
                    this.create_inclusions = [
                        {
                            item: '',
                            amount: 0
                        }
                    ]
                }

                if(result.deduction !== null) {
                    this.create_deductions = result.deduction
                } else {
                    this.create_deductions = [
                        {
                            item: '',
                            amount: 0
                        }
                    ]
                }
            })
        },

        resetModal() {
        //     this.designation_payroll.employee = null
        //     this.designation_payroll.department = null
        //     this.designation_payroll.designation = null
        //     this.create_gross_salary = 0
        //     this.create_inclusions = [
        //         {
        //             item: '',
        //             amount: null
        //         }
        //     ]
        //     this.create_deductions = [
        //         {
        //             item: '',
        //             amount: null
        //         }
        //     ]
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        
        handleSubmit() {
            this.$store.dispatch("payroll/addEmployeePayroll", {payroll: this.designation_payroll, gross_salary: this.create_gross_salary, inclusions: this.create_inclusions, deductions: this.create_deductions}).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.designation_payroll.department = null
                    this.designation_payroll.designation = null
                    this.designation_payroll.employee = null
                    this.create_gross_salary = 0
                    this.create_inclusions = []
                    this.create_deductions = []

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-xl')
            })
        },

        shiftPayroll(gross_salary) { 
            this.shift_designation_payroll.employee = gross_salary.employee.id
            this.shift_designation_payroll.department = gross_salary.designation.department.id
            this.shift_designation_payroll.designation = gross_salary.designation.id
            if(gross_salary.inclusion !== null) {
                this.shift_inclusions = gross_salary.inclusion
            } else {
                this.shift_inclusions = [
                    {
                        item: '',
                        amount: 0
                    }
                ]
            }

            if(gross_salary.deduction !== null) {
                this.shift_deductions = gross_salary.deduction
            } else {
                this.shift_deductions = [
                    {
                        item: '',
                        amount: 0
                    }
                ]
            }

            // if(gross_salary.gross_salary !== null) {
            //     this.shift_gross_salary = gross_salary.gross_salary
            // } else {
            //     this.shift_gross_salary = 0
            // }
        },

        resetEditModal() {
            // this.shift_designation_payroll.employee = null
            // this.shift_designation_payroll.department = null
            // this.shift_designation_payroll.designation = null
            // this.shift_gross_salary = 0
            // this.shift_deductions = []
            // this.shift_inclusions = []
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },
        
        handleEditSubmit() {
            this.$store.dispatch("payroll/addEmployeePayroll", {payroll: this.shift_designation_payroll, gross_salary: this.shift_gross_salary, inclusions: this.shift_inclusions, deductions: this.shift_deductions}).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.shift_designation_payroll.department = null
                    this.shift_designation_payroll.designation = null
                    this.shift_designation_payroll.employee = null
                    this.shift_gross_salary = 0
                    this.shift_inclusions = []
                    this.shift_deductions = []

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit-xl')
            })
        },

        deleteItems(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("payroll/deletePayroll", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
  
<style scoped>
.form-inside-container {
    display: flex; 
    gap: 20px; 
    width: 100%;
}

.header-width {
    width: 50%;
}

.item-padding {
    padding-left: 12px;
    padding-right: 12px;
}

.button-style {
    padding: 2px; 
    width: 32px; 
    font-size: larger; 
    text-align: center; 
    color: white; 
    background-color: #222131; 
    border-radius: 4px;
    cursor: pointer;
}

@media screen and (max-width: 991px) {
    .form-inside-container {
        display: flex; 
        flex-direction: column;
        gap: 0px;
    }

    .header-width {
        width: 100%;
    }

    .item-padding {
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>