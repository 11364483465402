import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'JOB_UPDATE',
    smsDelete : 'JOB_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
      
        job_list: [],
        job_count:{},
        job_details:{},

        applicant_list:[],
        applicant_details:{},

        jobLoading: false,
        jobError: false,
        jobSuccess:false,


        ...store
    },
    mutations : {
        ...mutations,

        SET_JOB (state, job) {
            state.job_list = job
        },
        SET_JOB_COUNT(state,job){
            state.job_count = job
        },
        SET_JOB_DETAILS(state,job){
            state.job_details = job
        },
        
        
        SET_APPLICANT (state, applicant) {
            state.applicant_list = applicant
        },

        SET_APPLICANT_DETAILS(state,applicant){
            state.applicant_details = applicant
        },



        JOB_LOADING (state, loading) {
            state.jobLoading = loading;
        },
        JOB_ERROR (state, loading) {
            state.jobError = loading;
        },
        JOB_SUBMIT_SUCCESS (state, success) {
            state.jobSuccess = success;
        }
    },

    actions: {
       
        async addJob ({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.job_create, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('JOB_SUBMIT_SUCCESS', true);
                dispatch ('fetchJob')
                return response

            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },

        
        async fetchJobDetails ({ commit }, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_job_details + payload, config);
                let response = result.data

                commit('SET_JOB_DETAILS', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            commit('JOB_LOADING', false);
        },


        async fetchJob ({ commit }) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_job_list, config);
                let response = result.data
                commit('SET_JOB', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            commit('JOB_LOADING', false);
        },


        async fetchJobCount ({ commit }) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_job_count, config);
                let response = result.data
                commit('SET_JOB_COUNT', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            commit('JOB_LOADING', false);
        },


        
        async updateJob({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.update_job+payload.id,payload.payload,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchJob');

                }
            )
            return response
        },


        async deleteJob({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_job + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchJob');
            
            return response
        },

        async updateJobStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.update_ticket_status+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data

                })
                dispatch("fetchJob")
                return response
            } catch (e) {

                commit('JOB_ERROR', true);
            } finally {

                commit('JOB_LOADING', false);
            }
        },


        //Applicant
        
        async fetchApplicant({ commit }) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_applicant, config);
                let response = result.data
                commit('SET_APPLICANT', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            commit('JOB_LOADING', false);
        },

        async fetchApplicantDetails ({ commit }, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_applicant_details + payload, config);
                let response = result.data

                commit('SET_APPLICANT_DETAILS', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            commit('JOB_LOADING', false);
        },






    },

    getters: {}
}
