<template>
<div>
    <Breadcrumbs main="My Workspace" submain="Requsition" title="My Requisitions" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header" style="max-width: fit-content">
                        <router-link to="/my-workspace/requisition-create">
                            <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">

                                <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                    <i class="fa fa-light fa-plus"></i>
                                    <h7>Add New Requisition</h7>
                                </div>

                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">
                            <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template #cell(title)="data">
                                    <span>

                                        {{ data.item.title }}

                                    </span>
                                </template>

                                <template #cell(raised_by)="data">
                                    <span>
                                        {{ data.item.raised_by.first_name }}
                                    </span>
                                </template>

                                <template #cell(refering_to)="data">
                                    <span>
                                        <div v-if="data.item.refering_to !== null">
                                            <div v-for="user in data.item.refering_to" :key="user.id">
                                                <p>{{ user.first_name }}</p>
                                            </div>
                                        </div>
                                    </span>
                                </template>

                                <template #cell(total)="data">
                                 
                                    <div class="d-flex flex-column" style="width: 150px;">
                                        
                                        <span class="font-weight-bold">Total: {{ data.item.grand_total }}</span>
                                        <span><span class="text-success font-weight-bold">Paid:</span> {{ data.item.paid_amount }}</span>
                                        <span><span class="text-danger font-weight-bold">Due:</span> {{ data.item.due_amount }}</span>
                                    </div>
                                </template>

                                
                                <template #cell(status)="row">

                                    <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'APPROVED'">
                                        <b-badge pill variant="success">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'PENDING'">
                                        <b-badge pill variant="primary">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'DUE'">
                                        <b-badge pill variant="danger">{{ row.value }}</b-badge>
                                    </span>
                                </template>
                                <template #cell(requisition_for)="row">

                                    <span  v-if="row.item.project == null">
                                        <b-badge pill variant="success">Personal</b-badge>
                                    </span>
                                    <span v-else>
                                        <b-badge pill variant="primary">Project</b-badge>
                                    </span>
                                   
                                </template>

                                

                                <template #cell(action)="data">
                                    <span>
                                        <!-- <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteRequisition(data.item.id)" title="Delete"></feather> -->
                                        <router-link :to="`/my-workspace/requisition-view/${data.item.id}`">
                                            <feather type="eye" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="View"></feather>
                                        </router-link>
                                    </span>
                                </template>
                            </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
    <!-- <b-modal id="modal-prevent-closing" ref="modal" title="Update Requisition Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Status" label-for="name-input">
                    <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
                </b-form-group>
            </form>
        </b-modal> -->

    <!-- Asset Delete Modal -->
    <!-- <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this requisition?</div>
    </b-modal> -->
</div>
</template>

    
<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'total',
                    label: 'Grand Total',
                    sortable: true
                },
                {
                    key: 'raised_by',
                    label: 'Raised By',
                    sortable: true
                },
                {
                    key: 'refering_to',
                    label: 'Reffered To',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false
                },
                {
                    key: 'requisition_for',
                    label: 'Requisition',
                    sortable: false
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            filter: null,
            delete_value: null,
            selectedStatus: null,
            update_id: null,
            statuses: ['DUE', 'PENDING', 'APPROVED'],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            items: state => state.requisition.my_requisition
        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {
        this.$store.dispatch("requisition/fetchMyRequisition");
        this.totalRows = this.items.length;
    },

    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteRequisition(id) {
            this.delete_value = id
        },
        getStatus(status, id) {
            this.update_id = id
            this.selectedStatus = status
            console.log(status, id)
        },
        resetModal() {

        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {

            this.$store.dispatch("requisition/updateRequisitionStatus", {
                id: this.update_id,
                status: this.selectedStatus
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        handleDeleteOk() {
            this.$store.dispatch("requisition/deleteRequisition", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
    }
};
</script>
