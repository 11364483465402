// import config from 'config';

/* eslint-disable */
import { authHeader } from '../_helpers';
import axios from 'axios'
import {V1API} from "../_helpers/APIPath"


export const userService = {
    login,
    logout,
    // getAll
};

 async function login(email, password) {

     let data = {
        "email": email,
        "password": password
     };

     let response = await axios.post(V1API.getAccessToken, data)
         .then(user=> {

             if (user.data.access_token) {
                // console.log(user.data.permissions)
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user.data));
                localStorage.setItem("access_token", user.data.access_token);
                localStorage.setItem("permissions", JSON.stringify(user.data.permissions));
                localStorage.setItem('reloaded', 'no')
                return user
             }else {
                let error = user
                return error
            }

        })
        .catch(function (error) {
            return error;
        });

    return response
}

function logout() {
    // remove user from local storage to log user out

    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('reloaded');
}

//
// function getAll() {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };
//
//     return fetch(`http://localhost:8080/users`, requestOptions).then(handleResponse);
// }

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
