<template>
<div>
    <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Quotation" />
    <div>
        <div class="invoice-container">

            <div style="display: flex; gap: 10px;">
                <b-button variant="primary" @click="editQuotation(quotationData.id)">Edit</b-button>
                <b-button variant="primary" @click="printPdf('invoice')">Print</b-button>
            </div>

        </div>
        <div class="row">
            <div class="col-xl-8">

                <div id="invoice">

                    <div class="content-container" :style="quotationBackgroundStyle">
                        <div class="invoice-header mt-5">
                            <!-- Company info -->
                            <div style="font-size: small; display: flex; flex-direction: row;">
                                <div style="display: flex; align-items: center;">
                                    <img style="height: 40px;" :src="host+setting.logo" alt="" />
                                </div>
                                <div style="margin-left: 16px;">
                                    <h5>{{ setting.title }}</h5>
                                    <div style="display: flex; flex-direction: column;">
                                        <div class="text-blue-500" style="display: flex; align-items: center; font-weight: 500;">
                                            <a :href="`tel:${setting.phone}`">
                                                {{ setting.phone }}
                                            </a>
                                        </div>

                                        <div class="text-blue-500" style="display: flex; align-items: center; font-weight: 500;">
                                            <a :href="`mailto:${setting.email}`">
                                                {{ setting.email }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Quotation Info -->
                            <div class="invoice-info" style="font-size: small;">
                                <h5>
                                    Quotation #{{ quotationData.quotation_id }}
                                </h5>
                                <p style="font-weight: 500;">
                                    Quotation Date:
                                    {{ parseDate(quotationData.quotation_date) }}
                                </p>
                                <p style="font-weight: 500; margin-top: -12px;">
                                    Due date: {{ parseDate(quotationData.due_date) }}
                                </p>
                            </div>
                        </div>

                        <div style="width: 100%; display: flex; justify-content: space-between;">
                            <!-- Client Info -->
                            <div class="client-info" style="font-size: small;">
                                <h6>Invoice to:</h6>
                                <h5 v-if="quotationData.client">
                                    {{ quotationData.client.username }}
                                </h5>
                                <a :href="`mailto:${quotationData.client.email}`">
                                    {{ quotationData.client.email }}
                                </a>
                                <p style="width: 75%; max-width: 65ch;">
                                    {{ quotationData.client_address }}
                                </p>
                            </div>

                            <!-- Payment Info -->
                            <div class="invoice-info" style="font-size: small;">
                                <h6>Payment Info:</h6>
                                <div style="display: flex; gap: 20px;">
                                    <h7>Total Due:</h7>
                                    <h6 style="color: brown;">{{ quotationData.subtotal }}<span class="ml-1"> {{ quotationData.currency }}</span></h6>
                                </div>
                                <div style="display: flex; gap: 20px;">
                                    <h7>Billing Address:</h7>
                                    <p>
                                        {{ quotationData.billing_address }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="client-section">
                            <div style="display: flex; align-items: flex-start;">
                                <h6 v-if="quotationData.project.title">Project: {{ quotationData.project.title }}</h6>
                                <h6 v-else>Project: N/A </h6>
                            </div>
                            <div></div>
                        </div>

                        <!-- <DataTable :value="JSON.parse(quotationData.details)" responsiveLayout="scroll"></DataTable> -->
                        <!-- Item table -->
                        <div class="table-container" v-if="quotationData.details!=null">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty :items="parsedDetails" :fields="fields">
                                    <!-- <template #cell(id)="row">
                                        {{ row.index+1 }}
                                    </template> -->

                                    <template #cell(name)="row">
                                        <h6 style="min-width: 150px;" v-if="row.item.checkLabel">{{ row.item.name }}</h6>
                                        <p style="min-width: 150px;" v-else>{{ row.item.name }}</p>
                                    </template>

                                    <template #cell(amount)="row">
                                        <p v-if="row.item.amount == 0"></p>
                                        <p v-else>{{ row.item.amount }}</p>
                                    </template>

                                    <template #cell(description)="row">
                                        <p style="width: 300px; word-break: break-all;">{{ row.item.description }}</p>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div class="total-container" style="font-size: small;">
                            <div class="total-item" style="padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;">
                                <h6 style="font-weight: 500;">Subtotal:</h6>
                                <h6 style="text-align: right;">
                                    <span style="font-weight: 500;">
                                        {{ quotationData.total }}
                                    </span>
                                    {{ quotationData.currency }}
                                </h6>
                            </div>
                            <div class="total-item" style="margin-top: 5px; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;">
                                <h6 style="font-weight: 500;">Vat (%):</h6>
                                <h6 style="text-align: right;">
                                    {{ quotationData.vat ? quotationData.vat : 0 }}
                                </h6>
                            </div>
                            <div class="total-item" style="margin-top: 5px; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;">
                                <h6 style="font-weight: 500;">Discount (%):</h6>
                                <h6 style="text-align: right;">
                                    {{ quotationData.discount ? quotationData.discount : 0 }}
                                </h6>
                            </div>
                            <div class="total-item" style="margin-top: 5px; padding: 4px; border-style: solid; border-top: 0px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #e5e7eb;">
                                <h6 style="font-weight: 500;">Total:</h6>
                                <h6 style="text-align: right; color: brown;">
                                    <span style="font-weight: 500;">
                                        {{ quotationData.subtotal }}
                                    </span>
                                    {{ quotationData.currency }}
                                </h6>
                            </div>
                        </div>

                        <div class="other-info-container" v-if="quotationData.remarks">
                            <h6 style="padding-left: 10px; padding-right: 10px;">Remarks:</h6>
                            <p style="padding: 8px; font-size: small;">
                                {{ quotationData.remarks }}
                            </p>
                        </div>

                        <div style="display: flex; justify-content: flex-start; margin-top: 10px">
                            <div style="display: flex; flex-direction: column; align-items: flex-start;">
                                <img style="height: auto; width: auto; max-height: 100px; max-width: 100px;" :src="host+setting.authorized_signature" alt="" v-if="showSignature == true" />
                                <b-badge pill variant="primary" v-if="showSignature == false" @click="DisplaySignature()" class="signature-btn" style="cursor: pointer;">Authorized Signature</b-badge>
                                <p style="border-style: solid; border-bottom: 40px; border-left: 0; border-right: 0; border-width: 1px; border-color: #d1d5db; padding: 4px 8px 12px; margin-top: 12px; font-size: small; align-self: flex-start;">
                                    Authorized signature
                                </p>
                                <b-badge pill variant="primary" v-if="showSignature == true" @click="RemoveSignature()" class="signature-btn" style="cursor: pointer;">Remove Signature</b-badge>
                            </div>
                        </div>

                        <!-- <div style="display: flex; justify-content: flex-start;">
                            <img style="height: 100px;" :src="host+setting.authorized_signature" alt="" />
                        </div>
                        <p style="border-style: solid; border-bottom: 40px; border-left: 0px; border-right: 0px; border-width: 1px; border-color: #d1d5db; padding-left: 8px; padding-right: 8px; padding-top: 4px; padding-bottom: 12px; margin-top: 96px; font-size: small; align-self: flex-start;">
                            Authorized signature
                        </p> -->

                        <!-- <div style="width: 100%; display: flex; justify-content: space-between; margin-top: 32px; background-color: #e5e7eb; font-size: small; padding-left: 24px; padding-right: 24px;">
                            <p style="padding-top: 18px;">{{ setting.address }}</p>
                            <p style="padding-top: 18px;">
                                © 2024 SMICEE | Developed by
                                <a target="_blank" class="text-blue-500" href="https://bitflex.xyz/">BitFlex</a>
                            </p>
                        </div> -->

                    </div>

                </div>
            </div>
            <div class="col-xl-4">
                <div class="content-container-attahment w-100">
                    <div class="">
                        <div class="d-flex justify-content-between ">
                            <h6 class="my-2">Supporting Documents</h6>

                            <feather type="plus-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-modal.modal-scrollable-add-document v-b-tooltip.hover title="Add Documents"> </feather>

                        </div>

                        <b-table show-empty sticky-header :items="quotationData.quotation_attach" :fields="Attachment" head-variant="light">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>

                            <template #cell(title)="data">
                                <a :href="host+data.item.quotation_attachment" target="_blank"> {{ truncateText(data.item.attach_title, 100) }}</a>
                            </template>
                            <template #cell(action)="data">

                                <div style="display: flex; flex-direction: row; gap:10px">

                                    <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                </div>
                            </template>
                        </b-table>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Project Attachment Create -->
    <b-modal id="modal-scrollable-add-document" ref="modal" title="Add document" @ok="handleAttachOk">
        <form ref="form" @submit.stop.prevent="handleAttahmentSubmit">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload Documents</label>
                        <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
// import logo from "src/assets/images/logo/login.png";
import {
    myHost
} from "../../../_helpers/host";
export default {
    props: ["id", "download"],

    data() {
        return {
            company: {
                name: 'SMICEE',
                logo: 'https://picsum.photos/200/300',
                address: 'H #74, R #21, Block #B, Banani, Dhaka',
                email: 'info@smicee.com',
                website: 'www.portal.smicee.com',
                phone_number: '01758963258'
            },

            showSignature: false,

            host: myHost.host,

            add_attachment: {
                quotation_attachment: null,
                quotation_id: '',
            },

            fields: [
                // {
                //     key: 'id',
                //     label: '#'
                // },
                {
                    key: 'name',
                    label: 'Item',
                    sortable: false
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: false
                },

                {
                    key: 'item_quantity',
                    label: 'Item Quantity',
                    sortable: false
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    sortable: false
                }
            ],
            parsedDetails: [],
            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                },

            ],

            loading: true,
            downloadPdf: false,
            showSignature: false,
        };
    },

    methods: {
        DisplaySignature() {
            this.showSignature = true;
        },
        RemoveSignature() {
            this.showSignature = false;
        },
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },
        parseDetails() {
            try {
                this.parsedDetails = JSON.parse(this.quotationData.details);
            } catch (error) {
                // console.error('Error parsing details:', error);
            }
        },

        printPdf(section) {
            this.downloadPdf = true;

            var printContents = document.getElementById(section).innerHTML;
            var originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;

            window.print();

            document.body.innerHTML = originalContents;
            window.close();
        },

        parseDate(date) {
            return new Date(Date.parse(date)).toLocaleDateString("en-US", {
                year: "numeric",
                day: "numeric",
                month: "short",
            });
        },

        editQuotation(id) {
            this.$router.push({
                path: `/finance-and-accounts/finance/quotation/edit/${id}`
            });
        },
        handleAttachOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleAttahmentSubmit()
        },

        handleAttahmentSubmit() {
            this.add_attachment.quotation_id = this.$route.params.id
            this.$store.dispatch("quotation/addQuotationAttachment", {
                attachment: this.add_attachment,
                quotation_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.add_attachment.invoice_attachment = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable-add-document')
            })
        },
        deleteAttach(id) {
            this.$store.dispatch("quotation/deleteAttachmentFromQuotation", {
                id: id,
                quotation_id: this.$route.params.id,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        uploadAttachFile(e) {
            this.add_attachment.quotation_attachment = e.target.files[0]

        },
    },

    computed: {
        ...mapState({
            quotationData: (state) => state.quotation.quotation_detail,
            setting: state => state.organization.organization_setting,
        }),
        quotationBackgroundStyle() {
            return {
                'background-image': `url(${this.host}${this.setting.letterpad})`,
                'background-size': 'auto 100%',
                'background-position': 'center center',
                'background-repeat': 'no-repeat',

            };
        }
    },

    mounted() {

        this.$store.dispatch("quotation/fetchQuotationDetail", this.$route.params.id);
        this.item_details = (JSON.stringify(this.quotationData.details))
        this.parseDetails();
        this.$store.dispatch("organization/fetchOrganizationSettings")
    },

    watch: {
        'quotationData.details': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.parseDetails();
            }
        }
    }
};
</script>

<style scoped>
.content-container {
    background-color: white;
    max-width: 1280px;
    padding: 140px 60px;
    display: flex;
    flex-direction: column;
    /* justify-items: center;
    align-items: center; */
    margin-bottom: 32px;
}

.content-container-attahment {
    background-color: white;
    padding: 10px;
}

.invoice-header,
.client-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 48px;
}

.company-info,
.client-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: start;
}

.payment-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    align-items: flex-end;
}

.total-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.invoice-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: end;
}

.logo-img {
    object-fit: cover;
    object-position: center;
}

.table-container {
    width: 100%;
    font-size: small;
    color: black;
}

.total-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    margin-top: 20px;
    margin-bottom: 32px;
}

.other-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* @media print {
        html,
        body {
            width: 210mm;
            height: 297mm;
        }

        .content-container {
            margin: 0;
            width: initial;
            min-height: initial;
        }
    } */

.invoice-container {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 32px;
}

@media print {
    .invoice-container {
        display: none;
        visibility: hidden;
    }

    .signature-btn {
        display: none;
        visibility: hidden;
    }
}

@media screen and (max-width: 639px) {
    .invoice-header {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }

    .invoice-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: start;
    }

    .payment-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;
    }

    .client-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 20px;
        margin-bottom: 20px;
    }

    .total-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-self: flex-end;
        margin-top: 20px;
        margin-bottom: 32px;
    }
}
</style>
