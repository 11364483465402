<template>
<div>
    <Breadcrumbs main="HR" title="Holidays" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Holiday</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">
                            <b-table show-empty :items="holiday_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template #cell(name)="data">
                                    <div style="min-width: 120px;">{{ data.item.name }}</div>
                                </template>

                                <template #cell(start_date)="data">
                                    <div style="min-width: 100px;">{{ data.item.start_date }}</div>
                                </template>

                                <template #cell(end_date)="data">
                                    <div style="min-width: 100px;">{{ data.item.end_date }}</div>
                                </template>

                                <template #cell(notice)="data">
                                    <div style="width: 400px; word-break: break-all;">{{ data.item.notice.substring(0, 120) }}</div>
                                </template>

                                <template #cell(action)="data">
                                    <span style="display: flex; gap: 12px;">
                                        <div>
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteHoliday(data.item.id)" title="Delete"></feather>
                                        </div>

                                        <div @click="editHoliday(data.item)">
                                            <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                        </div>
                                    </span>
                                </template>
                            </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Holiday Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add Holiday" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit()">
            <b-form-group label="Title" label-for="name-input">
                <b-form-input v-model="holiday.name" placeholder="Enter holiday title"></b-form-input>
            </b-form-group>

            <b-form-group label="Start Date" label-for="name-input">
                <datepicker placeholder="Select start date" input-class="datepicker-here form-control digits" v-model="holiday.start_date"></datepicker>
            </b-form-group>

            <b-form-group label="End Date" label-for="name-input">
                <datepicker placeholder="Select end date" input-class="datepicker-here form-control digits" v-model="holiday.end_date"></datepicker>
            </b-form-group>

            <b-form-group label="Notice" label-for="textarea-rows">
                <b-form-textarea id="textarea-rows" placeholder="Enter notice..." rows="5" v-model="holiday.notice"></b-form-textarea>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Holiday Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Holiday" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Title" label-for="name-input">
                <b-form-input v-model="edit_holiday.name" placeholder="Enter holiday title"></b-form-input>
            </b-form-group>

            <b-form-group label="Start Date" label-for="name-input">
                <datepicker placeholder="Select start date" input-class="datepicker-here form-control digits" v-model="edit_holiday.start_date"></datepicker>
            </b-form-group>

            <b-form-group label="End Date" label-for="name-input">
                <datepicker placeholder="Select end date" input-class="datepicker-here form-control digits" v-model="edit_holiday.end_date"></datepicker>
            </b-form-group>

            <b-form-group label="Notice" label-for="textarea-rows">
                <b-form-textarea id="textarea-rows" placeholder="Enter notice..." rows="5" v-model="edit_holiday.notice"></b-form-textarea>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Holiday Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this holiday?</div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import { mapState } from 'vuex';

export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: 'Start Date',
                    sortable: true
                },
                {
                    key: 'end_date',
                    label: 'End Date',
                    sortable: true
                },
                {
                    key: 'notice',
                    label: 'Notice',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            holiday: {
                name: '',
                start_date: '',
                end_date: '',
                notice: '',
            },

            edit_holiday: {
                id: '',
                name: '',
                start_date: '',
                end_date: '',
                notice: '',
            },

            delete_value:null,

            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState({
            holiday_list: state => state.holiday.holiday,
        }),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
            .filter(f => f.sortable)
            .map(f => {
                return {
                    text: f.label,
                    value: f.key
                };
            });
        }
    },

    mounted() {
        this.$store.dispatch("holiday/fetchHoliday")
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.holiday.title = ''
            this.holiday.start_date = null
            this.holiday.end_date = null
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()

        },

        handleSubmit() {
            if (this.holiday.start_date !== null) {
                this.holiday.start_date = moment(String(this.holiday.start_date)).format("YYYY-MM-DD");
            }
            if (this.holiday.end_date !== null) {
                this.holiday.end_date = moment(String(this.holiday.end_date)).format("YYYY-MM-DD");
            }
            this.$store.dispatch("holiday/addHoliday", this.holiday).then(response => {
                // console.log(this.holiday)
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.holiday.name = '',
                    this.holiday.start_date = '',
                    this.holiday.end_date = '',
                    this.holiday.notice = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editHoliday(type) {
            this.edit_holiday.id = type.id
            this.edit_holiday.name = type.name
            this.edit_holiday.start_date = type.start_date
            this.edit_holiday.end_date = type.end_date
            this.edit_holiday.notice = type.notice
        },

        resetEditModal() {
            this.edit_holiday.title = ''
            this.edit_holiday.start_date = null
            this.edit_holiday.end_date = null
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {
            if (this.edit_holiday.start_date !== null) {
                this.edit_holiday.start_date = moment(String(this.edit_holiday.start_date)).format("YYYY-MM-DD");
            }
            if (this.edit_holiday.end_date !== null) {
                this.edit_holiday.end_date = moment(String(this.edit_holiday.end_date)).format("YYYY-MM-DD");
            }
            this.$store.dispatch("holiday/updateHoliday", {
                id: this.edit_holiday.id,
                name: this.edit_holiday.name,
                start_date: this.edit_holiday.start_date,
                end_date: this.edit_holiday.end_date,
                notice: this.edit_holiday.notice
            }).then(response => {
                console.log(this.edit_holiday)
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_holiday.name = '',
                    this.edit_holiday.start_date = '',
                    this.edit_holiday.end_date = '',
                    this.edit_holiday.notice = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        deleteHoliday(id) {
            this.delete_value = id
        },
        
        handleDeleteOk(){
            this.$store.dispatch("holiday/deleteHoliday", this.delete_value).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
