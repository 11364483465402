<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Quotations"/>
        
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header" style="max-width: fit-content">
                            <router-link to="/finance-and-accounts/finance/quotation/create/new">
                            <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
                                    <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                        <i class="fa fa-light fa-plus"></i> 
                                        <h7>Add New Quotation</h7>
                                    </div>                   
                            </button>
                        </router-link>
                        </div>
            
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(quotation_id)="data">
                                        <span>
                                            <router-link :to="`/finance-and-accounts/finance/quotation/${data.item.id}`" class="link-style">
                                                {{ data.item.quotation_id }}
                                            </router-link>
                                        </span>
                                    </template>

                                    <template #cell(subtotal)="data">
                                        <span>
                                            {{ data.item.subtotal }} {{ data.item.currency }}
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteQuotation(data.item.id)" title="Delete"></feather>
                                            <router-link :to="`/finance-and-accounts/finance/quotation/edit/${data.item.id}`">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                            </router-link>
                                            <feather type="plus-square" size="21px" style="color: #51bb25; cursor: pointer;" v-b-tooltip.hover title="Generate Invoice" @click="generateInvoice(data.item.id)"></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Delete Modal -->
        <b-modal 
            id="modal-center" 
            centered 
            hide-header
            @ok="handleDeleteOk"
        >
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this quotation?</div>
        </b-modal>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'quotation_id', label: 'Quotation ID', sortable: true },
                { key: 'email', label: 'Email', sortable: true },
                { key: 'quotation_date', label: 'Quotation Date', sortable: true },
                { key: 'due_date', label: 'Due Date', sortable: true },
                { key: 'subtotal', label: 'Grand Total', sortable: true },
                { key: 'action', label: 'Action', sortable: false }
            ],
    
            filter: null,
            delete_value: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            items: state => state.quotation.quotation
        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },

    mounted() {
        this.$store.dispatch("quotation/fetchQuotation")
        this.totalRows = this.items.length;
    },

    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        generateInvoice(id) {
            this.$router.push('/finance-and-accounts/finance/invoice/create/' + id)
        },

        deleteQuotation(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("quotation/deleteQuotation", this.delete_value).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
  