<template>
<div>
    <Breadcrumbs main="Vendor" title="Projects" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">

                        <b-row>
                            <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">
                            <b-table show-empty stacked="md" :items="project_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ (currentPage - 1) * perPage + data.index + 1 }}
                                </template>

                                <template #cell(title)="data">
                                    <div>
                                        <router-link :to="`/vendor/projects/${data.item.id}`"> <span>{{data.item.title}}</span></router-link>

                                        <small><span style="font-size: medium;">
                                                <b-badge pill variant="primary" v-if="data.item.category !== null">

                                                    {{data.item.category}}

                                                </b-badge>
                                            </span></small>
                                    </div>
                                </template>

                                <template #cell(status)="row">
                                    <span v-if="row.value == 'APPROVED'">
                                        <b-badge pill variant="primary">{{ row.value }}</b-badge>
                                    </span>
                                    <span v-if="row.value == 'PENDING'">
                                        <b-badge pill variant="danger">{{ row.value }}</b-badge>
                                    </span>
                                    <span v-if="row.value == 'WORKING'">
                                        <b-badge pill variant="info">{{ row.value }}</b-badge>
                                    </span>
                                    <span v-if="row.value == 'COMPLETED'">
                                        <b-badge pill variant="success">{{ row.value }}</b-badge>
                                    </span>
                                </template>

                            </b-table>

                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
import Multiselect from 'vue-multiselect';
import {
    myHost
} from '../../../../_helpers/host';

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            vendor_id: 12,
            tablefields: [{
                    key: 'index',
                    label: '#',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: 'Start Date',
                    sortable: true
                },
                {
                    key: 'end_date',
                    label: 'End Date',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',

                },

            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]

        };
    },

    watch: {

    },

    computed: {
        ...mapState({
            project_list: state => state.vendor.vendor_projects,

        }),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {
        this.$store.dispatch("vendor/fetchProjectHasVendor", this.vendor_id).then(() => {
            this.items = this.project_list
            this.totalRows = this.items.length;
        });

    },

    methods: {
        convertUrls(text) {
            const urlPattern = /https?:\/\/[^\s]+/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    }
};
</script>

<style scoped>

    </style>
