<template>
    <div>
        <Breadcrumbs main="HR" submain="Payroll" title="Payroll Items"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">

                        <div class="card-header">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i> 
                                <h7>Add New Payroll Item</h7>
                            </button>
                        </div>
            
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(status)="data">
                                        <span> 
                                            <small v-if="data.item.status == 'INCLUSIVE'"><span style="font-size: medium;"><b-badge pill variant="success">{{ data.item.status }}</b-badge></span></small>
                                            <small v-if="data.item.status == 'DEDUCTIVE'"><span style="font-size: medium;"><b-badge pill variant="danger">{{ data.item.status }}</b-badge></span></small>
                                        </span>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover name="Delete" v-b-modal.modal-center @click="deleteItems(data.item.id)"></feather> 
                                            <div @click="editItem(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover name="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Payroll Item Create Modal -->
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Add Payroll Item"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    label="Name"
                    label-for="name-input"
                >
                    <b-form-input v-model="payroll_type.name" placeholder="Enter payroll name"></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Type"
                    label-for="name-input"
                >
                    <b-form-select v-model="payroll_type.status" :options="types"></b-form-select>
                </b-form-group>
            </form>
        </b-modal>

        <!-- Payroll Item Edit Modal -->
        <b-modal
            id="modal-prevent-closing-edit"
            ref="modal"
            title="Edit Payroll Item"
            @show="resetEditModal"
            @hidden="resetEditModal"
            @ok="handleEditOk"
        >
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <b-form-group
                    label="Name"
                    label-for="name-input"
                >
                    <b-form-input v-model="edit_payroll_type.name" placeholder="Enter payroll name"></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Type"
                    label-for="name-input"
                >
                    <b-form-select v-model="edit_payroll_type.status" :options="types"></b-form-select>
                </b-form-group>
            </form>
        </b-modal>

        <!-- Payroll Item delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>
        </b-modal>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; 

export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'name', label: 'Title', sortable: true },
                { key: 'status', label: 'Type', sortable: true },
                { key: 'action', label: 'Action', sortable: false }
            ],

            types: ['INCLUSIVE', 'DEDUCTIVE'],
        
            payroll_type: {
                name: '',
                status: ''
            },

            edit_payroll_type: {
                id: null,
                name: '',
                status: ''
            },

            delete_value: null,

            filter: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    }, 

    computed: {
        ...mapState({
            items: state => state.payroll.payroll_items
        }),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },

    mounted() {
        this.$store.dispatch("payroll/fetchPayrollItems").then(() => {
            this.totalRows = this.items.length;
        });
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.payroll_type.name = ''
            this.payroll_type.status = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        
        handleSubmit() {
            this.$store.dispatch("payroll/addPayrollItems", this.payroll_type).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.payroll_type.name = '',
                    this.payroll_type.status = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editItem(type) {
            this.edit_payroll_type.id = type.id
            this.edit_payroll_type.name = type.name
            this.edit_payroll_type.status = type.status
        },

        resetEditModal() {
            this.edit_payroll_type.id = null
            this.edit_payroll_type.name = ''
            this.edit_payroll_type.status = ''
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },
        
        handleEditSubmit() {
            this.$store.dispatch("payroll/updatePayrollItems", this.edit_payroll_type).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_payroll_type.id = null
                    this.edit_payroll_type.name = '',
                    this.edit_payroll_type.status = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        deleteItems(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("payroll/deletePayrollItem", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
  