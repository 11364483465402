<template>
    <div class="table-container">

        <div class="table-responsive datatable-vue">
            <b-table
                show-empty
                stacked="md"
                @change="emitChange"
                :items="state.tableData"
                :fields="state.fields"
            >
                <!-- <template #cell(id)="row">
                    {{ row.index+1 }}
                </template> -->

                <template #cell(name)="row"> 
                    <div v-if="row.item.checkLabel">
                        <input class="form-control" type="text" placeholder="Enter label name" v-model="row.item.name">
                    </div>
                    <div v-else>
                        <input class="form-control" type="text" placeholder="Enter item" list="quotation_items" @change="getItems(row.item.name, row.index)" v-model="row.item.name" />
                        <datalist id="quotation_items">
                            <option v-for="(q_item, index) in state.quotation_items" :key="index">
                                <span>{{ q_item.name }}</span>
                            </option>
                        </datalist>
                    </div>
                </template>

                <template #cell(description)="row">
                    <div v-if="row.item.checkLabel">
                    </div>
                    <div v-else>
                        <input class="form-control" type="text" placeholder="Enter description" v-model="row.item.description">
                    </div>
                </template>

                <template #cell(rate)="row">
                    <div v-if="row.item.checkLabel">
                    </div>
                    <div v-else>
                        <input @change="calculateAmount(row.item.id)" class="form-control" type="number" placeholder="Enter unit cost" v-model="row.item.rate">
                    </div>
                </template>

                <template #cell(item_quantity)="row">
                    <div v-if="row.item.checkLabel">
                    </div>
                    <div v-else>
                        <input @change="calculateAmount(row.item.id)" class="form-control" type="number" placeholder="Enter item quantity" v-model="row.item.item_quantity">
                    </div>
                </template>

                <template #cell(amount)="row">
                    <div v-if="row.item.checkLabel">
                    </div>
                    <div v-else>
                        <input class="form-control" type="number" placeholder="0" disabled v-model="row.item.amount">
                    </div>
                </template>

                <template #cell(action)="row">
                    <button @click="removeItem(row.item.id)" class="btn btn-primary" type="submit">Delete</button>
                </template>
            </b-table>
        </div>

        <div style="display: flex; gap: 12px;">
            <b-button variant="primary" @click="addItem">Add Item</b-button>
            <b-button variant="primary" @click="addLabel">Add Label</b-button>
        </div>
    </div>
</template>
  
<script>
import { computed, defineComponent, reactive } from "vue";

export default defineComponent ({
    name: "item-table",

    props: ["tableData"],

    setup(props, { emit }) {
        const state = reactive({
            currentId: computed(() => state.tableData.length),
            tableData: props.tableData || [
                {
                    id: 1,
                    name: null,
                    description: null,
                    rate: null,
                    item_quantity: null,
                    amount: null,
                    checkLabel: false
                },
            ],
            fields: [
                // { key: 'id', label: '#' },
                { key: 'name', label: 'Item', sortable: false },
                { key: 'description', label: 'Description', sortable: false },
                { key: 'rate', label: 'Unit Cost', sortable: false },
                { key: 'item_quantity', label: 'Item Quantity', sortable: false },
                { key: 'amount', label: 'Amount', sortable: false },
                { key: 'action', label: 'Action', sortable: false }
            ],
            quotation_items: []
        });

        // Calculate Amount
        const calculateAmount = (id) => {
            state.tableData.map((item) => {
                if (item.id === id) {
                    item.amount = item.rate * item.item_quantity;
                    return item;
                }
                return item;
            });

            emitChange();
        };
    
        // Add item
        const addItem = () => {
            state.tableData.push({
                id: state.currentId + 1,
                name: null,
                description: null,
                rate: null,
                item_quantity: null,
                amount: null,
                checkLabel: false
            });
        };

        // Add Label
        const addLabel = () => {
            state.tableData.push({
                id: state.currentId + 1,
                name: null,
                description: null,
                rate: null,
                item_quantity: null,
                amount: null,
                checkLabel: true
            });
        };

        // Get Item
        const getItems = (name, id) => {
            // console.log(name)
            const temp = {}
            state.quotation_items.filter((item) => {
                if(item.name == name) {
                    state.tableData[id].name = item.name
                    state.tableData[id].description = item.description
                    state.tableData[id].rate = item.rate
                    state.tableData[id].item_quantity = 1
                    state.tableData[id].amount = item.rate
                }
            });

            // console.log(state.tableData)
            calculateId();
            emitChange();
        };

        // Remove Item
        const removeItem = (id) => {
            const temp = state.tableData.filter((item) => item.id !== id);
            state.tableData = temp;
            calculateId();
            emitChange();
        };
    
        // Calculate ID
        const calculateId = () => {
            let id = 0;
            state.tableData.map((item) => {
                id++;
                item.id = id;
                return item;
            });
        };

        // Emit change
        const emitChange = () => {
            // console.log(state.tableData)
            emit("itemsUpdated", state.tableData);
        };
    
        return {
            state,
            addItem,
            addLabel,
            getItems,
            removeItem,
            calculateAmount,
            emitChange,
        };
    }, 

    mounted() {
        this.$store.dispatch("item/fetchItem").then(result=>{
            this.state.quotation_items = result.data
        })
    }
})
</script>
  
<style scoped>
.table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
</style>
  