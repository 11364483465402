<template>
<div>
    <Breadcrumbs main="HR" title="Employee" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header" style="max-width: fit-content">
                        <router-link to="/hr/organization/employee/create">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i>
                                <h7>Add New Employee</h7>
                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">
                            <b-table show-empty stacked="md" :items="employee" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ (currentPage - 1) * perPage + data.index + 1 }}
                                </template>

                                <template #cell(username)="data">
                                    <span style="display: flex; flex-direction: column; gap: 8px;">
                                        <router-link :to="`/hr/organization/employee/details/${data.item.id}`" class="link-style">{{data.item.user_name}}</router-link>
                                        <small><span style="font-size: medium;">
                                                <b-badge pill variant="dark">{{data.item.username}}</b-badge>
                                            </span></small>

                                    </span>
                                </template>

                                <template #cell(action)="data">
                                    <span style="display: flex; gap: 12px;">

                                        <div>
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteDesignation(data.item.id)" title="Delete"></feather>
                                        </div>

                                        <div @click="editEmployee(data.item)">
                                            <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                        </div>
                                    </span>
                                </template>
                            </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this Employee?</div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'username',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'additional_info.department.name',
                    label: 'Department',
                    sortable: true
                },
                {
                    key: 'additional_info.designation.title',
                    label: 'Designation',
                    sortable: true
                },
                {
                    key: 'additional_info.joining_date',
                    label: 'Joining Date',
                    sortable: true
                },
                {
                    key: 'additional_info.address',
                    label: 'Address',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            designation: {
                title: '',
                department: '',

            },

            edit_designation: {
                id: '',
                title: '',
                department: '',

            },

            delete_value: null,

            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState({
            employee: state => state.employee.employee_list,
            department_list: state => state.department.departments,
            designation_list: state => state.designation.designation,

        }),
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },

    },

    mounted() {
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("designation/fetchDesignations")

        this.$store.dispatch("employee/fetchEmployeeList").then(() => {
            this.items = this.employee
            this.totalRows = this.items.length;
        });
    },

    methods: {
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.designation.title = ''
            this.designation.department = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()

        },

        handleSubmit() {

            this.$store.dispatch("designation/addDesignation", this.designation).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.designation.title = ''
                    this.designation.department = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editEmployee(type) {

            this.$router.push(`/hr/organization/employee/edit/${type.id}`)

        },

        deleteDesignation(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("employee/deleteEmployee", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
