<template>
<div>
    <Breadcrumbs main="HR" submain="My Workspace" title="My Updates" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row flex-md-row-reverse ">
            <!-- <div class="col-md-12">
                <div class="card">

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">

                            <b-table show-empty stacked="md" :items="updates_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template #cell(activity)="data">

                                    <span style="cursor: pointer;">
                                        <div @click="readMsg(data.item)">

                                            {{data.item.activity}}

                                        </div>
                                    </span>

                                </template>

                                <template #cell(ref_type)="data">
                                    <span v-if="data.item.ref_type == 'LEAVE'">
                                        <b-badge pill variant="danger">{{ data.item.ref_type}}</b-badge>
                                    </span>
                                    <span v-if="data.item.ref_type == 'TICKET'">
                                        <b-badge pill variant="info">{{ data.item.ref_type}}</b-badge>
                                    </span>
                                    <span v-if="data.item.ref_type == 'BUDGET'">
                                        <b-badge pill variant="warning">{{ data.item.ref_type}}</b-badge>
                                    </span>
                                    <span v-if="data.item.ref_type == 'GRIEVANCE'">
                                        <b-badge pill variant="primary">{{ data.item.ref_type}}</b-badge>
                                    </span>

                                </template>

                            </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div> -->
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); margin-top: 8px; gap: 8px;">
                            <div>
                                <span style="font-size: medium; width: 100%;">
                                    <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="danger" @click="filterByRefType('LEAVE')">
                                        <span>LEAVE</span>
                                    </b-badge>
                                </span>
                            </div>
                            <div>
                                <span style="font-size: medium; width: 100%;">
                                    <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="info" @click="filterByRefType('TICKET')">
                                        <span>TICKET</span>
                                    </b-badge>
                                </span>
                            </div>
                            <div>
                                <span style="font-size: medium; width: 100%;">
                                    <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="warning" @click="filterByRefType('BUDGET')">
                                        <span>BUDGET</span>
                                    </b-badge>
                                </span>
                            </div>
                            <div>
                                <span style="font-size: medium; width: 100%;">
                                    <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="primary" @click="filterByRefType('GRIEVANCE')">
                                        <span>GRIEVANCE</span>
                                    </b-badge>
                                </span>
                            </div>
                            <div>
                                <span style="font-size: medium; width: 100%;">
                                    <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="primary" @click="filterByRefType('EOD')">
                                        <span>EOD</span>
                                    </b-badge>
                                </span>
                            </div>
                            <div>
                                <span style="font-size: medium; width: 100%;">
                                    <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="secondary" @click="filterByRefType('REQUISITION')">
                                        <span>REQUISITION</span>
                                    </b-badge>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 my-2">
                <div class="card" v-for="myUpdate in visibleUpdates" :key="myUpdate.id" :class="{ 'read': !myUpdate.read }">
                    <div class="card-body" @click="readMsg(myUpdate)" style="cursor: pointer;">
                        <div style="display: flex; flex-direction: row; justify-content: space-between">
                            <div >
                                <h7>{{ myUpdate.activity }}</h7>
                            </div>
                            <div>
                                <span v-if="myUpdate.ref_type == 'LEAVE'">
                                    <b-badge pill variant="danger">{{ myUpdate.ref_type}}</b-badge>
                                </span>
                                <span v-if="myUpdate.ref_type == 'TICKET'">
                                    <b-badge pill variant="info">{{ myUpdate.ref_type}}</b-badge>
                                </span>
                                <span v-if="myUpdate.ref_type == 'BUDGET'">
                                    <b-badge pill variant="warning">{{ myUpdate.ref_type}}</b-badge>
                                </span>
                                <span v-if="myUpdate.ref_type == 'GRIEVANCE'">
                                    <b-badge pill variant="primary">{{ myUpdate.ref_type}}</b-badge>
                                </span>
                                <span v-if="myUpdate.ref_type == 'EOD'">
                                    <b-badge pill variant="dark">{{ myUpdate.ref_type}}</b-badge>
                                </span>
                                <span v-if="myUpdate.ref_type == 'REQUISITION'">
                                    <b-badge pill variant="secondary">{{ myUpdate.ref_type}}</b-badge>
                                </span>


                                
                            </div>
                        </div>
                        <div>
                            <!-- <h7>{{ updateTime(myUpdate.timestamp) }}</h7> -->
                               <h7>{{ myUpdate.timestamp }}</h7>

                        </div>

                    </div>
                </div>
                <div class="d-flex flex-column">
                    <div class="d-flex justify-content-center" v-if="showLoadMoreButton()" style="cursor: pointer;">
                        <button class="btn btn-primary " @click="loadMore()">Load More</button>
                    </div>
                </div>
            </div>
            

        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },

    data() {
        return {
            updatedTime: null,
            visibleMyUpdates: 5,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'activity',
                    label: 'Activity',
                    sortable: true
                },
                {
                    key: 'timestamp',
                    label: 'Time Stamp',
                    sortable: true
                },
                {
                    key: 'ref_type',
                    label: 'Type',
                    sortable: true
                },

            ],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        sortOptions() {

            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        ...mapState({
            updates_list: state => state.myUpdates.my_updates,
            eod_reply: state => state.eod.eod_reply,

        }),
        visibleUpdates() {
            if (!this.filter) {
                return this.updates_list.slice(0, this.visibleMyUpdates);
            } else {
                return this.updates_list.filter(update => update.ref_type === this.filter).slice(0, this.visibleMyUpdates);
            }
        },

    },

    mounted() {
        this.$store.dispatch("myUpdates/fetchMyUpdates").then(() => {
            this.items = this.updates_list
            this.totalRows = this.items.length;
        });

    },

    methods: {
        // updateTime(myTime) {
        //     const timeParts = myTime.match(/(\d+):(\d+):(\d+) (AM|PM)/);
        //     const dateParts = myTime.match(/(\w+) (\d+)(?:st|nd|rd|th) (\d+)/);

        //     const hours = parseInt(timeParts[1]);
        //     const minutes = parseInt(timeParts[2]);
        //     const seconds = parseInt(timeParts[3]);
        //     const period = timeParts[4];
        //     const month = this.getMonthIndex(dateParts[1]);
        //     const day = parseInt(dateParts[2]);
        //     const year = parseInt(dateParts[3]);

        //     const existingDate = new Date(year, month, day, hours + (period === "PM" ? 12 : 0), minutes, seconds);

        //     // Add 6 hours to existing time
        //     existingDate.setHours(existingDate.getHours() + 6);

        //     // Format updated time
        //     const options = {
        //         month: "long",
        //         day: "numeric",
        //         year: "numeric",
        //         hour: "2-digit",
        //         minute: "2-digit",
        //         second: "2-digit",
        //         hour12: true
        //     };

        //     this.updatedTime = existingDate.toLocaleString("en-US", options);

        //     // console.log(this.updatedTime)

        //     return this.updatedTime
        // },

        // getMonthIndex(monthName) {
        //     const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        //     return months.indexOf(monthName);
        // },

        filterByRefType(refType) {
            this.visibleMyUpdates = 5;
            this.filter = refType;
        },

        loadMore() {
            this.visibleMyUpdates += 5;
        },

        showLoadMoreButton() {
            return this.visibleUpdates.length < this.updates_list.length;
        },

        readMsg(data) {
            if (data.ref_type == 'LEAVE') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/my-workspace/leave-details/${data.type_id}`);
                    }  
                    
                    else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }
            if (data.ref_type == 'TICKET') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/my-workspace/ticket-details/${data.type_id}`);
                    }
                   
                    
                    else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }
            if (data.ref_type == 'BUDGET') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/my-workspace/budget-details/${data.type_id}`);
                    }
                   
                    else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }
            if (data.ref_type == 'GRIEVANCE') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/my-workspace/grievance-details/${data.type_id}`);
                    } 
                  
                    
                    else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }
            if (data.ref_type == 'EOD') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/eod/details/${data.type_id}`);
                    }
                    
                   
                    else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }
            if (data.ref_type == 'REQUISITION') {
                this.$store.dispatch("myUpdates/readMyUpdates", data.id).then(response => {
                    if (response.code == 200) {
                        this.$router.push(`/my-workspace/requisition-details/${data.type_id}`);
                    }
                    
                   
                    else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })

            }


            
        },
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    }
};
</script>
<style scoped>
.read {
  
    background-color:#fff0f0c7; 
}
</style>