<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Items"/>
        
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i> 
                                <h7>Add New Item</h7>
                            </button>
                        </div>
            
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <!-- <template #cell(status)="row">
                                        <span v-if="row.value == 'Active'">
                                            <b-badge pill variant="success">{{ row.value }}</b-badge>
                                        </span>
                                        <span v-if="row.value == 'Inactive'">
                                            <b-badge pill variant="danger">{{ row.value }}</b-badge>
                                        </span>
                                    </template> -->

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(data.item.id)" title="Delete"></feather>
                                            <feather @click="editItem(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Create Item -->
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Create Item"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    label="Item"
                    label-for="name-input"
                    invalid-feedback="Item is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="item.name"
                        placeholder="Enter item"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Unit Cost"
                    label-for="name-input"
                    invalid-feedback="Unit Cost is required"
                >
                    <b-form-input
                        id="name-input"
                        type="number"
                        placeholder="Enter unit cost"
                        v-model="item.rate"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Description"
                    label-for="name-input"
                    invalid-feedback="Description is required"
                >
                    <b-form-textarea
                        id="textarea"
                        v-model="item.description"
                        placeholder="Enter description..."
                        rows="3"
                    ></b-form-textarea>
                </b-form-group>
            </form>
        </b-modal>

        <!-- Edit Item -->
        <b-modal 
            id="modal-prevent-closing-edit" 
            ref="modal"
            title="Edit Item"  
            @show="resetEditModal"
            @hidden="resetEditModal"
            @ok="handleEditOk"
        >
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                {{ edit_item }}
                <b-form-group
                    label="Item"
                    label-for="name-input"
                    invalid-feedback="Item is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="edit_item.name"
                        placeholder="Enter item"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Unit Cost"
                    label-for="name-input"
                    invalid-feedback="Unit Cost is required"
                >
                    <b-form-input
                        id="name-input"
                        type="number"
                        placeholder="Enter unit cost"
                        v-model="edit_item.rate"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Description"
                    label-for="name-input"
                    invalid-feedback="Description is required"
                >
                    <b-form-textarea
                        id="textarea"
                        v-model="edit_item.description"
                        placeholder="Enter description..."
                        rows="3"
                    ></b-form-textarea>
                </b-form-group>
            </form>
        </b-modal>

        <!-- Delete Modal -->
        <b-modal 
            id="modal-center" 
            centered 
            hide-header
            @ok="handleDeleteOk"
        >
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>
        </b-modal>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'name', label: 'Item', sortable: true },
                { key: 'description', label: 'Description', sortable: true },
                { key: 'rate', label: 'Unit Cost', sortable: true },
                { key: 'action', label: 'Action', sortable: false }
            ],

            item: {
                name: '',
                rate: null,
                description: ''
            },

            edit_item: {
                id: null,
                name: '',
                rate: null,
                description: ''
            },
    
            filter: null,
            delete_value: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            items: state => state.item.items
        }),

        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },

    mounted() {
        this.$store.dispatch("item/fetchItem")
        this.totalRows = this.items.length;
    },

    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.item.name = ''
            this.item.rate = null
            this.item.description = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleSubmit() {
            this.$store.dispatch("item/addItem", this.item).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.item.name = '',
                    this.item.rate = null,
                    this.item.description = ''
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editItem(item) {
            this.edit_item.id = item.id
            this.edit_item.name = item.name
            this.edit_item.rate = item.rate
            this.edit_item.description = item.description
        },

        // resetEditModal() {
        //     this.edit_item.name = ""
        //     this.edit_item.id = null
        //     this.edit_item.rate = null
        //     this.edit_item.description = ""
        // },

        handleEditSubmit() {
            this.$store.dispatch("item/updateItem", this.edit_item).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_item.name = ""
                    this.edit_item.id = null
                    this.edit_item.rate = null
                    this.edit_item.description = ""
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },

        deleteItem(id) {
            this.delete_value = id
        },

        handleDeleteOk() {
            this.$store.dispatch("item/deleteItem", this.delete_value).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
  