import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PO_UPDATE',
    smsDelete : 'PO_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        purchase_order: [],
        purchase_order_detail: {},
        purchase_order_replies:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_PO (state, project) {
            state.purchase_order = project;
        },

        SET_PO_DETAIL (state, project) {
            state.purchase_order_detail = project;
        },
        SET_PO_REPLY(state, reply) {
            state.purchase_order_replies = reply;
        },


        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updatePO ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try {

                payload.purchase_order.details=JSON.stringify(payload.purchase_order.details)
                console.log(payload.purchase_order.details)

                let data = new FormData()
                let payloadData = buildFormData(data, payload.purchase_order);

                let response = await axios.patch(V1API.edit_purchase_order + payload.id, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchPO')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deletePO ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_purchase_order + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchPO');

            return response
        },

        async fetchPO ({ commit }) {
            commit('PO_LOADING', true);
            commit('PO_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_purchase_order, config);
                let response = result.data

                commit('SET_PO', response.data);

            } catch(error) {
                commit('PO_ERROR', true);
            }
            commit('PO_LOADING', false);
        },

        async fetchPODetail ({ commit }, payload) {
            commit('PO_LOADING', true);
            commit('PO_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_purchase_order_details + payload, config);
                let response = result.data

                commit('SET_PO_DETAIL', response.data);

            } catch(error) {
                commit('PO_ERROR', true);
            }
            commit('PO_LOADING', false);
        },

        async addPO ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{
                payload.details=JSON.stringify(payload.details)
                // console.log(payload.details)

                let data2= new FormData()
                let payloadData = buildFormData(data2, payload);
                // let magic = encode(payloadData)

                let response = await axios.post(V1API.create_purchase_order, payloadData, config).then(result=>{
                    // let data = decode(result.data)
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchPO')
                return response

            }catch (e) {
                console.log(e)
                commit('SUBMIT_ERROR', true);
            }finally {
                // console.log('jgdfjg - 2')
                commit('SUBMIT_LOADING', false);
            }
        },
        async addPOAttachment ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };
            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.attachment);
        
                let response = await axios.post(V1API.add_po_attachment, payloadData, config).then(result=>{
                  
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch("fetchPODetail",payload.po_id)
                return response

            }catch (e) {
               
                commit('SUBMIT_ERROR', true);
            }finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },
        async deleteAttachmentFromPO ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_po_attachment + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchPODetail',payload.po_id)
            
            return response
        },

        // Purchase Order Reply


        async addPOReply ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.purchase_order_add_reply, {

                    purchase_order: payload.purchase_order,
                    reply:payload.reply,
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

               
                dispatch('fetchPOReplies',payload.purchase_order)
                commit('SUBMIT_SUCCESS', true);
                
                
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async fetchPOReplies ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_replies_of_po + payload, config);
                let response = result.data
                commit('SET_PO_REPLY', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },










    },

    getters: {}
}
