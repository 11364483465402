<template>
<div>
    <Breadcrumbs main="My Updates" submain="Requsition" title="Requisition View" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-8">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                <div>
                                    <!-- <b-badge variant="primary" class="my-4">
                                        <h7 style="text-align: center;font-size: 14px;">Employee Details</h7>
                                    </b-badge> -->
                                </div>
                                <div style="display: flex; align-items: center; justify-content: center;">
                                    <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(detail)" title="Reply"></feather>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Title</label>
                                        <input class="form-control" type="text" placeholder="Enter requisition title" disabled v-model="requisition.title">
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Project</label>
                                        <select class="form-control digits" disabled v-model="requisition.project">
                                            <option v-for="(project, index) in projects" :key="index" :value="project.id">{{ project.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Items</label>
                                        <ItemTable v-if="requisition.details" style="margin-bottom: 10px;" id="item-table" :tableData="requisition.details" @itemsUpdated="updateItems" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <!-- Grand Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Grand Total</label>
                                        <input class="form-control" type="number" placeholder="Enter grand total" disabled v-model="requisition.grand_total" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Note</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter note..." rows="2" disabled v-model="requisition.note"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div style="display: flex; flex-direction: row; gap: 10px;">
                                <h7>Requisition Status:</h7>
                                <div>
                                    <span @click="getStatus(detail.status)" v-if="detail.status == 'APPROVED'">
                                        <b-badge v-b-modal.modal-prevent-closing pill variant="success" v-b-tooltip.hover title="Update Status">{{ detail.status }}</b-badge>
                                    </span>
                                    <span @click="getStatus(detail.status)" v-if="detail.status == 'PENDING'">
                                        <b-badge v-b-modal.modal-prevent-closing pill variant="primary" v-b-tooltip.hover title="Update Status">{{ detail.status }}</b-badge>
                                    </span>
                                    <span @click="getStatus(detail.status)" v-if="detail.status == 'DUE'">
                                        <b-badge v-b-modal.modal-prevent-closing pill variant="danger" v-b-tooltip.hover title="Update Status">{{ detail.status }}</b-badge>
                                    </span>
                                </div>
                            </div>

                            <h6 class="mt-4 mb-2">Already Refered</h6>
                            <div class="row">
                                <div class="col">
                                    <div v-for="user in detail.refering_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete" style="width: fit-content;">
                                        <div class="mt-2 badge-style">
                                            <h7>{{ user.first_name}} {{ user.last_name}}</h7>
                                            <b-icon icon="x" style="color: white;"></b-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>

            <div class="col-sm-4">
                <px-card>
                    <div class="w-100 p-4">
                        <div>
                            <div class="d-flex justify-content-between ">
                                <h6 class="mb-3">Supporting Documents</h6>

                                <feather type="plus-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-modal.modal-scrollable-add-document v-b-tooltip.hover title="Add Documents"> </feather>

                            </div>

                            <b-table show-empty  sticky-header :items="detail.requisition_attach" :fields="Attachment" head-variant="light">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template #cell(title)="data">
                                    <a :href="host+data.item.requisition_attachment" target="_blank">{{ truncateText(data.item.attach_title, 100) }}</a>
                                </template>
                                <template #cell(action)="data">
                                    <div style="display: flex; flex-direction: row; gap:10px">
                                        
                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </px-card>
            </div>

            <!-- <div class="col-sm-4">
                <div class="content-container-attahment w-100">
                    <div>
                        <div class="d-flex justify-content-between ">
                            <h6 class="my-2">Supporting Documents</h6>

                            <feather type="plus-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-modal.modal-scrollable-add-document v-b-tooltip.hover title="Add Documents"> </feather>

                        </div>

                        <b-table show-empty  sticky-header :items="detail.requisition_attach" :fields="Attachment" head-variant="light">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>

                            <template #cell(title)="data">
                                <a :href="host+data.item.requisition_attachment" target="_blank">{{ truncateText(data.item.attach_title, 100) }}</a>
                            </template>
                            <template #cell(action)="data">
                                <div style="display: flex; flex-direction: row; gap:10px">
                                    
                                    <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                </div>
                            </template>
                        </b-table>
                     
                    </div>
                </div>
            </div> -->
        </div>
    </div>

    <!-- Project Attachment Create -->
    <b-modal id="modal-scrollable-add-document" ref="modal" title="Add document" @ok="handleAttachOk">
        <form ref="form" @submit.stop.prevent="handleAttahmentSubmit">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload Documents</label>
                        <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
    <!-- Container-fluid Ends-->

    <!-- Container-fluid Ends-->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Requisition Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Reply Create Modal -->

    <b-modal id="modal-scrollable" ref="modal" title="Requistion Replies" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleOk">

        <h6>Already Refered</h6>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="user in detail.refering_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ user.first_name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>
        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>

        <!-- <div style="margin-top: 30px;" v-if="ticket_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in ticket_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleLeaveReplySubmit()">
                <button @click="handleLeaveReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div> -->
    </b-modal>

    <!-- Requistion Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refere?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {
    computed
} from "vue";
import ItemTable from "../../requisition/edit-item-table.vue";
import Multiselect from 'vue-multiselect';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    myHost
} from "../../../../_helpers/host";
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        ItemTable,
        Multiselect
    },

    data() {
        return {
            selectedStatus: '',
            statuses: ['DUE', 'PENDING', 'APPROVED'],
            host: myHost.host,

            add_attachment: {
                requisition_attachment: null,
                requisition_id: '',
            },
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            update_id: '',
            assignedMembers: [],

            requisition: {
                title: '',
                refering_to: null,
                details: null,
                project: null,
                total: computed(() => {
                    let total = 0;
                    if (this.requisition.details.length >= 1) {
                        this.requisition.details.map((item) => {
                            total = total + item.amount;
                        });
                    }
                    return total;
                }),

                grand_total: computed(() => {
                    if (this.requisition.total !== 0) {
                        const tax = this.requisition.vat_tax || 0;
                        const discount = this.requisition.discount || 0;
                        let gtotal =
                            this.requisition.total +
                            tax * (this.requisition.total / 100) -
                            discount * (this.requisition.total / 100);
                        return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                    }
                    return 0;
                }),
                note: ''
            },
            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                },

            ],

            client: {
                name: '',
                company: '',
                designation: '',
                phone: '',
                title: '',
                status: 'Active'
            },
            temp_id: '',
        };
    },

    computed: {
        ...mapState({
            user_list: state => state.employee.employee_list,
            detail: state => state.requisition.requisition_detail,
            projects: state => state.project.project,
        })
    },

    mounted() {
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("requisition/fetchRequisitionDetail", this.$route.params.id);
        this.$store.dispatch("project/fetchProject");
    },

    watch: {
        detail(oldValue, newValue) {
            this.requisition.id = this.detail.id
            this.requisition.title = this.detail.title
            this.requisition.details = this.detail.details
            this.requisition.total = this.detail.total
            this.requisition.grand_total = this.detail.grand_total
            this.requisition.note = this.detail.note
            this.requisition.project = this.detail.project.id
            this.requisition.refering_to = this.detail.refering_to
        },

        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        }
    },

    methods: {
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },
        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("requisition/RequisitionAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })

        },
        deletetAllocation(id) {
            this.temp_id = id
        },
        handleAllocationDeleteOk() {
            this.$store.dispatch("requisition/RequisitionDeleteRefer", {
                id: this.$route.params.id,
                refer_id: this.temp_id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-center-deleteAlocation')
                    })

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })

        },
        Reply(type) {
            this.my_reply.id = type.id
            // this.my_reply.reply = type.name
            // this.my_reply.refer_to = type.refer_to
            // this.$store.dispatch("leave/fetchLeaveReply", this.my_reply.id)
        },
        getStatus(status, id) {
            this.selectedStatus = status

        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {

            this.$store.dispatch("requisition/updateRequisitionStatus", {
                id: this.$route.params.id,
                status: this.selectedStatus
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },
        handleAttachOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleAttahmentSubmit()
        },

        handleAttahmentSubmit() {
            this.add_attachment.requisition_id = this.$route.params.id
            this.$store.dispatch("requisition/addRequistionAttachment", {
                attachment: this.add_attachment,
                requisition_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.add_attachment.requisition_attachment = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable-add-document')
            })
        },
        deleteAttach(id) {
            this.$store.dispatch("requisition/deleteAttachmentFromRequisition", {
                id: id,
                requisition_id: this.$route.params.id,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
        uploadAttachFile(e) {
            this.add_attachment.requisition_attachment = e.target.files[0]

        },

        resetModal() {
            this.name = ''
            this.nameState = null
        },

    }
};
</script>

<style scoped>
.content-container-attahment {
    background-color: white;
    padding: 10px;
}

.badge-style {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}
</style>
