<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" submain="Finance" title="Purchase Order Edit" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">

                                <div class="row"> 
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Vendor</label>
                                            <select class="form-control digits" v-model="purchase_order.vendor">
                                                <option v-for="(vendor, index) in vendors" :key="index" :value="vendor.user.id">{{ vendor.user.first_name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Project</label>
                                            <select class="form-control digits" v-model="purchase_order.project">
                                                <option v-for="(project, index) in projects" :key="index" :value="project.id">{{ project.title }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input class="form-control" type="text" placeholder="Enter email address" v-model="purchase_order.email">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Currency</label>
                                            <select class="form-control digits" v-model="purchase_order.currency">
                                                <option v-for="(currency, index) in currencies" :key="index">{{ currency }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Vendor Address</label>
                                            <input class="form-control" type="text" placeholder="Enter vendor address" v-model="purchase_order.vendor_address">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Billing Address</label>
                                            <input class="form-control" type="text" placeholder="Enter billing address" v-model="purchase_order.billing_address">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Purchase Order Date</label>
                                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="purchase_order.purchase_order_date"></datepicker>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Due Date</label>
                                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="purchase_order.due_date"></datepicker>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Upload Attachment</label>
                                            <b-form-file
                                                v-model="purchase_order.purchase_order_attachment"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                                @change="uploadFile"
                                            ></b-form-file>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Items</label>
                                            <ItemTable
                                                v-if="purchase_order.details"
                                                style="margin-bottom: 10px;"
                                                id="item-table"
                                                :tableData="purchase_order.details"
                                                @itemsUpdated="updateItems"
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-sm-4"></div>
                                    <div class="col-sm-8">
                                        <!-- Total -->
                                        <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                            <label style="margin-top: 8px; font-weight: 500; width: 120px;">Total</label>
                                            <input class="form-control" type="number" placeholder="Enter total amount" disabled v-model="purchase_order.total" />
                                        </div>

                                        <!-- Vat -->
                                        <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                            <label style="margin-top: 8px; font-weight: 500; width: 120px;">Vat (%)</label>
                                            <input class="form-control" type="number" placeholder="Enter vat (%)" v-model="purchase_order.vat" />
                                        </div>

                                        <!-- Tax -->
                                        <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                            <label style="margin-top: 8px; font-weight: 500; width: 120px;">Tax (%)</label>
                                            <input class="form-control" type="number" placeholder="Enter tax (%)" v-model="purchase_order.tax" />
                                        </div>

                                        <!-- Discount -->
                                        <!-- <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                            <label style="margin-top: 8px; font-weight: 500; width: 120px;">Discount (%)</label>
                                            <input class="form-control" type="number" placeholder="Enter discount (%)" v-model="purchase_order.discount" />
                                        </div> -->

                                        <!-- Grand Total -->
                                        <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                            <label style="margin-top: 8px; font-weight: 500; width: 120px;">Grand Total ({{ purchase_order.currency ? purchase_order.currency : "BDT" }})</label>
                                            <input class="form-control" type="number" placeholder="Enter grand total" disabled v-model="purchase_order.subtotal" />  
                                        </div>

                                        <!-- In Words -->
                                        <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                            <label style="margin-top: 8px; font-weight: 500; width: 120px;">In Words</label>
                                            <input class="form-control" type="text" placeholder="Enter grand total in words" v-model="purchase_order.in_words" />  
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Remarks</label>
                                            <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter remarks..." rows="2" v-model="purchase_order.remarks"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mb-0">
                                            <button @click="onSubmit" class="btn btn-success mr-3">Submit</button>
                                            <router-link to="/finance-and-accounts/finance/purchase-order/list">
                                                <button class="btn btn-danger" href="#">Go Back</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { computed } from "vue";
import ItemTable from "./edit-item-table.vue";
import moment from "moment";

export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        ItemTable
    },

    data(){
        return{
            purchase_order: {
                vendor: '',
                project: '',
                email: '',
                currency: 'BDT',
                vendor_address: '',
                billing_address: '',
                purchase_order_date: null,
                due_date: null,
                details: null,
                details_in_depth: [],
                total: computed(() => {
                    let total = 0;
                    if (this.purchase_order.details_in_depth.length >= 1) {
                        this.purchase_order.details_in_depth.map((item) => {
                            total = total + item.amount;
                        });
                    }
                    return total;
                }), 
                vat_tax: '5',
                discount: '10',
                subtotal: computed(() => {
                    if (this.purchase_order.total !== 0) {
                        const tax = this.purchase_order.tax || 0;
                        const vat = this.purchase_order.vat || 0;
                        const discount = this.purchase_order.discount || 0;
                        let gtotal =
                        this.purchase_order.total +
                        tax * (this.purchase_order.total / 100) +
                        vat * (this.purchase_order.total / 100) -
                        discount * (this.purchase_order.total / 100);
                        return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                    }
                    return 0;
                }),
                purchase_order_attachment: null,
                in_words: '',
                remarks: ''
            },

            vendor: {
                name: '',
                company: '',
                designation: '',
                phone: '',
                email: '',
                status: 'Active'
            },

            currencies: ['BDT', 'USD', 'EUR']
        };
    },

    computed: {
        ...mapState({
            detail: state => state.purchase_order.purchase_order_detail,
            items: state => state.item.items,
            projects: state => state.project.project,
            vendors: state => state.vendor.vendor
        })
    },

    mounted() {
        this.$store.dispatch("purchase_order/fetchPODetail", this.$route.params.id)
        this.$store.dispatch("item/fetchItem")
        this.$store.dispatch("project/fetchProject")
        this.$store.dispatch("vendor/fetchVendor")
    },

    watch: {
        detail(oldValue, newValue) {
            this.purchase_order.vendor = this.detail.vendor.id
            this.purchase_order.project = this.detail.project.id
            this.purchase_order.email = this.detail.email
            this.purchase_order.currency = this.detail.currency
            this.purchase_order.vendor_address = this.detail.vendor_address
            this.purchase_order.billing_address = this.detail.billing_address
            this.purchase_order.purchase_order_date = this.detail.purchase_order_date
            this.purchase_order.due_date = this.detail.due_date
            this.purchase_order.purchase_order_attachment = this.detail.purchase_order_attachment
            this.purchase_order.details = JSON.parse(this.detail.details)
            this.purchase_order.details_in_depth = this.purchase_order.details
            this.purchase_order.total = this.detail.total
            this.purchase_order.vat = this.detail.vat
            this.purchase_order.tax = this.detail.tax
            // this.purchase_order.discount = this.detail.discount
            this.purchase_order.subtotal = this.detail.subtotal
            this.purchase_order.in_words = this.detail.in_words
            this.purchase_order.remarks = this.detail.remarks
        }
    },

    methods: {
        updateItems(data) {
            // console.log(data);
            if (data) {
                this.purchase_order.details = data;
                this.purchase_order.details_in_depth = data;
            }
        },

        uploadFile(e){
            this.purchase_order.purchase_order_attachment = e.target.files[0];
        },

        onSubmit() {
            if(this.purchase_order.purchase_order_date !== null) {
                this.purchase_order.purchase_order_date = moment(String(this.purchase_order.purchase_order_date)).format("YYYY-MM-DD");
            }
            if(this.purchase_order.due_date !== null) {
                this.purchase_order.due_date = moment(String(this.purchase_order.due_date)).format("YYYY-MM-DD");
            }

            // this.purchase_order.details = JSON.stringify(this.purchase_order.details)

            // console.log(this.purchase_order.details)
            // alert(JSON.stringify(this.purchase_order.details))

            this.$store.dispatch("purchase_order/updatePO", {id: this.$route.params.id, purchase_order: this.purchase_order}).then(response => {
                if(response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    setTimeout(() => {
                        this.$router.push('/finance-and-accounts/finance/purchase-order/list') 
                    }, 2000)
                    
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        resetModal() {
            this.name = ''
            this.nameState = null
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            // Push the name to submitted names
            alert(JSON.stringify(this.vendor))

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        }
    }
};
</script>
