<template>
    <div>
        <Breadcrumbs main="Job" title="Job Details"/>

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-5">
                    <div class="card p-3" style="width: 100%;"> 
                        <div class="task-board-task-scroll">
                            <div style="display: flex; flex-direction: column; height: 100%; overflow-y: auto; padding-right: 12px;">
                                <div>
                                    <div style="display:flex; flex-direction: column; align-items: center; justify-content: center;" class="w-full">
                                        <img style="width: 100%; border-radius: 0.75rem; object-fit: cover; background-size: cover;" :src="job.image" class="image-height">
                                    </div>

                                    <div style="margin-top: 25px; display:flex; flex-direction: column; align-items: start; justify-content: left; text-align: center;">
                                        <h5 style="text-align: left;">{{ job.title }}</h5>
                                        <h5 class="mt-2"><b-badge pill variant="success">{{ job.position }}</b-badge></h5>
                                        <div class="mt-1 d-flex">
                                            <h5><b-badge pill variant="primary">Vacancy: {{ job.vacancies }}</b-badge></h5>
                                            <h5 style="margin-left: 12px;"><b-badge pill variant="info">Applied: {{ job.applied }}</b-badge></h5>
                                        </div>
                                    </div>

                                    <div style="margin-top: 15px; display:flex; flex-direction: column; align-items: start; justify-content: left;; text-align: center; font-size: medium;">
                                        <h7>Post Date: {{ job.opening }}</h7>
                                        <h7>Expiry Date: <span style="color: red;">{{ job.deadline }}</span></h7>
                                    </div>
                                </div>

                                <div style="margin-top: 20px;">
                                    <div v-if="showDescription">
                                        <h7 v-if="job.description.length < 450">{{ job.description }}</h7> 
                                        <h7 v-else>{{ job.description.substring(0, 450) }}</h7> 
                                    </div>
                                    <div v-else class="pb-4 lg:pb-6">
                                        <div v-html="job.description"></div>
                                    </div>
                                    <div v-if="job.description.length > 450">
                                        <b-button v-if="showDescription" variant="primary" size="sm" @click="displayDescription" style="margin-top: 20px;">See More</b-button>
                                        <b-button v-else variant="primary" size="sm" @click="closeDescription">See Less</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

                <div class="col-lg-7">
                    <div class="card p-3" style="width: 100%;"> 
                        <div class="task-board-task-scroll">
                            <div style="display: flex; flex-direction: column; gap: 15px; height: 100%; overflow-y: auto; padding-right: 12px;">
                                <div class="applicant-view">
                                    <div v-for="applicant in applicants" :key="applicant.index">
                                        <div style="height: 390px; display: flex; flex-direction: column; align-items: center; text-align: center; background-color: rgb(243 244 246); border-radius: 0.75rem; filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06)); padding: 20px;">
                                            <div style="width: 100%; display: flex; align-items: start;">
                                                <b-badge pill variant="success">{{ applicant.status }}</b-badge>
                                            </div>
                                            <img style="height: 100px; width: 100px; border-radius: 100%;" :src="applicant.image">
                                            <div style="margin-top: 15px;">
                                                <h5>{{ applicant.name }}</h5>
                                                <h7>{{ applicant.position }} | {{ applicant.type }}</h7>
                                            </div>
                                            <div style="position: absolute; bottom: 25px; width: 100%;">
                                                <router-link :to="`/job/canditate/detail/${applicant.name}`">
                                                    <b-button variant="primary">View Details</b-button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var primary = localStorage.getItem('primary_color') || '#FF0006';
var secondary = localStorage.getItem('secondary_color') || '#818284';

import {
    mapState
} from 'vuex';


export default {
    data() {
      return {
            selected: 'a',

            options: [
                { value: 'a', text: 'First Option' },
                { value: 'b', text: 'Selected Option' },
                { value: 'c', text: 'Third Option' },
            ],

      

            applicants: [
                {
                    id: 1,
                    name: 'Rasel Mir',
                    position: 'Senior Marketing Executive',
                    type: 'Full-time',
                    status: 'Applied',
                    image: "https://placehold.co/120x120"
                },
                {
                    id: 2,
                    name: 'Zavid Iqbal',
                    position: 'English Copywrite',
                    type: 'Full-time',
                    status: 'Applied',
                    image: "https://placehold.co/120x120"
                },
                {
                    id: 3,
                    name: 'Imran Hossain',
                    position: 'Business Development - Senior, International Sales',
                    type: 'Full-time',
                    status: 'Applied',
                    image: "https://placehold.co/120x120"
                },
                {
                    id: 4,
                    name: 'Rasel Mir',
                    position: 'Senior Marketing Executive',
                    type: 'Full-time',
                    status: 'Applied',
                    image: "https://placehold.co/120x120"
                }
            ],
            
            search_text: '',
            selected_job: null,
            notes:'',
            tag_a_manager:null,

            managers: [
                { value: null, text:'Tag a Manager' },
                { value: 'a', text: 'A' },
                { value: 'b', text: 'B' }
            ],

            showDescription: true
        };
    },

    methods: {
        displayDescription(){
            this.showDescription = false
        },

        closeDescription(){
            this.showDescription = true
        }
    },
    computed: {
        ...mapState({
            job: state => state.job.job_details,
            
        }),
    },
    mounted() {
        this.$store.dispatch("job/fetchJobDetails", this.$route.params.id)
    },
}
</script>

<style scoped>
.image-height {
    height: 310px;
}

.applicant-view {
    display: grid; 
    grid-template-columns: repeat(3, minmax(0, 1fr)); 
    gap: 25px;
}

.task-board-task-scroll {
    position: relative; 
    height: 75.5vh;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

@media screen and (max-width: 1680px) {
    .image-height {
        height: 270px;
    }

    .applicant-view {
        display: grid; 
        grid-template-columns: repeat(2, minmax(0, 1fr)); 
        gap: 20px;
    }
}

@media screen and (max-width: 991px) {
    .image-height {
        height: 290px;
    }

    .applicant-view {
        display: grid; 
        grid-template-columns: repeat(3, minmax(0, 1fr)); 
        gap: 20px;
    }

    .task-board-task-scroll {
        position: relative; 
        height: 100%;
    }
}

@media screen and (max-width: 730px) {
    .applicant-view {
        display: grid; 
        grid-template-columns: repeat(2, minmax(0, 1fr)); 
        gap: 20px;
    }
}

@media screen and (max-width: 645px) {
    .image-height {
        height: 260px;
    }
}

@media screen and (max-width: 430px) {
    .image-height {
        height: 240px;
    }

    .applicant-view {
        display: grid; 
        grid-template-columns: repeat(1, minmax(0, 1fr)); 
        gap: 20px;
    }
}
</style>
  