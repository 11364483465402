import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'ASSET_UPDATE',
    smsDelete : 'ASSET_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        asset: [],
        asset_detail: {},
        get_my_asset:[],
        get_user_list:[],
        asset_type:[],
        count:null,
        assetLoading: false,
        assetError: false,

        asset_allocation_list:[],

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_ASSET (state, asset) {
            state.asset = asset;
        },
        SET_MY_ASSET(state, { data, count }){
            state.get_my_asset = data,
            state.count = count
        },
        SET_ASSET_ALLOCATION_LIST(state,asset){
            state.asset_allocation_list = asset
        },
        SET_ASSET_TYPE(state,type){
            state.asset_type = type
        },
        
        SET_ASSET_DETAIL (state, asset) {
            state.asset_detail = asset;
        },

        ASSET_LOADING (state, loading) {
            state.assetLoading = loading;
        },
        ASSET_ERROR (state, loading) {
            state.assetError = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },
        SET_USER_LIST (state, asset) {
            state.get_user_list = asset;
        },

    },

    actions: {
        
        async fetchAsset ({ commit }) {
            commit('ASSET_LOADING', true);
            commit('ASSET_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_asset_list, config);
                let response = result.data.data
              
                commit('SET_ASSET', response);

            } catch(error) {
                commit('ASSET_ERROR', true);
            }
            commit('ASSET_LOADING', false);
        },
        
        async fetchMyAsset ({ commit }) {
            commit('ASSET_LOADING', true);
            commit('ASSET_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_my_asset, config);
                const { data, count } = result.data;
               
                commit('SET_MY_ASSET', { data, count });

            } catch(error) {
                commit('ASSET_ERROR', true);
            }
            commit('ASSET_LOADING', false);
        },
        async fetchUserList ({ commit }) {
            commit('ASSET_LOADING', true);
            commit('ASSET_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.user_list, config);
                let response = result.data.data
              
                commit('SET_USER_LIST', response);

            } catch(error) {
                commit('ASSET_ERROR', true);
            }
            commit('ASSET_LOADING', false);
        },

        async fetchAssetDetails ({ commit }, payload) {
            commit('ASSET_LOADING', true);
            commit('ASSET_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.asset_details + payload, config);
                let response = result.data

                commit('SET_ASSET_DETAIL', response.data);

            } catch(error) {
                commit('ASSET_ERROR', true);
            }
            commit('ASSET_LOADING', false);
        },


        async updateAsset ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  },
                  
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
               
                async () => {
                    payload.payload.specification=JSON.stringify(payload.payload.specification)
                

                    response= await axios.patch(V1API.update_asset+payload.id,payload.payload,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchAsset');

                }
            )
            return response
        },
    

        
        async deleteAsset ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_asset + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchAsset');

            return response
        },

        

        async addAsset ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{

                payload.specification=JSON.stringify(payload.specification)
         

                let data= new FormData()
                let payloadData = buildFormData(data, payload);
           

                let response = await axios.post(V1API.create_asset, payloadData, config).then(result=>{
                  
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchAsset')
                return response

            }catch (e) {
                
                commit('SUBMIT_ERROR', true);
            }finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },

        async assetAllocate ({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
              
            };
            try{
                
                let response = await axios.post(V1API.asset_allocation_create, payload, config).then(result=>{
                    let data = result.data
                    return data
                });
                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchAsset');
                return response

            }catch (e) {
                
                commit('SUBMIT_ERROR', true);
            }finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },
        async fetchAssetAllocationList ({ commit }) {
            commit('ASSET_LOADING', true);
            commit('ASSET_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.asset_allocation_list, config);
                let response = result.data.data
              
                commit('SET_ASSET_ALLOCATION_LIST', response);

            } catch(error) {
                commit('ASSET_ERROR', true);
            }
            commit('ASSET_LOADING', false);
        },
        async deleteAssetAllocation ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.asset_allocation_delete + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchAsset');
            dispatch('fetchAssetDetails',payload.asset_id);
            dispatch('fetchAssetAllocationList');

            return response
        },

        async addAssetAttachment ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };
            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.attachment);
        
                let response = await axios.post(V1API.asset_add_attachment, payloadData, config).then(result=>{
                  
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch("fetchAssetDetails",payload.asset_id)
                return response

            }catch (e) {
               
                commit('SUBMIT_ERROR', true);
            }finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },
        async deleteAttachmentFromAsset ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.asset_delete_attachment + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchAssetDetails',payload.asset_id)
            
            return response
        },



        ////

        
        async fetchAssetType({commit}) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_asset_type, config);
                let response = result.data.data

                commit('SET_ASSET_TYPE', response);

            } catch(error) {
                commit('ASSET_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },
        

        async addAssetType ({commit, dispatch}, payload) {
            commit('SUBMIT_ERROR', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },

            };
            try{

                let response = await axios.post(V1API.create_asset_type, payload, config).then(result=>{

                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchAssetType')
                return response

            }catch (e) {

                commit('SUBMIT_ERROR', true);
            }finally {

                commit('SUBMIT_LOADING', false);
            }
        },



        async deleteAssetType ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_asset_type + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchAssetType');

            return response
        },


        async updateAssetType ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,

                async () => {
                    response = await axios.patch(V1API.update_asset_type + payload.id, {
                        name: payload.name,
                       
                    }, config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch('fetchAssetType');

                }
            )
            return response
        },







    },

    getters: {}
}
