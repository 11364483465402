<template>
<div>
    <Breadcrumbs main="HR" submain="Leave Management" title="Leave Requests" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">
                            <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">

                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template #cell(employee)="data">
                                    <span>
                                        <router-link :to="`/hr/organization/employee/details/${data.item.employee.id}`" class="link-style">
                                            {{ data.item.employee.first_name}} {{ data.item.employee.last_name}}
                                        </router-link>
                                    </span>
                                </template>

                                <template #cell(leave_type)="data">
                                    <span>
                                        {{ data.item.leave_type.name}}
                                    </span>
                                </template>

                                <template #cell(subject)="data">
                                    <div @click="leaveDetails(data.item)">
                                        <p v-b-tooltip.hover title="Subject" v-b-modal.modal-prevent-closing-edit>{{ data.item.subject}}</p>
                                    </div>
                                </template>

                                <template #cell(refer_to)="data">
                                    <span>
                                        <div v-if="data.item.refer_to !== null && data.item.refer_to.length > 0">
                                            <div v-for="user in data.item.refer_to" :key="user.id">
                                                <p>{{ user.first_name }}</p>
                                            </div>
                                        </div>
                                    </span>
                                </template>

                                <template #cell(status)="row">
                                    <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'APPROVED'">
                                        <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="success" title="Update Status">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'PENDING'">
                                        <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="primary" title="Update Status">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'DUE'">
                                        <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="warning" title="Update Status">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'REJECTED'">
                                        <b-badge v-b-modal.modal-prevent-closing pill v-b-tooltip.hover variant="danger" title="Update Status">{{ row.value }}</b-badge>
                                    </span>
                                </template>

                                <template #cell(action)="data">
                                    <span style="display: flex; gap: 12px;">
                                        <div>
                                            <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(data.item)" title="Reply"></feather>
                                        </div>
                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteRequest(data.item.id)" title="Delete"></feather>
                                    </span>
                                </template>
                            </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Status Update Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Leave Status" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Reply Create Modal -->
    <b-modal id="modal-scrollable" ref="modal" title="Leave Replies" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <h6>Already Refered</h6>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ user.first_name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>
        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>

        <div style="margin-top: 30px;" v-if="ticket_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in ticket_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleLeaveReplySubmit()">
                <button @click="handleLeaveReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>

    <!-- Leave Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refer?</div>
    </b-modal>

    <!-- Leave Request Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleRequestDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this request?</div>
    </b-modal>

    <!-- Leave Detail Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Leave Details" hide-footer>
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <b-form-group label="Employee Name" label-for="name-input">
                <b-form-input v-model="leave_details.employee_name" placeholder="" disabled></b-form-input>
            </b-form-group>

            <b-form-group label="Leave Type" label-for="name-input">
                <b-form-input v-model="leave_details.leave_type" placeholder="" disabled></b-form-input>
            </b-form-group>

            <b-form-group label="Subject" label-for="name-input">
                <b-form-input v-model="leave_details.subject" placeholder="" disabled></b-form-input>
            </b-form-group>

            <div class="row">
                <div class="col-sm-6">
                    <b-form-group label="Start Date" label-for="name-input">
                        <datepicker placeholder="Select start date" input-class="datepicker-here form-control digits" v-model="leave_details.start_date" disabled></datepicker>
                    </b-form-group>
                </div>
                <div class="col-sm-6">
                    <b-form-group label="End Date" label-for="name-input">
                        <datepicker placeholder="Select end date" input-class="datepicker-here form-control digits" v-model="leave_details.end_date" disabled></datepicker>
                    </b-form-group>
                </div>
            </div>

            <b-form-group label="Reason" label-for="name-input">
                <b-form-textarea v-model="leave_details.reason" disabled ref="textarea" rows="2"></b-form-textarea>
            </b-form-group>

            <div class="d-flex flex-column" style="margin-top: 10px;">
                <div>
                    <h7>Reffered To</h7>
                </div>
                <div class="row mt-2">
                    <div class="text-center col-sm-6 w-full" v-for="user in leave_details.refer_to" :key="user.id">
                        <div class="w-100 mb-2 badge-style">
                            <h7>{{ user.first_name }}</h7>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>
</div>
</template>

<script>
import { mapState } from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import { myHost } from '../../../../_helpers/host';

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            items: [],
            tablefields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'employee',
                    label: 'Employee',
                    sortable: true
                },
                {
                    key: 'leave_type',
                    label: 'Leave Type',
                    sortable: true
                },
                {
                    key: 'subject',
                    label: 'Subject',
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: 'Start Date',
                    sortable: true
                },
                {
                    key: 'end_date',
                    label: 'End Date',
                    sortable: true
                },
                {
                    key: 'refer_to',
                    label: 'Referred To',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
                // { key: 'action', label: 'Action', sortable: false }
            ],

            statuses: ['DUE', 'PENDING', 'APPROVED', 'REJECTED'],
            selectedStatus: '',
            update_id: '',
            filter: null,
            assignedMembers: [],
            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            temp_id: '',
            temp_request_id: '',
            leave_details: {
                id: '',
                employee_name: '',
                leave_type: '',
                subject: '',
                reason: '',
                start_date: '',
                end_date: '',
                refer_to: [],
                status: '',
            },

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState('leave', ["leaveList", "assetLoading", "assetError", "submitError", "submitSuccess", "submitLoading"]),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        ...mapState({
            user_list: state => state.employee.employee_list,
            ticket_reply: state => state.leave.ticket_reply,
        })
    },

    created() {
        this.$store.dispatch('leave/fetchLeaveList').then(() => {
            this.items = this.leaveList
            this.totalRows = this.items.length;
        });
        this.$store.dispatch("employee/fetchEmployeeList");

    },

    watch: {
        leaveList(oldValue, newValue) {
            this.items = this.leaveList
        },

        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        }
    },

    mounted() {
        this.totalRows = this.items.length;
    },

    methods: {
        convertUrls(text) {
            const urlPattern = /https?:\/\/[^\s]+/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },
        leaveDetails(type) {
            this.leave_details.id = type.id
            this.leave_details.employee_name = type.employee.username
            this.leave_details.leave_type = type.leave_type.name
            this.leave_details.subject = type.subject
            this.leave_details.reason = type.reason
            this.leave_details.start_date = type.start_date
            this.leave_details.end_date = type.end_date
            this.leave_details.refer_to = type.refer_to
        },

        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.refer_to
            this.$store.dispatch("leave/fetchLeaveReply", this.my_reply.id)
        },

        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("leave/leaveAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        deletetAllocation(id) {
            this.temp_id = id
        },

        deleteRequest(id) {
            this.temp_request_id = id
        },

        handleAllocationDeleteOk() {
            this.$store.dispatch("leave/leaveDeleteRefer", {
                id: this.my_reply.id,
                refer_id: this.temp_id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        handleRequestDeleteOk() {
            this.$store.dispatch("leave/leaveDeleteRequest", this.temp_request_id).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.temp_request_id = ''
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center')
            })
        },

        handleLeaveReplySubmit() {
            this.$store.dispatch("leave/addLeaveReply", {
                leave: this.my_reply.id,
                reply: this.my_reply.reply
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.my_reply.id = '',
                        this.my_reply.reply = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        getStatus(status, id) {
            this.update_id = id
            this.selectedStatus = status
        },

        resetModal() {
            this.selectedStatus = ''
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            if (this.selectedStatus === 'APPROVED') {
                this.$store.dispatch("leave/approveLeave", {
                    id: this.update_id,
                }).then(response => {
                    if (response.code == 200) {

                    } else {

                    }
                })
            }
            this.$store.dispatch("leave/updateLeaveStatus", {
                id: this.update_id,
                status: this.selectedStatus
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}


.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1; 
}

.msg {
    background: #fff0f0;
    width: 100%; /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}


.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
