<template>
<div>
    <Breadcrumbs main="HR" submain="Asset Management" title="Asset Detail" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex flex-column flex-lg-row " style="gap: 10px;">
                            <div class="image-portion d-flex justify-content-center align-items-center ">
                                <div v-if="asset_details.image">
                                    <img :src="host+asset_details.image" alt="">
                                </div>
                                <div v-else>
                                    <img src="https://placehold.co/300" alt="">
                                </div>
                            </div>
                            <div class="feature-portion" style="display: flex; flex-direction: column;">
                                <div class="mb-3">
                                    <span v-if="asset_details.status == 'PENDING'">
                                        <b-badge pill variant="warning">{{ asset_details.status }}</b-badge>
                                    </span>
                                    <span v-if="asset_details.status == 'APPROVED'">
                                        <b-badge pill variant="success">{{ asset_details.status }}</b-badge>
                                    </span>
                                    <span v-if="asset_details.status == 'RETURNED'">
                                        <b-badge pill variant="danger">{{ dasset_details.status }}</b-badge>
                                    </span>
                                </div>
                                <h5>{{ asset_details.name }}</h5>

                                <span class="mt-3">Asset ID: <span class="font-weight-bold">{{ asset_details.asset_id }}</span></span>
                                <span class="mt-3">Asset Type: <span class="font-weight-bold">{{ asset_details.asset_type.name }}</span></span>
                                <span class="mt-3">Price: <span class="font-weight-bold">{{ asset_details.amount }}</span></span>
                                <span class="mt-3">Manufacture Date: <span class="font-weight-bold">{{ asset_details.manufacture_date }}</span></span>
                                <span class="mt-3">Purchase Date: <span class="font-weight-bold">{{ asset_details.purchase_date }}</span></span>
                                <!-- <span class="mt-3">Warranty: <span class="font-weight-bold">{{ asset_details.warranty }} {{ asset_details.warranty_type }}</span></span> -->
                                <span class="mt-3">Warranty End: <span class="font-weight-bold">{{ asset_details.warranty_end }}</span></span>
                                <span class="mt-3">Warranty Remaining: <span class="font-weight-bold text-primary">{{ getWarrantyRemaining(asset_details.warranty_end) }} days</span></span>

                            </div>
                        </div>
                        <div style="margin-top: 40px;">
                            {{ asset_details.details }}
                        </div>

  

                        <h6 class="my-4" v-if="asset_details.specification!=null">Specifications</h6>
                        <div class="table-container" v-if="asset_details.specification!=null">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="parsedDetails()" :fields="fields">
                                    <template #cell(id)="row">
                                        {{ row.index+1 }}
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="content-container-attahment w-100">
                                    <div class="mt-2 mb-2">
                                        <h6 v-if="asset_details.asset_attach.length>0">Asset Documents</h6>

                                        <b-table sticky-header :items="asset_details.asset_attach" :fields="Attachment" head-variant="light" v-if="asset_details.asset_attach.length>0">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>

                                            <template #cell(title)="data">
                                                <a :href="host+data.item.asset_attachment" target="_blank">{{ data.item.attach_title }}</a>
                                            </template>
                                            <template #cell(action)="data">

                                                <div style="display: flex; flex-direction: row; gap:10px">

                                                    <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                                </div>
                                            </template>

                                        </b-table>
                                        <button v-if="asset_details.asset_attach.length>0" class="btn btn-primary" v-b-modal.modal-scrollable-add-document>Upload More</button>
                                        <button v-else class="btn btn-primary" v-b-modal.modal-scrollable-add-document>Add Attachment</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <h6 class="mt-4 mb-3" v-if="asset_details.allocated_users.length > 0">Allocated User</h6>
                        <div class="row">
                            <div class="col-sm-6">
                                <div v-for="user in asset_details.allocated_users" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-assetallocation @click="deleteAssetAllocation(user.id)" title="Delete" style="width: fit-content;">
                                    <div class="mb-2 badge-style">
                                        <h7>{{ user.asset_user.first_name}} {{ user.asset_user.last_name}}</h7>
                                        <b-icon icon="x" style="color: white;"></b-icon>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Asset Allocate Delete Modal -->
    <b-modal id="modal-center-assetallocation" centered hide-header @ok="handleAssetDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this asset allocation?</div>
    </b-modal>

    <!-- Project Attachment Create -->
    <b-modal id="modal-scrollable-add-document" ref="modal" title="Add document" @ok="handleAttachOk">
        <form ref="form" @submit.stop.prevent="handleAttahmentSubmit">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload project file</label>
                        <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
    myHost
} from "../../../../_helpers/host";

export default {
    data() {
        return {
            asset_allocation_id: '',
            host: myHost.host,
            fields: [{
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: 'Item',
                    sortable: false
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: false
                }
            ],
            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                },

            ],
            add_attachment: {
                asset_attachment: null,
                asset_id: '',
            },

        };
    },

    mounted() {
        this.$store.dispatch("asset/fetchAssetDetails", this.$route.params.id);
    },

    computed: {
        ...mapState({
            asset_details: state => state.asset.asset_detail,
        })
    },

    watch: {
        asset_details(newValue, oldValue) {},

        'asset_details.specification': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.parsedDetails();
            }
        }
    },

    methods: {
        getWarrantyRemaining(warrantyEndDate) {
            const endDate = new Date(warrantyEndDate);
            const currentDate = new Date();
            const timeDifference = endDate.getTime() - currentDate.getTime();
            const remainingDays = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert milliseconds to days
            return remainingDays >= 0 ? remainingDays : 0;
        },

        uploadAttachFile(e) {
            this.add_attachment.asset_attachment = e.target.files[0]
        },

        handleAttachOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleAttahmentSubmit()
        },

        handleAttahmentSubmit() {
            this.add_attachment.asset_id = this.$route.params.id
            this.$store.dispatch("asset/addAssetAttachment", {
                attachment: this.add_attachment,
                asset_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.add_attachment.requisition_attachment = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable-add-document')
            })
        },
        deleteAttach(id) {
            this.$store.dispatch("asset/deleteAttachmentFromAsset", {
                id: id,
                asset_id: this.$route.params.id,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
        parsedDetails() {
            try {
                let details = JSON.parse(this.asset_details.specification);
                return details

            } catch (error) {
                console.error('Error parsing details:', error);
            }
        },

        deleteAssetAllocation(id) {
            this.asset_allocation_id = id
        },

        handleAssetDeleteOk() {
            this.$store.dispatch("asset/deleteAssetAllocation", {
                id: this.asset_allocation_id,
                asset_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.asset_allocation_id = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center-assetallocation')
            })
        },

        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.asset.image = e.target.result;
            };
        },

        onSubmit(event) {
            event.preventDefault()
            alert(JSON.stringify(this.asset))
        }
    }
};
</script>

<style scoped>
.asset-container {
    display: flex;
    flex-direction: row;
    gap: 32px
}

.image-portion {
    width: 40%;
}

.image-portion img {
    max-height: 400px;
}

.feature-portion {
    width: 60%;
}

@media screen and (max-width: 991px) {
    .asset-container {
        display: flex;
        flex-direction: column;
    }

    .image-portion {
        width: 100%;
    }

    .feature-portion {
        width: 100%;
    }
}

.badge-style {
    display: flex;
    width: fit-content;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}
</style>
