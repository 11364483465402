<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Notes"/>

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                <i class="fa fa-light fa-plus"></i>
                                <h7>Create Note</h7>
                            </button>
                        </div>

                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.item.id}}
                                    </template>
                                  <template #cell(balance_sheet)="data">
                                        {{ data.item.balance_sheet!==null?data.item.balance_sheet.title:"N/A"}}
                                    </template>
                                    <template #cell(title)="data">
                                      <router-link :to="{ path: '/finance-and-accounts/account/note-details/' + data.item.id }">
                                        {{ data.item.title }}
                                      </router-link>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                              <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteItem(data.item.id)" title="Delete"></feather>
                                              <feather @click="editNote(data.item)" type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                        </span>
                                    </template>
                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Create Ledger -->
        <b-modal
            id="modal-prevent-closing"
            ref="modal"
            title="Create Note"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-form-group
                  label="Balance Sheet"
                  label-for="name-input"
                  invalid-feedback="Balance Sheet is required"
              >

                <b-form-select v-model="note.balance_sheet" >
                  <b-form-select-option v-for="option in balanceSheetElements" :value="option.id">{{ option.title }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group
                  label="Title"
                  label-for="name-input"
                  invalid-feedback="Title is required"
              >
                <b-form-input
                    id="name-input"
                    v-model="note.title"
                    placeholder="Enter Note title"
                ></b-form-input>
              </b-form-group>
            </form>
        </b-modal>

        <!-- Edit Ledger -->
        <b-modal
            id="modal-prevent-closing-edit"
            ref="modal"
            title="Edit Note"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleEditOk"
        >
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
              <b-form-group
                  label="Balance Sheet"
                  label-for="name-input"
                  invalid-feedback="Group is required"
              >

                <b-form-select v-model="edit_note.balance_sheet" >
                  <b-form-select-option v-for="option in balanceSheetElements" :value="option.id">{{ option.title }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
                <b-form-group
                    label="Title"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                >
                    <b-form-input
                        id="name-input"
                        v-model="edit_note.title"
                        placeholder="Enter Note title"
                    ></b-form-input>
                </b-form-group>

            </form>
        </b-modal>
      <b-modal
          id="modal-center"
          centered
          hide-header
          @ok="handleDeleteOk"
      >
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>
      </b-modal>

    </div>
</template>

<script>

import {mapState} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'title', label: 'Title', sortable: true },
                { key: 'balance_sheet', label: 'Balance Sheet', sortable: true },
                { key: 'action', label: 'Action', sortable: false }
            ],

            items: [
                // { lid:'LID-101', title: 'Digital Marketing', group: 'Digital Marketing' },
                // { lid:'LID-126', title: 'Front-end', group: 'Front-end' },
                // { lid:'LID-133', title: 'Back-end', group: 'Back-end' }
            ],

            ledger: {
                title: '',
                group: ''
            },
           note: {
            title:"",
            balance_sheet:""
           },
            category_selected: "",

            edit_note: {
              id:"",
              title:"",
              balance_sheet:""

            },
            delete_value:"",

            options: [

                // "Digital Marketing", "Front-end", "Back-end"
            ],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

  computed: {
    ...mapState('account', ["balanceSheetElements","accountNotes", "accountNoteLoading", "accounNoteError", "submitError", "submitSuccess", "submitLoading"]),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  created() {

    this.$store.dispatch('account/fetchBalanceSheetElements')
    this.$store.dispatch('account/fetchAccountNote')
    console.log(this.accountNotes)
    this.items = this.accountNotes
    this.totalRows=this.items.length


  },
  watch: {
    accountGroups(oldValue, newValue) {


    },
    accountCategories(oldValue, newValue) {


    },
    accountLedgers(oldValue, newValue) {


    },
    accountNotes(oldValue, newValue) {

      this.items = this.accountNotes
      this.totalRows=this.items.length
    },


  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },

  methods: {


    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    resetModal() {
      this.ledger.title = ''
      this.ledger.group = ''
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },

    handleEditOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleEditSubmit()
    },

    handleSubmit() {
      this.$store.dispatch("account/addAccountNote", this.note).then(response => {
        if (response.code == 200) {
          Swal.fire({
            title: "Success!",
            text: response.response,
            icon: "success"
          });

          this.note_itle = ''


        } else {
          Swal.fire({
            title: "Error!",
            text: response.response,
            icon: "error"
          });
        }
      })

      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },

    editNote(item) {
      console.log(item)
      this.edit_note.id = item.id
      this.edit_note.title = item.title

    },

    // resetEditModal() {
    //     this.edit_item.name = ""
    //     this.edit_item.id = null
    //     this.edit_item.rate = null
    //     this.edit_item.description = ""
    // },

    handleEditSubmit() {
      this.$store.dispatch("account/updateAccountNote", this.edit_note).then(response => {
        if (response.code == 200) {
          Swal.fire({
            title: "Success!",
            text: response.response,
            icon: "success"
          });


          this.edit_ledger.id = ""
          this.edit_ledger.title = ''
          this.edit_ledger.group = ''
          this.category_selected=""

        } else {
          Swal.fire({
            title: "Error!",
            text: response.response,
            icon: "error"
          });
        }
      })

      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing-edit')
      })
    },

    deleteItem(id) {
      this.delete_value = id
    },

    handleDeleteOk() {
      this.$store.dispatch("account/deleteAccountNote", this.delete_value).then(response => {
        if (response.code == 200) {
          Swal.fire({
            title: "Success!",
            text: response.response,
            icon: "success"
          });

          this.delete_value = null

        } else {
          Swal.fire({
            title: "Error!",
            text: response.response,
            icon: "error"
          });
        }
      })
    }
  }
};
</script>
