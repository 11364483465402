<template>
<div>
    <Breadcrumbs main="HR" title="Time Sheet" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    
                    <div class="card-header d-flex flex-column" style="gap:8px">
                        <h6 class="text-danger">{{ user.user_name }}</h6>
                        <div style="display: flex; flex-direction: row; gap:10px">
                            <h7 class="font-weight-bold">{{ user.department + ','+' '+user.designation }}</h7>  
                        </div>
                        <div>
                            <h6 class="text-info">Total Working Hour:   {{ workingDuration(this.$route.params.time) }}</h6>
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">
                            <b-table show-empty stacked="md" :items="detailSummary" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ (currentPage - 1) * perPage + data.index + 1 }}
                                </template>

                                <template #cell(username)="data">

                                    <router-link :to="`/hr/organization/employee/details/${data.item.user.id}`" class="link-style"> {{ data.item.user_name }} </router-link>

                                </template>

                                <template #cell(punch_in_time)="data">

                                    <div class="d-flex flex-column">
                                        <h7> {{formatDate(data.item.punch_in_time)}}</h7>
                                        <h7> {{formatTime(data.item.punch_in_time)}}</h7>
                                    </div>

                                </template>

                                <template #cell(punch_out_time)="data">

                                    <div class="d-flex flex-column">
                                        <h7> {{formatDate(data.item.punch_out_time)}}</h7>
                                        <h7> {{formatTime(data.item.punch_out_time)}}</h7>
                                    </div>

                                </template>

                                <template #cell(status)="row">
                                    <span v-if="row.value == 'IN'">
                                        <b-badge pill variant="success">{{ row.value }}</b-badge>
                                    </span>
                                    <span v-if="row.value == 'OUT'">
                                        <b-badge pill variant="warning">{{ row.value }}</b-badge>
                                    </span>
                                    <span v-if="row.value == 'absent'">
                                        <b-badge pill variant="danger" class="text-uppercase">{{ row.value }}</b-badge>
                                    </span>

                                </template>

                            </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this Employee?</div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true
                },
                {
                    key: 'punch_in_time',
                    label: 'Punch in Time',
                    sortable: true
                },

                {
                    key: 'punch_out_time',
                    label: 'Punch out Time',
                    sortable: true
                },

                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },

            ],

            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

        };
    },

    computed: {
        ...mapState({
            detailSummary: state => state.punch.detail_time_sheet_summary,
            user: state => state.punch.user_info,

        }),
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },

    },

    mounted() {

        this.$store.dispatch("punch/fetchDetailsTimeSheetByDate_ID", {
            id: this.$route.params.id,
            date: this.$route.params.date
        }, ).then(() => {
            this.items = this.detailSummary
            this.totalRows = this.items.length;
        });
    },

    methods: {
        // formatDateTime(dateTime) {
        //     const date = new Date(dateTime);
        //     const formattedDate = date.toISOString().split('T')[0];
        //     const hours = date.getUTCHours() + 6; // Adjust for GMT+6
        //     const minutes = date.getUTCMinutes();
        //     const seconds = date.getUTCSeconds();
        //     const ampm = hours >= 12 ? 'PM' : 'AM';
        //     const adjustedHours = hours % 12 || 12;
        //     const formattedTime = `${adjustedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
        //     return `${formattedDate} ${formattedTime}`;
        // },

        workingDuration(time) {
            const totalSeconds = parseFloat(time);
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = Math.floor(totalSeconds % 60);

            let formatted_duration;
            if (hours > 0) {
                formatted_duration = `${hours}h ${minutes}m ${seconds}s`;
            } else if (minutes > 0) {
                formatted_duration = `${minutes}m ${seconds}s`;
            } else {
                formatted_duration = `${seconds}s`;
            }

            return formatted_duration;
        },


        formatDate(dateTime) {
            return dateTime.split('T')[0];
        },
        formatTime(dateTime) {
            return dateTime.split('T')[1].split('+')[0].split('.')[0];
        },
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

    }
};
</script>
