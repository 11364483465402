<template>
<div>
    <Breadcrumbs main="HR" title="Organization Settings" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Organization Name</label>
                                        <input class="form-control" type="text" placeholder="Enter asset name" v-model="organization_setting.title">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Abbreviation</label>
                                        <input class="form-control" type="text" placeholder="Enter abbreviation" v-model="organization_setting.abbreviation">
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Phone No.</label>
                                        <input class="form-control" type="text" placeholder="Enter address" v-model="organization_setting.phone">
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Address</label>
                                        <input class="form-control" type="text" placeholder="Enter phone no" v-model="organization_setting.address">
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input class="form-control" type="text" placeholder="Enter email address" v-model="organization_setting.email">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                 <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Upload Logo</label>
                                        <div class="flex items-center">

                                            <img v-if="show_image" style="height: 60px; width: 100px;" :src="show_image">
                                            <input :class="organization_setting.logo ? 'ml-0' : 'ml-0'" type="file" accept="image/*" @change="uploadFile">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Upload Letterpad</label>
                                        <div class="flex items-center">
                                            <img v-if="show_letterpad" style="height: 60px; width: 100px;" :src="show_letterpad">
                                            <input :class="organization_setting.letterpad ? 'ml-0' : 'ml-0'" type="file" accept="image/*" @change="uploadLetterPad">
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                 <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Upload Signature</label>
                                        <div class="flex items-center">

                                            <img v-if="show_signature" style="height: 60px; width: 100px;" :src="show_signature">
                                            <input :class="organization_setting.logo ? 'ml-0' : 'ml-0'" type="file" accept="image/*" @change="uploadSignature">
                                        </div>
                                    </div>
                                </div>
                                

                            </div>



                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Organization Details</label>
                                        <b-form-textarea id="textarea-auto-height" placeholder="Enter organization details..." v-model="organization_setting.details" rows="4" max-rows="13"></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button v-if="setting == null" @click="onSubmit()" class="btn btn-success mr-3">Create</button>

                                        <button v-else @click="onSubmit()" class="btn btn-primary mr-3">Update</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </px-card>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div class="col-md-6">
                                <div class="content-container-attahment w-100">
                                    <div class="">
                                        <h6>Supporting Documents</h6>
                                        <button class="btn btn-primary" v-b-modal.modal-scrollable-add-document>Add attachment</button>

                                      <div v-if="setting.org_attach">
                                        <b-table show-empty sticky-header :items="setting.org_attach.slice(0,3)" :fields="Attachment" head-variant="light" v-if="setting.org_attach.length>0" class="my-4">
                                          <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                          </template>

                                          <template #cell(title)="data">
                                            <a :href="host+data.item.org_attachment" target="_blank">{{ truncateText(data.item.attach_title, 100) }}</a>
                                          </template>
                                          <template #cell(action)="data">

                                            <div style="display: flex; flex-direction: row; gap:10px">

                                              <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                            </div>
                                          </template>
                                        </b-table>

                                      </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </px-card>
            </div>
        </div>
    </div>

    <!-- Project Attachment Create -->
    <b-modal id="modal-scrollable-add-document" ref="modal" title="Add document" @ok="handleAttachOk">
        <form ref="form" @submit.stop.prevent="handleAttahmentSubmit">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload Documents</label>
                        <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>

    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
    myHost
} from '../../../../_helpers/host'
import {
    mapState
} from 'vuex';
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,

    },

    data() {
        return {
            organization_setting: {
                title: null,
                abbreviation: null,
                details: null,
                address: null,
                logo: null,
                letterpad: null,
                authorized_signature:null,
                phone: null,
                email: null,

            },
            show_image: null,
            show_letterpad:null,
            show_signature:null,

            host: myHost.host,

            add_attachment: {
                org_attachment: null,
                org_id: '',
            },

            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                },

            ],
        };
    },
    watch: {
        setting(oldValue, newValue) {
            this.organization_setting.title = this.setting.title
            this.organization_setting.abbreviation = this.setting.abbreviation
            this.organization_setting.details = this.setting.details
            this.organization_setting.address = this.setting.address
            this.organization_setting.email = this.setting.email
            this.organization_setting.phone = this.setting.phone

            this.show_image =this.setting.logo? this.host + this.setting.logo : null
            this.show_letterpad =this.setting.letterpad?  this.host+ this.setting.letterpad : null
            this.show_signature =this.setting.authorized_signature?  this.host+ this.setting.authorized_signature : null

        },
    },
    mounted() {
        this.$store.dispatch("organization/fetchOrganizationSettings")
    },
    computed: {
        ...mapState({
            setting: state => state.organization.organization_setting,
        })

    },
    methods: {
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },

        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.organization_setting.logo = e.target.result;

            };
        },

        uploadLetterPad(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_letterpad = e.target.result
                this.organization_setting.letterpad = e.target.result;

            };
        },

        uploadSignature(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_signature = e.target.result
                this.organization_setting.authorized_signature = e.target.result;

            };
        },



        onSubmit() {

            this.$store.dispatch("organization/addOrganizationSetting", this.organization_setting).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                        this.organization_setting.title = null,
                        this.organization_setting.abbreviation = null,
                        this.organization_setting.details = null,
                        this.organization_setting.address = null,
                        this.organization_setting.logo = null
                        this.organization_setting.letterpad = null,
                        this.organization_setting.authorized_signature = null,
                        this.organization_setting.email = null,
                        this.organization_setting.phone = null

                    // setTimeout(() => {
                    //     this.$router.push('')
                    // }, 3000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

        },
        handleAttachOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleAttahmentSubmit()
        },

        handleAttahmentSubmit() {
            this.add_attachment.org_id = this.setting.id
            this.$store.dispatch("organization/addOrganizationAttachment", {
                attachment: this.add_attachment,
                org_id: this.setting.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.add_attachment.org_attachment = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable-add-document')
            })
        },
        deleteAttach(id) {
            this.$store.dispatch("organization/deleteAttachmentFromOrganization", {
                id: id,
                org_id: this.$route.params.id,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        uploadAttachFile(e) {
            this.add_attachment.org_attachment = e.target.files[0]

        },
    }
};
</script>
