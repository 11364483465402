<template>
<li class="profile-nav onhover-dropdown p-0 mr-0">
    <div class="media profile-media">

        <img class="b-r-10 w-2 height-2" :src="host+user.user_object.user_image" alt="" style="width:35px" />
        <div class="media-body">
            <span>{{user.user_object.first_name+" "+user.user_object.last_name}}</span>
            <p class="mb-0 font-roboto">
                {{user.user_object.username}} <i class="middle fa fa-angle-down"></i>
            </p>
        </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
<!--        <li>-->
<!--            <router-link :to="{ path: '/user/profile' }">-->
<!--                <feather type="user"></feather><span>My Profile</span>-->
<!--            </router-link>-->
<!--        </li>-->
<!--        <li>-->
<!--            <router-link :to="{ path: '/user/change-password' }">-->
<!--                <feather type="lock"></feather><span>Change Password </span>-->
<!--            </router-link>-->
<!--        </li>-->

        <li>
            <a @click="logout">
                <feather type="log-in"></feather><span>Log out</span>
            </a>
        </li>
    </ul>
</li>
</template>

<script>
import host, { myHost } from '../../_helpers/host';
import firebase from 'firebase';
import UserAuth from '../../auth/js/index';

export default {
    name: 'Profile',
    created() {
        this.user = JSON.parse(localStorage.getItem('user'))
    },
    data() {
        return {
            user: null,
            host:myHost.host,
        }
    },
    methods: {
        logout: function () {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    UserAuth.Logout();
                    this.$router.replace('/auth/login');
                });
        },
    },
};
</script>
<style scoped>
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
    width: 160px;
    top: 46px;
    left: -12px;
    padding: 0 10px;
}
</style>
