<template>
    <div>
        <Breadcrumbs main="My Workspace" title="Trainings"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-col>
                
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
            
                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(training_type)="data">
                                        <span>
                                            {{ data.item.training_type.name }}
                                        </span>
                                    </template>

                                    <template #cell(trainer)="data">
                                        <span>
                                            {{ data.item.trainer.name }}
                                        </span>
                                    </template>

                                    <template #cell(status)="data">
                                        <span v-if="data.item.status == true">
                                            <b-badge pill variant="success">Active</b-badge>
                                        </span>
                                        <span v-if="data.item.status == false">
                                            <b-badge pill variant="danger">Inactive</b-badge>
                                        </span>
                                    </template> 
                                </b-table>
                            </div>
            
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div> 
</template>
  
<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'training_type', label: 'Training Type', sortable: true },
                { key: 'trainer', label: 'Trainer', sortable: true },
                { key: 'start_date', label: 'Start Date', sortable: true },
                { key: 'end_date', label: 'End Date', sortable: true },
                { key: 'status', label: 'Status', sortable: true }
            ],

            filter: null,
    
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        ...mapState({
            items: state => state.training.my_training_list
        }),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        }
    },

    mounted() {
        this.$store.dispatch("training/fetchMyTraining")
        this.totalRows = this.items.length;
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    }
};
</script>
  