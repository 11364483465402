<template>
  <div>
    <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Income Statement Structure"/>

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card" v-for="item in incomeStructure">
            <div class="card-body">
              <div class="col-sm-12">
                <h6>{{ item.title }}</h6>

                <div class="row">
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Category</label>
                      <b-form-select v-model="structure_item.category"
                                     @change="updateGroup(structure_item.category, true)">
                        <b-form-select-option v-for="option in accountCategories" :value="option.id">{{
                            option.title
                          }}
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Group</label>
                      <input class='form-control' type="text" list="groups" v-model="structure_item.group"
                             @change="updateLedgers(structure_item.group, true)"/>
                      <!--                                              <input class='form-contro' type="text" list="ledgers" v-model="debit.ledger_name"/>-->
                      <datalist id="groups">
                        <option v-for="option in groups" :value="option.title">{{ option.title }}</option>
                      </datalist>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Ledger</label>
                      <input class='form-control' type="text" list="ledgers" v-model="structure_item.ledger"/>
                      <datalist id="ledgers">
                        <option v-for="option in ledgers" :value="option.title">{{ option.title }}</option>
                      </datalist>
                    </div>
                  </div>


                  <div class="col-sm-1" style="margin-top: 35px;">
                    <!-- Add Svg Icon -->
                    <svg @click="addField(structure_item.ledger,item.slug)" width="24" height="24"
                         style="cursor: pointer;">
                      <path fill="none" d="M0 0h24v24H0z"/>
                      <path fill="green"
                            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>
                    </svg>

                    <!-- Remove Svg Icon -->

                  </div>
                </div>
                <div class="row">
                  <div class="table-responsive datatable-vue">
                    <b-table
                        show-empty
                        stacked="md"
                        :items="item.ledger"
                        :fields="tablefields"
                        @filtered="onFiltered"
                    >
                      <template #cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(group)="data">

                        {{ data.item.group.title != null ? data.item.group.title : "N/A" }}
                      </template>
                      <template #cell(lid)="data">
                                        <span>
                                            <router-link
                                                :to="{ path: '/finance-and-accounts/account/ledger/' + data.item.lid }">
                                                {{ data.item.lid }}
                                            </router-link>
                                        </span>
                      </template>

<!--                      <template #cell(action)="data">-->
<!--                                        <span style="display: flex; gap: 12px;">-->
<!--                                                <feather type="delete" size="22px"-->
<!--                                                         style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover-->
<!--                                                         v-b-modal.modal-center-->
<!--                                                         @click="deleteItem(data.item.title, 'net_sales')"-->
<!--                                                         title="Delete"></feather>-->
<!--                                                  </span>-->
<!--                      </template>-->
                    </b-table>
                  </div>


                </div>
              </div>
              <br><br><br>
            </div>


          </div>

        </div>
      </div>


    </div>

    <b-modal
        id="modal-center"
        centered
        hide-header
        @ok="handleDeleteOk"
    >
      <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this item?</div>
    </b-modal>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {mapState} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
  components: {
    Datepicker
  },

  data() {
    return {
      tablefields: [
        {key: 'index', label: '#'},
        {key: 'lid', label: 'Ledger ID', sortable: true},
        {key: 'title', label: 'Title', sortable: true},
        {key: 'group', label: 'Group', sortable: true},
        {key: 'group.pre_defined.title', label: 'Pre-Defined Categories', sortable: true},

      ],

      items: [],
      structure_item:
          {
            category: null,
            group: null,
            ledger: null,
            amount: null,
            is_debit: true
          },
      groups: [],
      ledgers: [],
      delete_value: "",
      delete_slug: "",
      // options: ["Digital Marketing", "Front-end", "Back-end"],
      //
      filter: "",

      totalRows: 1,
      currentPage: 2,
      perPage: 10,
      pageOptions: [5, 10, 15]
    };
  },

  computed: {
    ...mapState('account', ["incomeStructure", "incomeStructureLoading", "incomeStructureError", "accountCategories", "accountGroups", "accountLedgers", "accountLedgerLoading", "accountLedgerError", "accountGroupLoading", "accountGroupError", "accountCategoryLoading", "accountCategoryError", "submitError", "submitSuccess", "submitLoading"]),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  created() {
    this.$store.dispatch('account/fetchIncomeStructure').then(result => {
      this.items = this.incomeStructure
      this.totalRows = this.items.length
    })
    this.$store.dispatch('account/fetchAccountCategory')
    this.$store.dispatch('account/fetchAccountGroup').then(result => {
      this.groups = this.accountGroups

    })
    this.$store.dispatch('account/fetchAccountLedger').then(result => {
      this.ledgers = this.accountLedgers

    })


  },
  watch: {
    accountCategories(oldValue, newValue) {

      // this.options = this.accountCategories

    },
    incomeStructure(oldValue, newValue) {

      this.items = this.incomeStructure
      this.totalRows = this.items.length

    },
    accountLedgers(oldValue, newValue) {

      // this.options = this.accountCategories

    },

    accountGroups(oldValue, newValue) {

      // this.items = this.accountGroups

    },


  },


  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

  },

  methods: {
    updateGroup(id) {
      console.log("category_id", id)

      this.$store.dispatch('account/fetchAccountGroupByCategory', id).then(result => {

        this.groups = this.accountGroups

      })

    },
    updateLedgers(id) {
      console.log(id)
      this.$store.dispatch('account/fetchAccountLedgerByGroup', id).then(result => {

        this.ledgers = this.accountLedgers

      })

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 2;
    },

    addField(value, slug) {
      console.log(value, slug)
      this.$store.dispatch("account/addLedgerToELement", {slug: slug, ledger_title: value}).then(response => {
        if (response.code == 200) {
          this.structure_item =
              {
                category: null,
                group: null,
                ledger: null,
                amount: null,
                is_debit: true
              },
              Swal.fire({
                title: "Success!",
                text: response.response,
                icon: "success"
              });
        } else {
          Swal.fire({
            title: "Error!",
            text: response.response,
            icon: "error"
          });
        }

      })
    },
    getLedgers(slug) {
      let index = this.incomeStructure.map(object => object.slug).indexOf(slug)
      return this.incomeStructure[index].ledger
    },


    deleteItem(title, slug) {
      console.log(title, slug)
      this.delete_value = title
      this.delete_slug = slug
    },

    handleDeleteOk() {
      this.$store.dispatch("account/deleteLedgerToELement", {
        slug: this.delete_slug,
        ledger_title: this.delete_value
      }).then(response => {
        if (response.code == 200) {
          Swal.fire({
            title: "Success!",
            text: response.response,
            icon: "success"
          });

          this.delete_value = null

        } else {
          Swal.fire({
            title: "Error!",
            text: response.response,
            icon: "error"
          });
        }
      })
    }
  }
};
</script>
