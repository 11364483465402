<template>
    <div>
        <Breadcrumbs main="HR" submain="My Workspace" title="My Assets"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">

                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    :items="my_asset"
                                    :fields="tablefields"
                                    :filter="filter"
                                    :current-page="currentPage"
                                    :per-page="perPage"
                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template #cell(asset_name)="data">

                                        <span>
                                            <router-link :to="`/my-workspace/my-asset/details/${data.item.asset.id}`" class="link-style">
                                                 {{data.item.asset.name}}
                                            </router-link>
                                        </span>
                                    </template>
                                </b-table>
                            </div>

                            <b-col md="6" class="my-1 p-0">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    class="my-0"
                                ></b-pagination>
                            </b-col>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },

    data() {
        return {
            tablefields: [
                { key: 'index', label: '#' },
                { key: 'asset_name', label: 'Asset', sortable: true },
              
                { key: 'asset.asset_id', label: 'Asset ID', sortable: true },
               
                { key: 'allocation_date', label: 'Allocation Date', sortable: true }
            ],

            // items: [
            //     { name: 'Laptop', allocation_date: '2023-07-05', asset_id: 'AST-000001' },
            //     { name: 'Chair', allocation_date: '2023-08-08', asset_id: 'AST-000003' },
            //     { name: 'Mac Mini', allocation_date: '2024-02-21', asset_id: 'AST-000004' }
            // ],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                return {text: f.label, value: f.key};
            });
        },
        ...mapState({
            my_asset: state => state.asset.get_my_asset,

        }),


    },

    mounted() {
        this.$store.dispatch("asset/fetchMyAsset").then(() => {
            this.items = this.my_asset
            this.totalRows = this.items.length;
        });


    },

    methods: {
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    }
};
</script>
