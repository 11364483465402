import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";

const keyList = {
    smsUpdate : 'TRAINING_UPDATE',
    smsDelete : 'TRAINING_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        training_type: [],
        trainer:[],
        training_list:[],
        my_training_list:[],

        trainingLoading: false,
        trainingError: false,
        trainingsubmitSuccess:false,

        ...store
    },
    
    mutations : {
        ...mutations,

        SET_TRAINING_TYPE (state, training) {
            state.training_type = training;
        },

        SET_TRAINER(state, trainer){
            state.trainer = trainer;
        },

        SET_TRAINING_LIST(state, training){
            state.training_list = training;
        },

        SET_MY_TRAINING_LIST(state, training){
            state.my_training_list = training;
        },

        TRAINING_LOADING (state, loading) {
            state.trainingLoading = loading;
        },

        TRAINING_ERROR (state, loading) {
            state.trainingError = loading;
        },

        TRAINING_SUBMIT_SUCCESS (state, success) {
            state.trainingsubmitSuccess = success;
        }
    },

    actions: {
        //Training Type
        async addTrainingType ({commit, dispatch}, payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_training_type, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('TRAINING_SUBMIT_SUCCESS', true);
                dispatch ('fetchTrainingType')
                return response

            }catch (e) {
                commit('TRAINING_ERROR', true);
            }finally {
                commit('TRAINING_LOADING', false);
            }
        },

        async fetchTrainingType ({ commit }) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_training_type, config);
                let response = result.data
                commit('SET_TRAINING_TYPE', response.data);

            } catch(error) {
                commit('TRAINING_ERROR', true);
            }
            commit('TRAINING_LOADING', false);
        },

        async updateTrainingType ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.update_training_type+payload.id,
                        {
                             name:payload.name, 
                             description:payload.description, 
                             status:payload.status,

                        },config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchTrainingType');
                }
            )
            return response
        },

        async deleteTrainigType ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_training_type + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchTrainingType');
            return response
        },

        async updateTrainingTypeStatus ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.toggle_training_type_status + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchTrainingType');
            return response
        },

        //Trainers
        async addTrainer ({commit, dispatch}, payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_trainer, payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('TRAINING_SUBMIT_SUCCESS', true);
                dispatch ('fetchTrainer')
                return response

            }catch (e) {
                commit('TRAINING_ERROR', true);
            }finally {
                commit('TRAINING_LOADING', false);
            }
        },

        async fetchTrainer ({ commit }) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_all_trainer, config);
                let response = result.data
                commit('SET_TRAINER', response.data);

            } catch(error) {
                commit('TRAINING_ERROR', true);
            }
            commit('TRAINING_LOADING', false);
        },


        async updateTrainer({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.update_trainer+payload.id,
                        {
                             name:payload.name, 
                             contact_number:payload.contact_number,
                             email:payload.email,
                             description:payload.description ,
                             status:payload.status,

                        },config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchTrainer');
                }
            )
            return response
        },
        async deleteTrainer ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_trainer + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchTrainer');
            return response
        },

        async updateTrainerStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.toggle_trainer_status + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchTrainer');
            return response
        },


        //Training
        async addTraining ({commit, dispatch}, payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_training, payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('TRAINING_SUBMIT_SUCCESS', true);
                dispatch ('fetchTraining')
                return response

            }catch (e) {
                commit('TRAINING_ERROR', true);
            }finally {
                commit('TRAINING_LOADING', false);
            }
        },

        async fetchTraining ({ commit }) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_training_lits, config);
                let response = result.data
                commit('SET_TRAINING_LIST', response.data);

            } catch(error) {
                commit('TRAINING_ERROR', true);
            }
            commit('TRAINING_LOADING', false);
        },

        async updateTraining({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.update_training+payload.id,
                        {
                    training_type: payload.training_type,
                    trainer: payload.trainer,
                    cost:payload.cost,
                    start_date:payload.start_date,
                    end_date:payload.end_date,
                    status:payload.status

                        },config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchTraining');

                }
            )
            return response
        },

        async deleteTraining ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_training + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchTraining');
            return response
        },

        async updateTrainingStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.toggle_training_status + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchTraining');
            return response
        },

        async assignEmployee ({commit,dispatch}, payload) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.assign_employee_training, payload, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('TRAINING_SUBMIT_SUCCESS', true);
                dispatch('fetchTraining');
                return response

            }catch (e) {
                commit('TRAINING_ERROR', true);
            }finally {
                commit('TRAINING_LOADING', false);
            }
        },

        async deleteAssignedEmployee ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_assign_employee + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchTraining');
            return response
        },

        async fetchMyTraining ({ commit }) {
            commit('TRAINING_LOADING', true);
            commit('TRAINING_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_my_trainings, config);
                let response = result.data
                commit('SET_MY_TRAINING_LIST', response.data);

            } catch(error) {
                commit('TRAINING_ERROR', true);
            }
            commit('TRAINING_LOADING', false);
        }
    },

    getters: {}
}
