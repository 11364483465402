<template>
<div>
    <Breadcrumbs main="My Profile" title="Profile Edit" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Employee First Name</label>
                                        <input class="form-control" type="text" placeholder="Enter employee first name" v-model="temp_employee.first_name">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label>Employee Last Name</label>
                                            <input class="form-control" type="text" placeholder="Enter employee last name" v-model="temp_employee.last_name">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Employee Email</label>
                                        <input class="form-control" type="text" placeholder="Enter employee first name" v-model="temp_employee.email">
                                    </div>
                                </div>

                            </div>
                            <h6 class="my-4">Additional Information</h6>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Employee Phone No.</label>
                                        <input class="form-control" type="text" placeholder="Enter phone no" v-model="temp_employee.phone_number">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Joining Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="temp_employee.joining_date" disabled ></datepicker>
                                    </div>
                                </div>

                            </div>


                            <div class="row">
                            
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Department</label>

                                        <select class="form-control digits" v-model="temp_employee.department" disabled>
                                            <option v-for="(type, index) in department_list" :key="index" :value="type.id">{{ type.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Designation</label>
                                        <select class="form-control digits" v-model="temp_employee.designation" disabled>
                                            <option v-for="(type, index) in designation_list_by_department" :key="index" :value="type.id">{{ type.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Personal ID No.</label>
                                        <input class="form-control" type="text" placeholder="Enter employee first name" v-model="temp_employee.personal_id_no">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Address</label>
                                        <input class="form-control" type="text" placeholder="Enter employee first name" v-model="temp_employee.address">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Blood Group</label>
                                        <input class="form-control" type="text" placeholder="Enter employee first name" v-model="temp_employee.blood_group">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Date of Birth</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="temp_employee.date_of_birth"></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col">
                                    <label>Upload Photo (Ratio: 1:1, Max-size: 3MB)</label>
                                    <div class="flex items-center">
                                        <!-- {{ temp_employee.photo }} -->
                                        <img v-if="show_image" style="height: 60px; width: 100px;" :src="show_image">
                                        <input :class="temp_employee.photo ? 'ml-4' : 'ml-0'" type="file" accept="image/*" @change="uploadFile">
                                    </div>
                                </div>
                            </div>

                            <div class="row my-5">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="UpdateEmployee()" class="btn btn-success mr-3">Update</button>
                                        <router-link to="/hr/organization/employee">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>

    <!-- Container-fluid Ends-->
</div>
</template>

    
<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import Multiselect from 'vue-multiselect';
import {
    myHost
} from "../../../_helpers/host";
import {
    mapState
} from 'vuex';

export default {

    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            show_image: null,
            temp_employee: {
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                blood_group: '',
                joining_date: '',
                department: '',
                designation: '',
                address: '',
                personal_id_no: '',
                date_of_birth: '',
                photo: null,

            },

        };
    },

    methods: {
        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.temp_employee.photo = e.target.result;
                this.show_image = e.target.result;
            };
        },

        UpdateEmployee() {
            if (this.temp_employee.joining_date !== null) {
                this.temp_employee.joining_date = moment(String(this.temp_employee.joining_date)).format("YYYY-MM-DD");
            }
            if (this.temp_employee.date_of_birth !== null) {
                this.temp_employee.date_of_birth = moment(String(this.temp_employee.date_of_birth)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("employee/updateEmployee", {
                id: this.$route.params.id,
                info: this.temp_employee
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.temp_employee.first_name = '',
                        this.temp_employee.last_name = '',
                        this.temp_employee.email = '',
                        this.temp_employee.blood_group = '',
                        this.temp_employee.joining_date = null,
                        this.temp_employee.department = '',
                        this.temp_employee.designation = '',
                        this.temp_employee.address = '',
                        this.temp_employee.personal_id_no = '',
                        this.temp_employee.date_of_birth = null,
                        this.temp_employee.photo = null

                    setTimeout(() => {
                        this.$router.push('/user/profile')
                    }, 3000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    },
    computed: {
        ...mapState({
            department_list: state => state.department.departments,
            designation_list: state => state.designation.designation,
            details: state => state.employee.employee_detail,
            designation_list_by_department: state => state.designation.designation_by_department,
        }),

    },
    watch: {
        details(oldValue, newValue) {
            this.temp_employee.first_name = this.details.user_object.first_name
            this.temp_employee.last_name = this.details.user_object.last_name
            this.temp_employee.email = this.details.user_object.email
            this.temp_employee.blood_group = this.details.additional_info.blood_group
            this.temp_employee.joining_date = this.details.additional_info.joining_date
            this.temp_employee.department = this.details.additional_info.department.id
            this.temp_employee.designation = this.details.additional_info.designation.id
            this.temp_employee.address = this.details.additional_info.address
            this.temp_employee.personal_id_no = this.details.additional_info.personal_id_no
            this.temp_employee.phone_number = this.details.additional_info.phone_number
            this.temp_employee.date_of_birth = this.details.additional_info.date_of_birth
            this.show_image = this.host + this.details.additional_info.photo

        },
        'temp_employee.department': function (newDepartmentId, oldDepartmentId) {

            if (newDepartmentId) {
                this.$store.dispatch("designation/fetchDesignationsbyDepartment", newDepartmentId);
            }
        }
    },
    mounted() {
        this.$store.dispatch("department/fetchDepartments")
        this.$store.dispatch("designation/fetchDesignations")
        this.$store.dispatch("employee/fetchEmployeeDetail", this.$route.params.id)

    },

};
</script>

    
<style scoped>
.task-board {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}

::-webkit-scrollbar {
    height: 5px;
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
