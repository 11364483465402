<template>
<div>
    <Breadcrumbs main="HR" submain="Asset Management" title="Assets" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header" style="max-width: fit-content">
                        <router-link to="/hr/asset-management/asset/create">
                            <button class="btn btn-primary" type="submit" style="display: flex; align-items: center;">
                                <div style="display: flex; gap: 12px; align-items: center; color: white;">
                                    <i class="fa fa-light fa-plus"></i>
                                    <h7>Add New Asset</h7>
                                </div>
                            </button>
                        </router-link>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">
                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">
                            <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template #cell(name)="data">
                                    <span>
                                        <router-link :to="`/hr/asset-management/asset/details/${data.item.id}`" class="link-style">
                                            {{ data.item.name }}
                                        </router-link>
                                    </span>
                                </template>

                                <template #cell(warranty)="data">
                                    <span>
                                        {{ data.item.warranty }} {{ data.item.warranty_type }}
                                    </span>
                                </template>

                                <template #cell(status)="data">
                                    <span  v-if="data.item.status == 'PENDING'">
                                        <b-badge pill variant="warning" >{{ data.item.status }}</b-badge>
                                    </span>
                                    <span  v-if="data.item.status == 'APPROVED'">
                                        <b-badge pill variant="success" >{{ data.item.status }}</b-badge>
                                    </span>
                                    <span  v-if="data.item.status == 'RETURNED'">
                                        <b-badge pill variant="danger">{{ data.item.status }}</b-badge>
                                    </span>
                                </template>

                                <template #cell(allocated_users)="data">
                                    <span>
                                        <div v-if="data.item.allocated_users !== null && data.item.allocated_users.length > 0" v-b-modal.modal-prevent-closing title="Allocate Employee" @click="getAsset(data.item)">
                                            <div v-for="user in data.item.allocated_users" :key="user.id">
                                                <p>{{ user.asset_user.first_name + ' ' + user.asset_user.last_name }}</p>
                                            </div>
                                        </div>
                                        <div v-else v-b-modal.modal-prevent-closing title="Allocate Employee" @click="getAsset(data.item)">
                                            <b-badge pill variant="primary">Allocate Asset</b-badge>
                                        </div>
                                    </span>
                                </template>

                                <template #cell(action)="data">
                                    <span style="display: flex; gap: 12px;">
                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteAsset(data.item.id)" title="Delete"></feather>
                                        <div>
                                            <router-link :to="`/hr/asset-management/asset/edit/${data.item.id}`">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                            </router-link>
                                        </div>
                                    </span>
                                </template>
                            </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Allocate Employee Modal -->
    <b-modal id="modal-prevent-closing" hide-footer title="Allocate Employee">
        <div style="display: flex; flex-direction: column; gap: 15px;">
            <div v-if="this.asset.allocated_users !== null">
                <div class="row">
                    <div class="text-center col-sm-6 w-full" v-for="user in asset.allocated_users" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-assetallocation @click="deleteAssetAllocation(user.id)" title="Delete">
                        <div class="w-100 mb-2 badge-style">
                            <h7>{{ user.asset_user.first_name + ' ' + user.asset_user.last_name }}</h7>
                            <b-icon icon="x" style="color: white;"></b-icon>
                        </div>
                    </div>
                </div>
            </div>

            <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="user_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
            <div class="d-flex justify-content-end ">
                <button @click="assignMember" class="btn btn-primary" style="width: 120px;">Allocate</button>
            </div>
        </div>
    </b-modal>

    <!-- Asset Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this asset?</div>
    </b-modal>

    <!-- Asset Allocate Delete Modal -->
    <b-modal id="modal-center-assetallocation" centered hide-header @ok="handleAssetDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this asset allocation?</div>
    </b-modal>
</div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Multiselect from 'vue-multiselect';
import { mapState } from 'vuex';

export default {
    components: {
        Multiselect
    },

    data() {
        return {
            member: [],
            tablefields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'name',
                    label: 'Asset',
                    sortable: true
                },
                {
                    key: 'asset_type.name',
                    label: 'Asset Type',
                    sortable: true
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    sortable: true
                },
                {
                    key: 'purchase_date',
                    label: 'Purchase Date',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'allocated_users',
                    label: 'Allocated User',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            delete_value: null,
            asset: {},
            asset_id: null,
            asset_allocation_id: null,

            statuses: ['Active', 'Inactive'],
            selectedStatus: '',

            assignedMembers: [],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
            .filter(f => f.sortable)
            .map(f => {
                return {
                    text: f.label,
                    value: f.key
                };
            });
        },

        ...mapState({
            items: state => state.asset.asset,
            user_list: state => state.employee.employee_list
        })
    },

    mounted() {
        this.totalRows = this.items.length;
        this.$store.dispatch("asset/fetchAsset");
        this.$store.dispatch("employee/fetchEmployeeList");
    },

    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        assignMember() {
            const idArray = this.assignedMembers.map(item => this.member.push({
                asset_user: item.id,
                asset: this.asset_id
            }));

            this.$store.dispatch("asset/assetAllocate", this.member).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.assignedMembers = [],
                    this.member = []

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        deleteAsset(id) {
            this.delete_value = id
        },

        deleteAssetAllocation(id) {
            console.log(id)
            this.asset_allocation_id = id
        },

        getAsset(asset) {
            this.asset_id = asset.id,
            this.asset = asset
        },

        handleDeleteOk() {
            this.$store.dispatch("asset/deleteAsset", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

        handleAssetDeleteOk() {
            this.$store.dispatch("asset/deleteAssetAllocation", {
                id: this.asset_allocation_id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.asset_allocation_id = null
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-prevent-closing') 
                    })

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-center-assetallocation') 
            })
        }
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;
}

.badge-style:hover {
    background-color: #ff595d;
}
</style>
