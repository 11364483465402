import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'QUOTATION_UPDATE',
    smsDelete : 'QUOTATION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        quotation: [],
        quotation_detail: {},

        projectLoading: false,
        projectError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_QUOTATION (state, project) {
            state.quotation = project;
        },

        SET_QUOTATION_DETAIL (state, project) {
            state.quotation_detail = project;
        },

        QUOTATION_LOADING (state, loading) {
            state.projectLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateQuotation ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try {

                payload.quotation.details=JSON.stringify(payload.quotation.details)
                console.log(payload.quotation.details)

                let data = new FormData()
                let payloadData = buildFormData(data, payload.quotation);

                let response = await axios.patch(V1API.edit_quotation + payload.id, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchQuotation')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteQuotation ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_quotation + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchQuotation');

            return response
        },

        async fetchQuotation ({ commit }) {
            commit('QUOTATION_LOADING', true);
            commit('QUOTATION_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_quotation, config);
                let response = result.data

                commit('SET_QUOTATION', response.data);

            } catch(error) {
                commit('QUOTATION_ERROR', true);
            }
            commit('QUOTATION_LOADING', false);
        },

        async fetchQuotationDetail ({ commit }, payload) {
            commit('QUOTATION_LOADING', true);
            commit('QUOTATION_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_quotation_details + payload, config);
                let response = result.data

                commit('SET_QUOTATION_DETAIL', response.data);

            } catch(error) {
                commit('QUOTATION_ERROR', true);
            }
            commit('QUOTATION_LOADING', false);
        },

        async addQuotation ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{



                payload.details=JSON.stringify(payload.details)
                console.log(payload.details)


                let data2= new FormData()
                let payloadData = buildFormData(data2, payload);
                // let magic = encode(payloadData)

                let response = await axios.post(V1API.create_quotation, payloadData, config).then(result=>{
                    // let data = decode(result.data)
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchQuotation')
                return response

            }catch (e) {
                console.log(e)
                commit('SUBMIT_ERROR', true);
            }finally {
               
                commit('SUBMIT_LOADING', false);
            }
        },
        async addQuotationAttachment ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };
            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.attachment);
        
                let response = await axios.post(V1API.quotation_add_attachment, payloadData, config).then(result=>{
                  
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch("fetchQuotationDetail",payload.quotation_id)
                return response

            }catch (e) {
               
                commit('SUBMIT_ERROR', true);
            }finally {
             
                commit('SUBMIT_LOADING', false);
            }
        },
        async deleteAttachmentFromQuotation ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.quotation_delete_attachment + payload.id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchQuotationDetail',payload.quotation_id)
            
            return response
        },
    },

    getters: {}
}
