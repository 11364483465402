<template>
<div>
    <Breadcrumbs main="My Workspace" submain="Ticket" title="Raise a Ticket" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">

        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Ticket Details</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter ticket subject..." rows="4" v-model="ticket.subject"></textarea>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Refer To</label>
                                        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="handleSubmit" class="btn btn-success mr-3">Submit</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </px-card>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <!-- Ticket Create Modal -->
                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="table-responsive datatable-vue">

                            <b-table show-empty stacked="md" :items="ticket_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ (currentPage - 1) * perPage + data.index + 1 }}
                                </template>
                                <template #cell(ticket_id)="data">
                                    <div @click="TicketDetails(data.item)" class="link-style">
                                        <h7 v-b-tooltip.hover title="Details" v-b-modal.modal-prevent-closing-edit>{{data.item.ticket_id}}</h7>
                                    </div>
                                </template>

                                <template #cell(status)="row">
                                    <span v-if="row.value == 'CLOSED'">
                                        <b-badge pill variant="warning">{{ row.value }}</b-badge>
                                    </span>
                                    <span v-if="row.value == 'OPEN'">
                                        <b-badge pill variant="success">{{ row.value }}</b-badge>
                                    </span>
                                </template>

                                <template #cell(priority)="row">
                                    <span @click="getPriority(row.value, row.item.id)" v-if="row.value == 'LOW'">
                                        <b-badge v-b-modal.modal-prevent-closing-priority pill variant="info" v-b-tooltip.hover title="Update Priority">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getPriority(row.value, row.item.id)" v-if="row.value == 'MEDIUM'">
                                        <b-badge v-b-modal.modal-prevent-closing-priority pill variant="warning" v-b-tooltip.hover title="Update Priority">{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getPriority(row.value, row.item.id)" v-if="row.value == 'HIGH'">
                                        <b-badge v-b-modal.modal-prevent-closing-priority pill variant="danger" v-b-tooltip.hover title="Update Priority">{{ row.value }}</b-badge>
                                    </span>

                                </template>

                                <template #cell(refer_to)="data">
                                    <span>
                                        <div v-if="data.item.refer_to !== null && data.item.refer_to.length > 0">
                                            <div v-for="user in data.item.refer_to" :key="user.id">
                                                <p>{{ user.first_name }}</p>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p>--</p>
                                        </div>
                                    </span>
                                </template>
                                <template #cell(action)="data">
                                    <span style="display: flex; gap: 12px;">
                                        <div>
                                            <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(data.item)" title="Reply"></feather>
                                        </div>
                                    </span>
                                </template>

                            </b-table>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <b-modal id="modal-scrollable" ref="modal" title="Ticket Reply" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleOk">

        <h6>Already Refered</h6>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ user.first_name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>
        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
        <div style="margin-top: 30px;" v-if="ticket_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in ticket_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..." @keyup.enter="handleReplySubmit()">
                <button @click="handleReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>

    <!--  Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refere?</div>
    </b-modal>

    <!-- Update Priority -->
    <b-modal id="modal-prevent-closing-priority" ref="modal" title="Update Ticket Priority" @show="resetModal" @hidden="resetModal" @ok="handlePriorityOk">
        <form ref="form" @submit.stop.prevent="handlePrioritySubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedPriority" :options="priorities"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Ticket Detail Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Ticket Details" hide-footer>
        <form ref="form" @submit.stop.prevent="handleEditSubmit">

            <div class="row">
                <div class="col">
                    <b-form-group label="Raised By" label-for="name-input">
                        <b-form-input v-model="fullName" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>

            </div>
            <b-form-group label="Reason" label-for="name-input">
                <b-form-input v-model="ticket_details.subject" placeholder="Enter holiday title" disabled></b-form-input>
            </b-form-group>

            <div class="row">
                <div class="col-sm-4">
                    <b-form-group label="Created Date" label-for="name-input">
                        <datepicker placeholder="Select start date" input-class="datepicker-here form-control digits" v-model="ticket_details.created_date" disabled></datepicker>
                    </b-form-group>
                </div>

                <div class="col-sm-4">
                    <b-form-group label="Status" label-for="name-input">
                        <b-form-input v-model="ticket_details.status" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-sm-4">
                    <b-form-group label="Priority" label-for="name-input">
                        <b-form-input v-model="ticket_details.priority" placeholder="" disabled></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <div class="text-center" style="margin-top: 10px;">
                <h6>Reffered To</h6>
            </div>
            <div class="row">
                <div class="text-center col-sm-6 w-full" v-for="user in ticket_details.refer_to" :key="user.id">
                    <div class="w-100 mb-2 badge-style">
                        <h7>{{ user.first_name }}</h7>
                    </div>
                </div>
            </div>

            <!-- <b-form-textarea id="textarea-rows" placeholder="Tall textarea" rows="8" v-model="edit_holiday.notice"></b-form-textarea> -->
        </form>
    </b-modal>

</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    myHost
} from '../../../../_helpers/host';

export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'ticket_id',
                    label: 'Ticket ID',
                    sortable: true
                },
                {
                    key: 'created_date',
                    label: 'Ticket Date',
                    sortable: true
                },
                {
                    key: 'subject',
                    label: 'Subject',
                    sortable: true
                },

                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'priority',
                    label: 'Priority',
                    sortable: true
                },
                {
                    key: 'refer_to',
                    label: 'Reffered To',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }

            ],
            ticket_details: {
                id: '',
                created_date: '',
                subject: '',
                status: '',
                priority: '',
                refer_to: '',
                raised_by: '',
            },
            update_priority_id: '',
            selectedPriority: '',
            priorities: ['HIGH', 'MEDIUM', 'LOW'],

            ticket: {
                subject: '',
                refer_to: null,
            },
            assignedMembers: [],

            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            temp_id: '',

            statuses: ['NEW', 'PENDING', 'APPROVED', 'DONE'],
            priorities: ['HIGH', 'MEDIUM', 'LOW'],

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },
    watch: {
        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        }
    },

    computed: {
        ...mapState({
            user_list: state => state.employee.employee_list,
            ticket_list: state => state.ticket.my_ticket_list,
            ticket_reply: state => state.ticket.ticket_reply,

        }),
        fullName() {
            if (this.ticket_details.raised_by) {
                return this.ticket_details.raised_by.first_name + ' ' + this.ticket_details.raised_by.last_name;
            } else {
                return '';
            }
        },
        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("ticket/fetchMyTicket").then(() => {
            this.items = this.ticket_list
            this.totalRows = this.items.length;
        });

    },

    methods: {
        TicketDetails(type) {
            this.ticket_details.id = type.id
            this.ticket_details.created_date = type.created_date
            this.ticket_details.status = type.status
            this.ticket_details.priority = type.priority
            this.ticket_details.subject = type.subject
            this.ticket_details.refer_to = type.refer_to
            this.ticket_details.raised_by = type.raised_by
        },
        convertUrls(text) {
            const urlPattern = /https?:\/\/[^\s]+/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },
        getPriority(priority, id) {
            this.update_priority_id = id
            this.selectedPriority = priority
        },
        deletetAllocation(id) {
            this.temp_id = id
        },
        handleAllocationDeleteOk() {
            this.$store.dispatch("ticket/ticketDeleteRefer", {
                id: this.my_reply.id,
                refer_id: this.temp_id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },
        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("ticket/ticketAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })

        },
        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.refer_to
            this.$store.dispatch("ticket/fetchTicketReply", this.my_reply.id)
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.ticket.subject = ''
            this.ticket.refer_to = null

        },

        // handleOk(bvModalEvent) {
        //     bvModalEvent.preventDefault()
        //     this.handleSubmit()
        // },

        handleSubmit() {
            if (!Array.isArray(this.ticket.refer_to)) {
                this.ticket.refer_to = [];
            }
            this.assignedMembers.forEach(item => {
                this.ticket.refer_to.push(item.id);
            })

            this.$store.dispatch("ticket/addTicketMyEnd", this.ticket).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = []
                    this.ticket.subject = ''
                    this.ticket.refer_to = []

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleReplySubmit()

        },

        handleReplySubmit() {

            this.$store.dispatch("ticket/addTicketReply", {
                ticket: this.my_reply.id,
                reply: this.my_reply.reply
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.my_reply.id = '',
                        this.my_reply.reply = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },
        handlePriorityOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handlePrioritySubmit()
        },

        handlePrioritySubmit() {
            this.$store.dispatch("ticket/updateTicketPriority", {
                id: this.update_priority_id,
                priority: this.selectedPriority
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-priority')
            })
        },

    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}

.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1;
}

.msg {
    background: #fff0f0;
    width: 100%;
    /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}

.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
