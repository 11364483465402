<template>
<div>
    <Breadcrumbs main="Project" title="Projects" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="row m-0">
                        <div class="col-sm-12 p-0">
                            <b-tabs content-class="mt-3" v-model="tabIndex">
                                <b-tab title="All" :title-link-class="linkClass(0)">
                                    <template v-slot:title>
                                        <div @click="categoryType('ALL')">
                                            <feather type="target" class="tab-title"></feather> All
                                        </div>

                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row" v-if="allprojects.length>0">

                                                        <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                    <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                        <h6 class="box">{{ project.title }}</h6>
                                                                    </router-link>
                                                                    <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                        <router-link :to="{ path: '/project/edit/' + project.id }">
                                                                            <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                        </router-link>
                                                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(project.id)" title="Delete"></feather>
                                                                    </div>
                                                                </div>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width':   projectDoneTaskPercentage(project)+ '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Approved" :title-link-class="linkClass(1)">
                                    <template v-slot:title>
                                        <div @click="categoryType('APPROVED')">
                                            <feather type="disc"></feather> Approved
                                        </div>

                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row" v-if="allprojects.length>0">

                                                        <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                    <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                        <h6 class="box">{{ project.title }}</h6>
                                                                    </router-link>
                                                                    <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                        <router-link :to="{ path: '/project/edit/' + project.id }">
                                                                            <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                        </router-link>
                                                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(project.id)" title="Delete"></feather>
                                                                    </div>
                                                                </div>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Pending" :title-link-class="linkClass(2)">
                                    <template v-slot:title>
                                        <div @click="categoryType('PENDING')">
                                            <feather type="info"></feather> Pending
                                        </div>

                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row" v-if="allprojects.length>0">

                                                        <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                    <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                        <h6 class="box">{{ project.title }}</h6>
                                                                    </router-link>
                                                                    <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                        <router-link :to="{ path: '/project/edit/' + project.id }">
                                                                            <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                        </router-link>
                                                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(project.id)" title="Delete"></feather>
                                                                    </div>
                                                                </div>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Working" :title-link-class="linkClass(3)">
                                    <template v-slot:title>
                                        <div @click="categoryType('WORKING')">
                                            <feather type="compass"></feather> Working
                                        </div>

                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row" v-if="allprojects.length>0">

                                                        <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                    <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                        <h6 class="box">{{ project.title }}</h6>
                                                                    </router-link>
                                                                    <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                        <router-link :to="{ path: '/project/edit/' + project.id }">
                                                                            <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                        </router-link>
                                                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(project.id)" title="Delete"></feather>
                                                                    </div>
                                                                </div>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Completed" :title-link-class="linkClass(4)">
                                    <template v-slot:title>
                                        <div @click="categoryType('COMPLETED')">
                                            <feather type="compass"></feather> Completed
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row" v-if="allprojects.length>0">

                                                        <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                                                    <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                        <h6 class="box">{{ project.title }}</h6>
                                                                    </router-link>
                                                                    <div style="display: flex;flex-direction: row; gap: 5px;">
                                                                        <router-link :to="{ path: '/project/edit/' + project.id }">
                                                                            <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                                        </router-link>
                                                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center-password @click="deleteProject(project.id)" title="Delete"></feather>
                                                                    </div>
                                                                </div>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width':  projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>No Project Found</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </div>

                        <div class="col-sm-4 create-project-btn">
                            <div class="text-right">
                                <div class="form-group mb-0 mr-0"></div>
                                <router-link to="create">
                                    <a class="btn btn-primary" href="">
                                        <i class="fa fa-light fa-plus" style="margin-right: 10px;"></i>
                                        <h7>Add New Project</h7>
                                    </a>
                                </router-link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
    <!-- Project Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this project?</div>
    </b-modal>

    <!-- Update Priority -->
    <b-modal id="modal-center-password" centered ref="modal" title="Enter your password to continue" @ok="handlePasswordOk">
        <form ref="form" @submit.stop.prevent="handlePasswordSubmit">
            <b-form-group label="Password" label-for="name-input">

                <div style="position: relative; width: 100%;">
                    <div style="position: absolute; top: 0px; bottom: 0px; right: 0px; padding-right: 28px; display: flex; align-items: center;">
                        <i style="position: absolute; display: flex; justify-content: center; cursor: pointer;" :class="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </div>
                    <input :type="passwordFieldType" v-model="password" name="password" placeholder="Enter your password" class="form-control" />
                </div>

            </b-form-group>
        </form>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    myHost
} from "../../../_helpers/host";
export default {
    data() {
        return {
            host: myHost.host,
            tabIndex: 0,
            delete_value: '',
            password: '',
            passwordFieldType: 'password',

            activeItem: 'ALL',
        }
    },

    computed: {
        ...mapState({
            allprojects: state => state.project.project,
            // doingprojects: state => state.common.doingprojects,
            // doneprojects: state => state.common.doneprojects
        }),
        // approvedProjects() {
        //     return this.allprojects.filter(project => project.status === 'APPROVED');
        // },
        // pendingProjects() {
        //     return this.allprojects.filter(project => project.status === 'PENDING');
        // },
        // workingProjects() {
        //     return this.allprojects.filter(project => project.status === 'WORKING');
        // },
        // completedProjects() {
        //     return this.allprojects.filter(project => project.status === 'COMPLETED');
        // },

        projectDoneTaskPercentage() {
            return (project) => {
                if (project.task == 0) {
                    const percentage = 0;
                    return percentage
                } else {
                    const percentage = ((project.task - project.remaining) / project.task) * 100;

                    return Math.floor(percentage);
                }

            };
        },
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'fa fa-regular fa-eye' : 'fa fa-regular fa-eye-slash'
        },

    },
    mounted() {
        this.$store.dispatch("project/fetchProject")
    },
    methods: {
        categoryType(item) {
            this.activeItem = item
            console.log(item)
            if (item === 'ALL') {
                this.$store.dispatch("project/fetchProject")
            } else {
                this.$store.dispatch("project/fetchProject", item)
            }

        },
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },
        deleteProject(id) {
            this.delete_value = id
        },
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['text-success']
            } else {

            }
        },
        handleDeleteOk() {
            this.$store.dispatch("project/deleteProject", this.delete_value).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-center')
            })
        },
        handlePasswordOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handlePasswordSubmit()
        },

        handlePasswordSubmit() {
            this.$store.dispatch("project/checkMyPassword", {
                password: this.password
            }).then(response => {
                if (response.code == 200) {
                    this.$nextTick(() => {
                        this.$bvModal.show('modal-center')
                    })
                    this.password = ''

                } else {
                    this.password = ''
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-center-password')
            })
        },

    }
}
</script>

<style scoped>
.img-30 {
    width: 30px !important;
    height: 30px !important;
}

.box {
    inline-size: 280px;
    overflow-wrap: break-word;
}

@media screen and (max-width: 1645px) {
    .box {
        inline-size: 250px;
    }
}

@media screen and (max-width: 1555px) {
    .box {
        inline-size: 230px;
    }
}

@media screen and (max-width: 1495px) {
    .box {
        inline-size: 210px;
    }
}

@media screen and (max-width: 1495px) {
    .box {
        inline-size: 190px;
    }
}

@media screen and (max-width: 1335px) {
    .box {
        inline-size: 170px;
    }
}

@media screen and (max-width: 1265px) {
    .box {
        inline-size: 150px;
    }
}

@media screen and (max-width: 1200px) {
    .box {
        inline-size: 250px;
    }
}

@media screen and (max-width: 991px) {
    .box {
        inline-size: 550px;
    }
}

@media screen and (max-width: 740px) {
    .box {
        inline-size: 400px;
    }
}

@media screen and (max-width: 590px) {
    .box {
        inline-size: 350px;
    }
}

@media screen and (max-width: 510px) {
    .box {
        inline-size: 300px;
    }
}

@media screen and (max-width: 460px) {
    .box {
        inline-size: 270px;
    }
}

@media screen and (max-width: 430px) {
    .box {
        inline-size: 240px;
    }
}

@media screen and (max-width: 400px) {
    .box {
        inline-size: 210px;
    }
}

@media screen and (max-width: 370px) {
    .box {
        inline-size: 190px;
    }
}

@media screen and (max-width: 350px) {
    .box {
        inline-size: 170px;
    }
}
</style>
