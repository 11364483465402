<template>
<div>
    <Breadcrumbs main="My Workspace" title="Budget Details" />
    <!-- Employee Details  -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                                <div>
                                    <b-badge variant="primary" class="mb-4">
                                        <h7 style="text-align: center;font-size: 14px;">Budget Details</h7>
                                    </b-badge>
                                </div>
                                <div style="display: flex;">
                                    <feather type="message-circle" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-scrollable @click="Reply(budget_detail)" title="Reply"></feather>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Budget Title</label>
                                        <input class="form-control" type="text" placeholder="Enter grievance subject" v-model="budget_detail.title" disabled>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Requested Date</label>
                                        <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="budget_detail.date" disabled></datepicker>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Budget Requested By</label>
                                        <input class="form-control" type="text" placeholder="Enter grievance subject" v-model="budget_detail.requested_by.username" disabled>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Project</label>
                                        <input class="form-control" type="text" placeholder="Enter grievance subject" v-model="budget_detail.project.title" disabled>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Budget Details</label>
                                        <b-form-textarea id="textarea-auto-height" placeholder="Enter asset details..." v-model="budget_detail.detail_note" rows="4" disabled></b-form-textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="my-2" style="display: flex; flex-direction: row; gap: 10px;">
                                <h7>Budget Amount:</h7>
                                <div>
                                    <h7>{{ budget_detail.budget_amount }}</h7>
                                </div>
                            </div>
                            <div class="my-3" style="display: flex; flex-direction: row; gap: 10px;">
                                <h7>Approved Amount:</h7>
                                <div>
                                    <h7 v-if="budget_detail.approved_amount == null"> -- </h7>
                                    <h7 v-else>{{ budget_detail.approved_amount }}</h7>
                                </div>
                            </div>

                            <div class="mb-3" style="display: flex; flex-direction: row; gap: 10px;">
                                <h7>Budget Status:</h7>
                                
                                <div>
                                    <span v-if="budget_detail.status == 'APPROVED'">
                                        <b-badge pill variant="success">{{ budget_detail.status }}</b-badge>
                                    </span>
                                    <span v-if="budget_detail.status == 'REJECTED'">
                                        <b-badge pill variant="danger">{{ budget_detail.status }}</b-badge>
                                    </span>
                                    <span v-if="budget_detail.status == 'PENDING'">
                                        <b-badge pill variant="primary">{{ budget_detail.status }}</b-badge>
                                    </span>
                                </div>
                            </div>
                            <button @click="editRequestedBudget(budget_detail)" v-b-tooltip.hover title="Budget Approve" v-b-modal.modal-prevent-closing-budget-edit class="btn btn-primary my-2" v-if="budget_detail.status == 'REJECTED' || budget_detail.status == 'PENDING'">Approve Budget</button>

                            <h6 class="mt-4 mb-2">Already Refered</h6>
                            <div class="row">
                                <div class="text-center col-md-2 w-full" v-for="user in budget_detail.refering_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                                    <div class="w-100 mt-2 badge-style">
                                        <h7>{{ user.first_name}}</h7>
                                        <b-icon icon="x" style="color: white;"></b-icon>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Leave Allocate Delete Modal -->
    <b-modal id="modal-center-deleteAlocation" centered hide-header @ok="handleAllocationDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to remove this refere?</div>
    </b-modal>
    <!-- Edit Requested Budget -->
    <b-modal id="modal-prevent-closing-budget-edit" ref="modal" title="Approve Budget" @show="resetBudgetModal" @hidden="resetBudgetModal" @ok="handleRequestOk">
        <form ref="form" @submit.stop.prevent="handleRequestSubmit">

            <b-form-group label="Requested Amount" label-for="name-input" invalid-feedback="Budget amount is required">
                <b-form-input id="name-input" type="number" v-model="edit_budget.budget_amount" placeholder="Enter requested amount" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Approved Amount" label-for="name-input" invalid-feedback="Budget amount is required">
                <b-form-input id="name-input" type="number" v-model="edit_budget.approved_amount" placeholder="Enter approved amount"></b-form-input>
            </b-form-group>
            <b-form-group label="Status" label-for="name-input" invalid-feedback="Status is required">
                <b-form-select v-model="edit_budget.status" :options="statuses"></b-form-select>
            </b-form-group>
        </form>
    </b-modal>

    <!-- Reply Create Modal -->

    <b-modal id="modal-scrollable" ref="modal" title="Budget Replies" hide-footer @show="resetModal" @hidden="resetModal" @ok="handleOk">

        <h6>Already Refered</h6>
        <div class="row">
            <div class="text-center col-sm-6 w-full" v-for="user in this.my_reply.refer_to" :key="user.id" v-b-tooltip.hover v-b-modal.modal-center-deleteAlocation @click="deletetAllocation(user.id)" title="Delete">
                <div class="w-100 mb-2 badge-style">
                    <h7>{{ user.first_name}}</h7>
                    <b-icon icon="x" style="color: white;"></b-icon>
                </div>
            </div>
        </div>
        <multiselect v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>

        <div style="margin-top: 30px;" v-if="ticket_reply.length>0">
            <h6>Replies</h6>
            <div class="task-board-comments">
                <div v-for="reply in ticket_reply" :key="reply.id" class="chatbox">
                    <div class="chat">
                        <div class="avatar">
                            <img :src="host + reply.replier_photo" alt="" v-b-tooltip.hover :title="reply.replied_by.first_name + ' ' + reply.replied_by.last_name">
                        </div>
                        <div class="message">
                            <h7 class="name pl-1">{{ reply.replied_by.username }}</h7>
                            <!-- <p class="msg" v-b-tooltip.hover :title="reply.reply_date">{{ reply.reply }}</p> -->
                            <p class="msg" v-html="convertUrls(reply.reply)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-board-comment-box" style="margin-top: 10px;">
            <div style="display: flex; gap: 12px;">
                <input class="form-control" type="text" v-model="my_reply.reply" placeholder="Write your reply here..."  @keyup.enter="handleBudgetReplySubmit()">
                <button @click="handleBudgetReplySubmit()" class="btn btn-primary">Send</button>
            </div>
        </div>
    </b-modal>

</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Multiselect from 'vue-multiselect';
import { myHost } from '../../../../_helpers/host';
import {
    mapState
} from 'vuex';
import moment from "moment";
export default {
    components: {
        Datepicker,
        Multiselect
    },

    data() {
        return {
            host:myHost.host,
            statuses: ['PENDING', 'APPROVED', 'REJECTED'],
            selectedStatus: '',

            my_reply: {
                id: '',
                reply: '',
                refer_to: '',
            },
            temp_id: '',
            assignedMembers: [],
            filter: null,
            temp_id: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],
            
            edit_budget: {
                budget_category: '',
                category: '',
                detail_note: '',
                request_note: '',
                budget_amount: null,
                approved_amount: null,
                status: ''
            },
            options: [{
                    value: 'PENDING',
                    text: 'Pending'
                },
                {
                    value: 'APPROVED',
                    text: 'Approved'
                },
                {
                    value: 'REJECTED',
                    text: 'Rejected'
                }
            ],
        };
    },

    computed: {
        ...mapState({
            budget_detail: state => state.budget.budget_request_detail,
            user_list: state => state.employee.employee_list,
            ticket_reply: state => state.budget.ticket_reply,

        }),
    },

    mounted() {

        this.$store.dispatch("budget/fetchBudgetRequestDetail", this.$route.params.id);
        this.$store.dispatch("employee/fetchEmployeeList");

    },
    watch: {
        assignedMembers: {
            handler: function (newVal, oldVal) {
                this.storeAssignedMembers(newVal);
            },
            deep: true
        }
    },

    methods: {
        convertUrls(text) {
            const urlPattern = /https?:\/\/[^\s]+/g;
            return text.replace(urlPattern, (url) => {
                return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
            });
        },
        handleBudgetReplySubmit() {

            this.$store.dispatch("budget/addBudgetReply", {
                budget_request: this.my_reply.id,
                reply: this.my_reply.reply
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.my_reply.id = '',
                        this.my_reply.reply = ''

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },
        Reply(type) {
            this.my_reply.id = type.id
            this.my_reply.reply = type.name
            this.my_reply.refer_to = type.refering_to
            this.$store.dispatch("budget/fetchBudgetReply", this.my_reply.id)
        },
        storeAssignedMembers(assignedMembers) {
            const latestId = assignedMembers[assignedMembers.length - 1].id;

            this.$store.dispatch("budget/BudgetAddRefer", {
                id: this.my_reply.id,
                refer_id: latestId
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })

        },
        handleRequestOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleRequestSubmit()
        },

        handleRequestSubmit() {
            this.$store.dispatch("budget/requestBudgetEdit", {

                id: this.edit_budget.id,
                data: this.edit_budget
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-budget-edit')
            })
        },
        editRequestedBudget(item) {

            this.edit_budget.id = item.id
            this.edit_budget.budget_category = item.budget_category.id
            this.edit_budget.category = item.budget_category.title
            this.edit_budget.detail_note = item.detail_note
            this.edit_budget.request_note = item.request_note
            this.edit_budget.budget_amount = item.budget_amount
            this.edit_budget.approved_amount = item.approved_amount
            this.edit_budget.status = item.status
        },

        deletetAllocation(id) {
            this.temp_id = id
        },
        handleAllocationDeleteOk() {
            this.$store.dispatch("budget/BudgetAddDelete", {
                id: this.$route.params.id,
                refer_id: this.temp_id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.assignedMembers = '';

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable')
            })
        },
    }
};
</script>

<style scoped>
.badge-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    background-color: #2c323f;
    padding: 0.375rem 1.75rem;
    color: white;

}

.badge-style:hover {
    background-color: #ff595d;
}

.chatbox {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 10px;
}

.chat {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    word-break: break-all;
}


.avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: solid 2px white;
}

.message {
    flex-grow: 1; 
}

.msg {
    background: #fff0f0;
    width: 100%; /* Ensures message takes full width */
    padding: 10px;
    border-radius: 10px;
    position: relative;
    font-size: 13px;
    word-break: break-all;
}


.task-board-comments {
    height: 450px;
    overflow-y: auto;
}

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
