import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'ACCOUNT_UPDATE',
    smsDelete : 'ACCOUNT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        accountCategories: [],
        accountGroups: [],
        accountLedgers: [],
        ledgerDetails: {},
        balanceDetails: {},
        balanceSheetElements:[],
        noteDetails: {},
        incomeStatementCalculationDetails: {},
        accountTransactions: [],
        journalTransactions: [],
        trialBalance: [],
        trialBalanceSummary: {},
        incomeStructure: {},
        accountNotes: [],
        incomeStatement: [],

        balanceSheet: [],
        balanceSheetDetails:{},

        accountCategoryLoading: false,
        accountCategoryError: false,

        accountTrialLoading: false,
        accountTrialError: false,

        balanceSheetLoading: false,
        balanceSheetError: false,

        incomeStatementLoading: false,
        incomeStatementError: false,

        incomeStructureLoading: false,
        incomeStructureError: false,

        accountLedgerLoading: false,
        accountLedgerError: false,

        accountNoteLoading: false,
        accounNoteError: false,

        accountTransactionsLoading: false,
        accountTransactionsError: false,


        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,
        SET_ACCOUNT_CATEGORY(state, item) {
            state.accountCategories = item;
        },

        SET_ACCOUNT_TRANSACTIONS(state, item) {
            console.log(item)
            state.accountTransactions = item;
        },
        SET_LEDGER_BALANCE(state, item) {

            state.balanceDetails = item;
        },
        SET_JOURNAL_TRANSACTIONS(state, item) {
            console.log(item)
            state.journalTransactions = item;
        },

        SET_ACCOUNT_GROUP(state, item) {
            state.accountGroups = item;
        },

        SET_ACCOUNT_LEDGER(state, item) {
            state.accountLedgers = item;
        },
        SET_BALANCE_SHEET_ELEMENTS(state, item) {
            state.balanceSheetElements = item;
        },
        SET_ACCOUNT_LEDGER_DETAILS(state, item) {
            state.ledgerDetails = item;
        },
        SET_ACCOUNT_NOTE_DETAILS(state, item) {
            state.noteDetails = item;
        },

        ACCOUNT_CATEGORY_LOADING(state, loading) {
            state.accountCategoryLoading = loading;
        },

        ACCOUNT_CATEGORY_ERROR(state, error) {
            state.accountCategoryError = error;
        },
        SET_ACCOUNT_TRIAL_BALANCE(state, item) {
            state.trialBalance = item;
        },
        SET_ACCOUNT_TRIAL_BALANCE_SUMMARY(state, item) {
            state.trialBalanceSummary = item;
        },

        ACCOUNT_TRIAL_BALANCE_LOADING(state, loading) {
            state.accountTrialLoading = loading;
        },

        ACCOUNT_TRIAL_BALANCE_ERROR(state, error) {
            state.accountTrialError = error;
        },
        SET_ACCOUNT_NOTES(state, item) {
            state.accountNotes = item;
        },


        ACCOUNT_NOTE_LOADING(state, loading) {
            state.accountNoteLoading = loading;
        },

        ACCOUNT_NOTE_ERROR(state, error) {
            state.accounNoteError = error;
        },

        SET_INCOME_STRUCTURE_ELEMENTS(state, item) {
            state.incomeStructure = item;
        },

        INCOME_STRUCTURE_ELEMENTS_LOADING(state, loading) {
            state.incomeStructureLoading = loading;
        },

        INCOME_STRUCTURE_ELEMENTS_ERROR(state, error) {
            state.incomeStructureError = error;
        },
        SET_INCOME_STATEMENT_ELEMENTS(state, item) {
            state.incomeStatement = item;
        },
        SET_INCOME_STATEMENT_CALCULATION(state, item) {
            state.incomeStatementCalculationDetails = item;
        },

        INCOME_STATEMENT_LOADING(state, loading) {
            state.incomeStatementLoading = loading;
        },

        INCOME_STATEMENT_ERROR(state, error) {
            state.incomeStatementError = error;
        },
        SET_BALANCE_SHEET(state, item) {
            state.balanceSheet = item;
        },
        SET_BALANCE_SHEET_CALCULATION(state, item) {
            state.balanceSheetDetails = item;
        },

        BALANCE_SHEET_LOADING(state, loading) {
            state.balanceSheetLoading = loading;
        },

        BALANCE_SHEET_ERROR(state, error) {
            state.balanceSheetError = error;
        },

        ACCOUNT_GROUP_LOADING(state, loading) {
            state.accountGroupLoading = loading;
        },

        ACCOUNT_GROUP_ERROR(state, error) {
            state.accountGroupError = error;
        },

        ACCOUNT_LEDGER_LOADING(state, loading) {
            state.accountLedgerLoading = loading;
        },

        ACCOUNT_LEDGER_ERROR(state, error) {
            state.accountLedgerError = error;
        },

        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateAccountCategory({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_account_category + "/" + payload.id, payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountCategory');
            return response
        },
        async updateSerialCategory({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.get(V1API.edit_account_serial_category + "/" + payload.id+"/"+payload.serial, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountCategory');
            return response
        },

        async deleteAccountCategory({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_account_category + "/" + payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountCategory');

            return response
        },

        async fetchAccountCategory({commit}) {
            commit('ACCOUNT_CATEGORY_LOADING', true);
            commit('ACCOUNT_CATEGORY_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_category, config);
                let response = result.data

                commit('SET_ACCOUNT_CATEGORY', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_CATEGORY_ERROR', true);
            }
            commit('ACCOUNT_CATEGORY_LOADING', false);
        },
        async fetchAccountTrialBalance({commit}) {
            commit('ACCOUNT_TRIAL_BALANCE_LOADING', true);
            commit('ACCOUNT_TRIAL_BALANCE_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_trial_balance, config);
                let response = result.data
                console.log(response.data)
                commit('SET_ACCOUNT_TRIAL_BALANCE', response.data);
                commit('SET_ACCOUNT_TRIAL_BALANCE_SUMMARY', response.summary);
                return response

            } catch (error) {
                commit('ACCOUNT_TRIAL_BALANCE_ERROR', true);
            }
            commit('ACCOUNT_TRIAL_BALANCE_LOADING', false);
        },

        async addAccountCategory({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try {
                let response = await axios.post(V1API.create_account_category, payload, config).then(result => {
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchAccountCategory')
                return response

            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateAccountGroup({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_account_group + "/" + payload.id, payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountGroup');
            return response
        },

        async deleteAccountGroup({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_account_group + "/" + payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountGroup');

            return response
        },

        async fetchAccountGroup({commit}) {
            commit('ACCOUNT_GROUP_LOADING', true);
            commit('ACCOUNT_GROUP_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_group, config);
                let response = result.data

                commit('SET_ACCOUNT_GROUP', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_GROUP_ERROR', true);
            }
            commit('ACCOUNT_GROUP_LOADING', false);
        },
        async fetchAccountGroupByCategory({commit}, payload) {
            commit('ACCOUNT_GROUP_LOADING', true);
            commit('ACCOUNT_GROUP_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_group_by_category + "/" + payload, config);
                let response = result.data

                commit('SET_ACCOUNT_GROUP', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_GROUP_ERROR', true);
            }
            commit('ACCOUNT_GROUP_LOADING', false);
        },

        async addAccountGroup({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try {
                let response = await axios.post(V1API.create_account_group, payload, config).then(result => {
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchAccountGroup')
                return response

            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateAccountLedger({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_account_ledger + "/" + payload.id, payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountLedger');
            return response
        },

        async deleteAccountLedger({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_account_ledger + "/" + payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountLedger');

            return response
        },

        async fetchAccountLedger({commit}) {
            commit('ACCOUNT_LEDGER_LOADING', true);
            commit('ACCOUNT_LEDGER_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_ledger, config);
                let response = result.data

                commit('SET_ACCOUNT_LEDGER', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_LEDGER_ERROR', true);
            }
            commit('ACCOUNT_LEDGER_LOADING', false);
        },
        async fetchAccountLedgerDetails({commit},payload) {
            commit('ACCOUNT_LEDGER_LOADING', true);
            commit('ACCOUNT_LEDGER_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_ledger_details+"/"+payload, config);
                let response = result.data

                commit('SET_ACCOUNT_LEDGER_DETAILS', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_LEDGER_ERROR', true);
            }
            commit('ACCOUNT_LEDGER_LOADING', false);
        },
        async fetchAccountLedgerByGroup({commit}, payload) {
            commit('ACCOUNT_LEDGER_LOADING', true);
            commit('ACCOUNT_LEDGER_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                payload = {
                    group: payload
                }
                let result = await axios.post(V1API.get_account_ledger_by_group, payload, config);
                let response = result.data

                commit('SET_ACCOUNT_LEDGER', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_LEDGER_ERROR', true);
            }
            commit('ACCOUNT_LEDGER_LOADING', false);
        },

        async addAccountLedger({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try {
                let response = await axios.post(V1API.create_account_ledger, payload, config).then(result => {
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchAccountLedger')
                return response

            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async recordTransaction({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try {
                let response = await axios.post(V1API.record_transaction, payload, config).then(result => {
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchAccountTransactions')
                return response

            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },
        async fetchAccountTransactions({commit}) {
            commit('ACCOUNT_CATEGORY_LOADING', true);
            commit('ACCOUNT_CATEGORY_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_transactions, config);
                let response = result.data

                commit('SET_ACCOUNT_TRANSACTIONS', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_CATEGORY_ERROR', true);
            }
            commit('ACCOUNT_CATEGORY_LOADING', false);
        },
        async fetchAccountTransactionsByLedger({commit}, payload) {
            commit('ACCOUNT_CATEGORY_LOADING', true);
            commit('ACCOUNT_CATEGORY_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_transactions_by_ledger + "/" + payload, config);
                let response = result.data

                commit('SET_ACCOUNT_TRANSACTIONS', response.data);
                let response_payload={
                    credit: response.credit,
                    debit: response.debit,
                    balance: response.balance
                }
                console.log(response_payload)
                commit('SET_LEDGER_BALANCE',response_payload);
                return response

            } catch (error) {

                commit('ACCOUNT_CATEGORY_ERROR', true);
            }
            commit('ACCOUNT_CATEGORY_LOADING', false);
        },
        async fetchAccountTransactionsByJid ({ commit },payload) {
            commit('ACCOUNT_CATEGORY_LOADING', true);
            commit('ACCOUNT_CATEGORY_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_transactions_by_jid+"/"+payload, config);
                let response = result.data

                commit('SET_JOURNAL_TRANSACTIONS', response.data);
                return response

            } catch(error) {
                console.log(error)
                commit('ACCOUNT_CATEGORY_ERROR', true);
            }
            commit('ACCOUNT_CATEGORY_LOADING', false);
        },


        async addLedgerToELement({dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.post(V1API.income_structure_add_ledger + "/" + payload.slug, payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchIncomeStructure');
            return response
        },

        async deleteLedgerToELement({ dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.post(V1API.income_structure_delete_ledger + "/" + payload.slug, payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchIncomeStructure');

            return response
        },

        async fetchIncomeStructure({commit}) {
            commit('INCOME_STRUCTURE_ELEMENTS_LOADING', true);
            commit('INCOME_STRUCTURE_ELEMENTS_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.income_structure, config);
                let response = result.data

                commit('SET_INCOME_STRUCTURE_ELEMENTS', response.data);
                return response

            } catch (error) {
                commit('INCOME_STRUCTURE_ELEMENTS_ERROR', true);
            }
            commit('INCOME_STRUCTURE_ELEMENTS_LOADING', false);
        },
        async fetchIncomeStructureList({commit}) {
            commit('INCOME_STRUCTURE_ELEMENTS_LOADING', true);
            commit('INCOME_STRUCTURE_ELEMENTS_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.income_structure_list, config);
                let response = result.data

                commit('SET_INCOME_STRUCTURE_ELEMENTS', response.data);
                return response

            } catch (error) {
                commit('INCOME_STRUCTURE_ELEMENTS_ERROR', true);
            }
            commit('INCOME_STRUCTURE_ELEMENTS_LOADING', false);
        },
        async fetchIncomeStatement({commit}) {
            commit('INCOME_STATEMENT_LOADING', true);
            commit('INCOME_STATEMENT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.income_statement_calculated, config);
                let response = result.data

                commit('SET_INCOME_STATEMENT_ELEMENTS', response.data);
                commit('SET_INCOME_STATEMENT_CALCULATION', response.calculations);
                return response

            } catch (error) {
                commit('INCOME_STATEMENT_ERROR', true);
            }
            commit('INCOME_STATEMENT_LOADING', false);
        },
        async fetchBalanceSheet({commit}) {
            commit('BALANCE_SHEET_LOADING', true);
            commit('BALANCE_SHEET_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.balance_sheet, config);
                let response = result.data

                commit('SET_BALANCE_SHEET', response.data);
                // commit('', response.calculations);
                return response

            } catch (error) {
                commit('BALANCE_SHEET_ERROR', true);
            }
            commit('BALANCE_SHEET_LOADING', false);
        },


        async updateAccountNote({commit, state, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.patch(V1API.edit_account_note + "/" + payload.id, payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountNote');
            return response
        },

        async deleteAccountNote({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_account_note + "/" + payload, config).then(result => {
                let data = result.data
                return data
            })

            dispatch('fetchAccountNote');

            return response
        },

        async fetchAccountNote({commit}) {
            commit('ACCOUNT_NOTE_LOADING', true);
            commit('ACCOUNT_NOTE_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_note, config);
                let response = result.data

                commit('SET_ACCOUNT_NOTES', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_NOTE_ERROR', true);
            }
            commit('ACCOUNT_NOTE_LOADING', false);
        },
        async fetchAccountNoteDetails({commit},payload) {
            commit('ACCOUNT_NOTE_LOADING', true);
            commit('ACCOUNT_NOTE_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.get_account_note_details+"/"+payload, config);
                let response = result.data

                commit('SET_ACCOUNT_NOTE_DETAILS', response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_NOTE_ERROR', true);
            }
            commit('ACCOUNT_NOTE_LOADING', false);
        },

        async addAccountNote({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try {
                let response = await axios.post(V1API.create_account_note, payload, config).then(result => {
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchAccountNote')
                return response

            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchBalanceSheetElements({commit}) {
            commit('ACCOUNT_NOTE_LOADING', true);
            commit('ACCOUNT_NOTE_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let result = await axios.get(V1API.balance_sheet_element_structure, config);
                let response = result.data

                commit("SET_BALANCE_SHEET_ELEMENTS", response.data);
                return response

            } catch (error) {
                commit('ACCOUNT_NOTE_ERROR', true);
            }
            commit('ACCOUNT_NOTE_LOADING', false);
        },


    },





    getters: {}
}
