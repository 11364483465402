import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        rfp_category: [],
        rfp: [],
        rfp_detail: {},
        rfp_label_list:[],
        rfp_submitted_list:[],
        rfp_form_response:[],
        rfp_submission_response:{},
        form:[],
        rfp_response_reply:[],
        responses:[],
        projectLoading: false,
        projectError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,
        

        ...store
    },
    mutations : {
        ...mutations,

        SET_RFP_SUBMITTED_FORM_RESPONSE (state, response) {
            state.rfp_form_response = response;
        },

        SET_RFP_SUBMISSION_DETAILS(state, details) {
            state.rfp_submission_response = details;
        },

        SET_RFP_CATEGORY (state, rfp_category) {
            state.rfp_category = rfp_category;
        },
        
        SET_RFP_LABELS (state, label) {
            state.rfp_label_list = label;
        },
        SET_RFP_FORM_SUBMITTED (state, rfpList) {
            state.rfp_submitted_list = rfpList;
        },

        SET_RFP_RESPONSE_REPLY(state, reply) {
            state.rfp_response_reply = reply;
        },

        SET_RFP (state, rfp) {
            state.rfp = rfp;
        },

        SET_RFP_DETAILS (state, rfp) {
            state.rfp_detail = rfp;
        },

        SET_RFP_FORM (state, item) {
            state.form = item;
        },

        SET_RFP_FORM_RESPONSES (state, item) {
            state.responses = item;
        },


        RFP_LOADING (state, loading) {
            state.projectLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {

        async deleterfpFormResonse ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_rfp_form_responses +payload.response_id, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchFormResponses',payload.rfp_id);

            return response
        },


        async fetchFormResponses ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_rfp_form_responses + payload, config);
                let response = result.data

                commit('SET_RFP_FORM_RESPONSES', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },


        async submitForm ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.submit_rfp_form, {
                    form_data: payload.form_data,
                    submitted_form: payload.form
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchForm', payload.rfp)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addForm ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_rfp_form, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
                dispatch ('fetchForm', payload.rfp)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async fetchForm ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_rfp_form + payload, config);
                let response = result.data

                commit('SET_RFP_FORM', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },



        async updateRFP ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{
                
                let data= new FormData()
                let payloadData = buildFormData(data, payload.rfp);

                if(payload.vendors !== null) {
                    payload.vendors.forEach((item) => data.append("vendor_id", item))
                } else {
                    // data.append("vendor_id", null)
                }

                let response = await axios.patch(V1API.edit_rfp + payload.id, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchRFP')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteRFP ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_rfp + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchRFP');
            
            return response
        },

        async fetchRFPDetails ({ commit }, payload) {
            commit('RFP_LOADING', true);
            commit('RFP_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_rfp_details + payload, config);
                let response = result.data

                commit('SET_RFP_DETAILS', response.data);

            } catch(error) {
                commit('RFP_ERROR', true);
            }
            commit('RFP_LOADING', false);
        },

        async fetchRFP ({ commit }) {
            commit('RFP_LOADING', true);
            commit('RFP_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_rfp, config);
                let response = result.data

                commit('SET_RFP', response.data);

            } catch(error) {
                commit('RFP_ERROR', true);
            }
            commit('RFP_LOADING', false);
        },

        async fetchRFPLabels ({ commit }) {
            commit('RFP_LOADING', true);
            commit('RFP_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.rfp_submission_label_list, config);
                let response = result.data

                commit('SET_RFP_LABELS', response.data);

            } catch(error) {
                commit('RFP_ERROR', true);
            }
            commit('RFP_LOADING', false);
        },   

        async addRFP ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.rfp);

                if(payload.vendors !== null) {
                    payload.vendors.forEach((item) => data.append("vendor_id", item))
                } else {
                    // data.append("vendor_id", null)
                }

                // console.log(payloadData)
                let response = await axios.post(V1API.create_rfp, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchRFP')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addRFPCategory ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_rfp_category, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchRFPCategory')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchRFPCategory ({ commit }) {
            commit('RFP_LOADING', true);
            commit('RFP_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_rfp_category, config);
                let response = result.data

                commit('SET_RFP_CATEGORY', response.data);

            } catch(error) {
                commit('RFP_ERROR', true);
            }
            commit('RFP_LOADING', false);
        },

        async deleteRFPCategory ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_rfp_category + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchRFPCategory')
            return response
        },

        async toggleRFPStatus ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
           
            let response = await axios.get(V1API.rfp_active_status_toggle + payload, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchRFP');
            return response
        },

        async updateRfpStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {
                let response = await axios.get(V1API.update_ticket_status+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data

                })
                dispatch("fetchRFP")
                return response

            } catch (e) {
                commit('RFP_ERROR', true);
            } finally {

                commit('RFP_LOADING', false);
            }
        },

        async addRFPLabel({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.rfp_submission_label_add, {
                    name: payload
                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchRFPLabels')
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteRFPLabel ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.rfp_submission_label_delete + payload, config).then(result=>{
                let data = result.data
                return data
            })
          
            dispatch ('fetchRFPLabels')
            return response
        },

        async addLabelToResponse({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.rfp_submission_add_label+payload.response_id, {
                    label_id: payload.label_id
                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchFormResponses',payload.form_response)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteLabelFromResponse({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.rfp_submission_delete_label+payload.response_id, {
                    label_id: payload.label_id
                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchFormResponses',payload.form_response)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        // Fetch submitted rfp list form_sumitted
        async fetchRFPFormSubmittedList ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.rfp_list_user_submitted_form_only, config);
                let response = result.data

                commit('SET_RFP_FORM_SUBMITTED', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },

        async fetchRFPResponseByForm ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.rfp_response_by_form + payload, config);
                let response = result.data

                commit('SET_RFP_SUBMITTED_FORM_RESPONSE', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },

        async fetchRFPSubmissionDetails ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.rfp_submission_details + payload, config);
                let response = result.data

                commit('SET_RFP_SUBMISSION_DETAILS', response.data);
                return response

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },

        // RFP Submission Reply
        async addRFPResponseReply ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.rfp_submission_reply_create, {
                    submission: payload.submission,
                    reply:payload.reply,
                }, config).then(result=>{
                    let data = result.data
                    return data
                });

                dispatch('fetchRFPResponseReply',payload.submission)
                commit('SUBMIT_SUCCESS', true);  
                return response

            }catch (e) {
                commit('ASSET_ERROR', true);
            }finally {
                commit('ASSET_LOADING', false);
            }
        },

        async fetchRFPResponseReply ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_rfp_submission_reply + payload, config);
                let response = result.data
                commit('SET_RFP_RESPONSE_REPLY', response.data);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        }
    },

    

    getters: {}
}
