<template>
    <div>
        <!-- page-wrapper Start-->
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-12 p-0">
                    <div class="login-card">
                        <div>
                            <div>
                                <a class="logo">
                                    <img
                                        class="img-fluid for-light"
                                        src="../assets/images/logo/login.png"
                                        alt="looginpage"
                                        style="max-width: 17%"
                                    />
                                    <img
                                        class="img-fluid for-dark"
                                        src="../assets/images/logo/logo_dark.png"
                                        alt="looginpage"
                                        style="max-width: 17%"
                                    />
                                </a>
                            </div>
                            <div class="login-main">
                                <b-card>
                                    <b-card-text>
                                        <!-- <div class="alert alert-secondary">
                                            Email: siam.mahmud05@gmail.com<br />
                                            Password: siam8288
                                        </div> -->
                                        <form class="theme-form" @submit.prevent="handleSubmit">
                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <input
                                                    type="text"
                                                    v-model="email"
                                                    name="email"
                                                    class="form-control"
                                                    :class="{ 'is-invalid': submitted && !email }"
                                                />
                                                <div
                                                    v-show="submitted && !email"
                                                    class="invalid-feedback"
                                                >
                                                Email is required
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label htmlFor="password">Password</label>
                                                <div style="position: relative; width: 100%;">
                                                    <div style="position: absolute; top: 0px; bottom: 0px; right: 0px; padding-right: 28px; display: flex; align-items: center;">
                                                        <i style="position: absolute; display: flex; justify-content: center; cursor: pointer;" :class="passwordToggleIcon" @click="togglePasswordVisibility" />
                                                    </div>
                                                    <input
                                                        :type="passwordFieldType"
                                                        v-model="passwordjwt"
                                                        name="password"
                                                        class="form-control"
                                                        :class="{
                                                            'is-invalid': submitted && !passwordjwt,
                                                        }"
                                                    /> 
                                                    <div
                                                        v-show="submitted && !passwordjwt"
                                                        class="invalid-feedback"
                                                    >
                                                        Password is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mt-3 mb-0">
                                                <button
                                                    class="btn btn-primary btn-block"
                                                    :disabled="loggingIn"
                                                >
                                                Login
                                                </button>
                                            </div>
                                            <p class="mt-4 mb-0">
                                                Don't have account?
                                                <router-link
                                                    class="ml-2"
                                                    tag="a"
                                                    to="/auth/register"
                                                >
                                                Create Account
                                                </router-link>
                                            </p>

                                            <p class="mt-2 mb-0">
                                                Forgot Password?
                                                <router-link
                                                    class="ml-2"
                                                    tag="a"
                                                    to="/auth/reset-password"
                                                >
                                                Reset Password
                                                </router-link>
                                            </p>
                                        </form>
                                    </b-card-text>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- latest jquery-->
    </div>
</template>

<script>
import firebase from 'firebase';
import Userauth from '../auth/js/index';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    name: 'login',

    data() {
        return {
            type: 'password',
            passwordFieldType: 'password',
            email: '',
            username: '',
            passwordjwt: '',
            submitted: false,
        };
    },

    computed: { 
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'fa fa-regular fa-eye' : 'fa fa-regular fa-eye-slash'
        },

        // JWT authentication
        loggingIn() {
            return this.$store.state.authentication.status.loggingIn;
        }
    },

    created() {
        // reset login status for JWT
        this.$store.dispatch('authentication/logout');
    },

    methods: {
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
        },

        // JWT authentication
        handleSubmit() {
            this.submitted = true;
            const { email, passwordjwt } = this;
            // const { dispatch } = this.$store;
            if (email && passwordjwt) {
                this.$store.dispatch('authentication/login', { email, passwordjwt }).then(result => {
                    if(result.code != 200) {
                        Swal.fire({
                            title: "Error!",
                            text: result.message,
                            icon: "error"
                        });
                    }
                });
            }
        },

        // show/hide password
        showPassword: function() {
            if (this.type === 'password') {
                this.type = 'text';
            } else {
                this.type = 'password';
            }
        }
    }
};
</script>
