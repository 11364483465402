import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'TICKET_UPDATE',
    smsDelete : 'TICKET_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {

        ticket_list: [],
        my_ticket_list:[],
        refer_ticket_list:[],
        ticket_details:{},

        ticket_reply:[],
        count:null,
        ticketLoading: false,
        ticketError: false,
        ticketSuccess:false,


        ...store
    },
    mutations : {
        ...mutations,

        SET_TICKET (state, ticket) {
            state.ticket_list = ticket;
        },
        SET_MY_TICKET (state, {data,count}) {
            state.my_ticket_list = data;
            state.count = count;
        },
        SET_TICKET_DETAILS(state, detail) {
            state.ticket_details = detail;
        },
        SET_MY_REFER_TICKET (state, ticket) {
            state.refer_ticket_list = ticket;
        },
        SET_TICKET_REPLY(state,reply){
            state.ticket_reply = reply;
        },

        TICKET_LOADING (state, loading) {
            state.ticketLoading = loading;
        },
        TICKET_ERROR (state, loading) {
            state.ticketError = loading;
        },
        TICKET_SUBMIT_SUCCESS (state, success) {
            state.ticketSuccess = success;
        }
    },

    actions: {
        //Training Type
        async addTicket ({commit, dispatch}, payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.create_ticket, payload, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('TICKET_SUBMIT_SUCCESS', true);
                dispatch ('fetchTicket')
                return response

            }catch (e) {
                commit('TICKET_ERROR', true);
            }finally {
                commit('TICKET_LOADING', false);
            }
        },
         async addTicketMyEnd ({commit, dispatch}, payload) {
                    commit('TICKET_LOADING', true);
                    commit('TICKET_ERROR', false);
                    let config = {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("access_token"),
                        },
                    };
                    try{
                        let response = await axios.post(V1API.create_ticket, payload, config).then(result=>{
                            let data = result.data
                            return data

                        });

                        commit('TICKET_SUBMIT_SUCCESS', true);
                        dispatch ('fetchMyTicket')
                        return response

                    }catch (e) {
                        commit('TICKET_ERROR', true);
                    }finally {
                        commit('TICKET_LOADING', false);
                    }
                },


        async fetchTicket ({ commit }) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_ticket, config);
                let response = result.data
                commit('SET_TICKET', response.data);

            } catch(error) {
                commit('TICKET_ERROR', true);
            }
            commit('TICKET_LOADING', false);
        },
        async fetchTicketDetail ({ commit },payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_ticket_details+payload, config);
                let response = result.data
                commit('SET_TICKET_DETAILS', response.data);

            } catch(error) {
                commit('TICKET_ERROR', true);
            }
            commit('TICKET_LOADING', false);
        },


        async updateTicket({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.edit_ticket+payload.id,
                        {
                            subject: payload.subject,
                            refer_to: payload.refer_to

                        },config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchTicket');

                }
            )
            return response
        },


        async deleteTicket({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_ticket + payload, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchTicket');

            return response
        },

        async updateTicketStatus({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.update_ticket_status+ payload.id + "/" + payload.status, config).then(result => {
                    let data = result.data
                    return data

                })
                dispatch("fetchMyRefreTicket")
                dispatch("fetchTicket")
                dispatch("fetchTicketDetail",payload.id)
                return response
            } catch (e) {

                commit('TICKET_ERROR', true);
            } finally {

                commit('TICKET_LOADING', false);
            }
        },

        async updateTicketPriority({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.update_ticket_priority+ payload.id + "/" + payload.priority, config).then(result => {
                    let data = result.data
                    return data

                })


                dispatch("fetchMyTicket")
                dispatch("fetchTicket")
                dispatch("fetchTicketDetail",payload.id)
                return response
            } catch (e) {

                commit('TICKET_LOADING', true);
            } finally {

                commit('TICKET_ERROR', false);
            }
        },


        async fetchMyTicket ({ commit }) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_my_ticket, config);

                const {data,count} = result.data
           
                commit('SET_MY_TICKET', {data,count});

            } catch(error) {
                commit('TICKET_ERROR', true);
            }
            commit('TICKET_LOADING', false);
        },


        async fetchMyRefreTicket ({ commit }) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_refer_ticket, config);
                let response = result.data
                commit('SET_MY_REFER_TICKET', response.data);

            } catch(error) {
                commit('TICKET_ERROR', true);
            }
            commit('TICKET_LOADING', false);
        },


        async addTicketReply ({commit, dispatch}, payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_ticket_reply, {

                    ticket: payload.ticket,
                    reply:payload.reply,
                }, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('TICKET_SUBMIT_SUCCESS', true);
                dispatch ('fetchMyRefreTicket')


                return response

            }catch (e) {
                commit('TICKET_ERROR', true);
            }finally {
                commit('TICKET_LOADING', false);
            }
        },
        async fetchTicketReply ({ commit }, payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            try{
                let result = await axios.get(V1API.get_replies_of_ticket + payload, config);
                let response = result.data
                commit('SET_TICKET_REPLY', response.data);

            } catch(error) {
                commit('TICKET_ERROR', true);
            }
            commit('TICKET_LOADING', false);
        },



        async ticketAddRefer ({commit, dispatch}, payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.add_refer_to_ticket + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });


                dispatch ('fetchMyRefreTicket')
                dispatch ('fetchTicketDetail',payload.id)
                dispatch ('fetchMyTicket')
                dispatch ('fetchTicket')
                return response

            }catch (e) {
                commit('TICKET_ERROR', true);
            }finally {
                commit('TICKET_LOADING', false);
            }
        },







        async ticketDeleteRefer ({commit, dispatch}, payload) {
            commit('TICKET_LOADING', true);
            commit('TICKET_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.delete_refer_to_ticket + payload.id, {
                    refer_id:payload.refer_id
                }, config).then(result=>{
                    let data = result.data
                    return data

                });


                dispatch ('fetchMyRefreTicket')
                dispatch ('fetchTicketDetail',payload.id)
                dispatch ('fetchMyTicket')
                dispatch ('fetchTicket')
                return response

            }catch (e) {
                commit('TICKET_ERROR', true);
            }finally {
                commit('TICKET_LOADING', false);
            }
        },



















    },










    getters: {}
}
