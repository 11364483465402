<template>
    <div>
        <Breadcrumbs main="Finance & Accounts" submain="Accounts" title="Trial Balance"/>

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Trial Balance Breakdown</h5>
                        </div>

                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">

                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </b-col>

<!--                                <b-col xl="6">-->
<!--                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">-->
<!--                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>-->
<!--                                    </b-form-group>-->
<!--                                </b-col>-->
                            </b-row>

                            <div class="table-responsive datatable-vue">
                                <b-table
                                    show-empty
                                    stacked="md"
                                    sticky-header
                                    :items="items"
                                    :fields="tablefields"
                                    :filter="filter"

                                    @filtered="onFiltered"
                                >
                                    <template #cell(index)="data">
                                        {{ data.item.journal_id}}
                                    </template>
                                    <template #cell(title)="data">
                                        {{data.item.title}} <br>
                                      <router-link :to="{ path: '/finance-and-accounts/account/ledger/' +  data.item.lid  }">
                                        <small style="font-weight: bold;">{{ data.item.lid }}</small>
                                      </router-link>
                                    </template>

                                    <template #cell(ledger_break_down)="data">
                                        <span style="display: flex; flex-direction: column; ">
                                              <span v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column">{{ledger.ledger_id.title}}
                                                  <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>
                                              </span>
                                        </span>

                                            <span  style="display: flex; flex-direction: column;">

                                                 <span v-for="(ledger,index) in data.item.ledger_break_down.credit" style="display: flex; flex-direction: column">
                                                   <span style="margin-left: 16px;">{{ledger.ledger_id.title}}</span>
                                                        <span v-if =" data.item.ledger_break_down.credit.length-1!==index"style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>
                                                 </span>

                                            </span>

                                    </template>

                                    <template #cell(reference)="data">
                                        <span style="display: flex; flex-direction: column;">
                                            <span  v-for="ledger in data.item.ledger_break_down.debit" style="display: flex; flex-direction: column">
                                                <router-link :to="{ path: '/finance-and-accounts/account/ledger/' + ledger.ledger_id.lid }">
                                                    {{ ledger.ledger_id.lid }}
                                                </router-link>
                                              <span style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>

                                            </span>
                                             <span  v-for="(ledger,index) in data.item.ledger_break_down.credit"  style="display: flex; flex-direction: column">
                                                <router-link :to="{ path: '/finance-and-accounts/account/ledger/' + ledger.ledger_id.lid }">
                                                    {{ ledger.ledger_id.lid}}
                                                </router-link>
                                               <span  v-if =" data.item.ledger_break_down.credit.length-1!==index" style="border-style: solid; border-width: 0.01rem; border-color:lightgrey" ></span>
                                             </span>
                                        </span>
                                    </template>



                                    <template #cell(debit)="data">
                                        <span>{{data.item.debit!=0?data.item.debit:""}}</span>
                                    </template>

                                  <template #cell(credit)="data">
                                    <span>{{data.item.credit!=0?(data.item.credit*-1):""}}</span>
                                  </template>

                                </b-table>
                            </div>

                            <b-row>
                              <b-col class="col-7 ml-2" style="font-weight: bolder">Totals</b-col>
                                  <b-col class="text-right mr-1" style="font-weight: bolder">{{trialBalanceSummary.debit}}</b-col>
                                  <b-col class="text-right mr-1" style="font-weight: bolder">{{trialBalanceSummary.credit*-1}}</b-col>
                            </b-row>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {mapState} from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
export default {
    components: {
        Datepicker
    },

    data() {
        return {
            tablefields: [
                // { key: 'index', label: '#', sortable:true },
                { key: 'title', label: 'Ledger title', sortable: true, class: 'text-start col-3' },
                // { key: 'lid', label: 'Ledger ID', sortable: true },
                { key: 'group.title', label: 'group',class:'text-start col-2' },
                { key: 'group.pre_defined.title', label: 'Pre-Defined',class:'text-start col-2', sortable: true },
                { key: 'debit', label: 'Debit',class:'text-right'  },
                { key: 'credit', label: 'Credit',class:'text-right' }

            ],

            items: [

                 ],


            // options: ["Digital Marketing", "Front-end", "Back-end"],
            //
            filter:"",

            totalRows: 1,
            currentPage: 2,
            // perPage: 10,
            pageOptions: [5, 10, 15]
        };
    },

  computed: {
    ...mapState('account', ["trialBalance","trialBalanceSummary", "accountTrialError", "accountTrialLoading", "submitError", "submitSuccess", "submitLoading"]),
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key};
          });
    }
  },
  created() {
    this.$store.dispatch('account/fetchAccountTrialBalance').then(result=>{
      this.items=this.trialBalance
      this.totalRows=this.items.length
    })





  },
  watch: {

    accountGroups(oldValue, newValue) {

      // this.items = this.accountGroups

    },


  },


  mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;

    },

    methods: {

      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 2;
      },
    },
};
</script>
