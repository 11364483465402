<template>
<div>
    <Breadcrumbs main="Projects" title="Tasks" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-12 project-list">

                <div class="card">
                    <div class="card-body">
                        <div class="row">

                            <div class="col-md-3 ">
                                <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); margin-top: 8px; gap: 8px;">
                                    <div>
                                        <span style="font-size: medium; width: 100%;">
                                            <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="primary" @click="filterByType('ALL')">
                                                <span>ALL</span>
                                            </b-badge>
                                        </span>
                                    </div>
                                    <div>
                                        <span style="font-size: medium; width: 100%;">
                                            <b-badge style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;" href="#" variant="danger" @click="filterByType('DEADLINE')">
                                                <span>TODAY</span>
                                            </b-badge>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 my-2" v-for="(list, index) in visibleList" :key="index">
                                <div class="project-box boxDesign">
                                    <div class="col-lg-4">
                                        <div class="title">
                                            <h5>{{ list.project_id.title }}</h5>
                                        </div>
                                    </div>
                                    <div class="tasks w-full col-lg-8" style="border-left: 2px solid #eeee;">
                                        <div v-for="task in list.tasks" :key="task.id" style="margin-bottom: 20px ;">
                                            <div style="display: flex; align-items: start;">

                                                <div class="mr-2" style="margin-top: 2px;">
                                                    <input type="checkbox" id="myCheck" v-model="task.task_id.complete" @click="Task(task.task_id.id)">
                                                </div>

                                                <div class="d-flex">
                                                    <h7 style="font-weight: 500;">{{ task.task_id.title }}</h7>
                                                    <h7 class="badgeStyle">{{task.task_id.end_date}}</h7>
                                                </div>
                                            </div>

                                            <div v-for="subtask in task.subtasks" :key="subtask.id" style="margin-left: 40px;">

                                                <div style="display: flex; align-items: start;">

                                                    <div class="mr-2" style="margin-top: 2px;">
                                                        <input type="checkbox" id="myCheck" v-model="subtask.complete" @click="subTask(subtask.id)">
                                                    </div>

                                                    <div>
                                                        <div>{{ subtask.title }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {myHost} from "../../../_helpers/host";
export default {
    data() {
        return {
            host:myHost.host,
            filter: null,
        }
    },

    computed: {
        ...mapState({
            allTask: state => state.project.myTask,
            deadlineTask: state => state.project.myTaskDeadline,

        }),
        visibleList() {
            if (!this.filter || this.filter === "ALL") {
                return this.allTask;
            } else {
                return this.deadlineTask;
            }

        },
    },

    mounted() {
        this.$store.dispatch("project/fetchMyTask")
        this.$store.dispatch("project/fetchMyTaskDeadlinToday")
    },
    methods: {
        filterByType(Type) {
            this.filter = Type;
        },

        Task(id) {

            var checkBox = document.getElementById("myCheck");
            var text = document.getElementById("text");

            this.$store.dispatch("project/togglTaskComplete", {
                id: id,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            if (checkBox.checked == true) {
                text.style.display = "block";
            } else {
                text.style.display = "none";
            }
        },
        subTask(id) {
            var checkBox = document.getElementById("myCheck");

            var text = document.getElementById("text");

            this.$store.dispatch("project/toggleSubTaskComplete", {
                id: id,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            if (checkBox.checked == true) {
                text.style.display = "block";
            } else {
                text.style.display = "none";
            }
        },
    }
};
</script>

<style scoped>
.boxDesign {
    display: flex;
    flex-direction: row;

}

.badgeStyle {
    background: #ffc107a1;
    padding: 2px 5px;
    color: black;
    margin-left: 35px;
    border-radius: 1rem;
    font-size: 11px;

}

@media only screen and (max-width: 800px) {
    .boxDesign {
        display: flex;
        flex-direction: column;
        gap: 15px;

    }
}
</style>
