import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'EMPLOYEE_UPDATE',
    smsDelete : 'EMPLOYEE_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        otp_list: [],
   

        ...store
    },
    mutations : {
        ...mutations,

        SET_OTPLIST (state, otps) {
            state.otp_list = otps;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async fetchOTPS ({ commit }) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            let config = {
                // headers: {
                //   Authorization: "Bearer " + localStorage.getItem("access_token"),
                // }
            };

            try{
                let result = await axios.get(V1API.otp_list, config);
                let response = result.data.data
              
                commit('SET_OTPLIST', response);

            } catch(error) {
                commit('SUBMIT_ERROR', true);
            }
            commit('SUBMIT_LOADING', false);
        },


        async emailCheck ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                // headers: {
                //     Authorization: "Bearer " + localStorage.getItem("access_token"),
                //   }
            };
            try{
                let response = await axios.post(V1API.email_check, {email:payload}, config).then(result=>{
                    
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
            
                return response

            }catch (e) {
                
                commit('SUBMIT_ERROR', true);
            }finally {
              
                commit('SUBMIT_LOADING', false);
            }
        },

        
        async verifyOTP ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                // headers: {
                //     Authorization: "Bearer " + localStorage.getItem("access_token"),
                //   }
            };
            try{
                let response = await axios.post(V1API.verify_otp, {otp:payload.otp, user:payload.user}, config).then(result=>{
                    
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
            
                return response

            }catch (e) {
                
                commit('SUBMIT_ERROR', true);
            }finally {
              
                commit('SUBMIT_LOADING', false);
            }
        },

        async resetPassword ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                // headers: {
                //     Authorization: "Bearer " + localStorage.getItem("access_token"),
                //   }
            };
            try{
                let response = await axios.post(V1API.reset_password, {email:payload.email, new_password:payload.new_password,new_password2:payload.new_password2}, config).then(result=>{
                    
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
            
                return response

            }catch (e) {
                
                commit('SUBMIT_ERROR', true);
            }finally {
              
                commit('SUBMIT_LOADING', false);
            }
        },
        
        async updatePassword ({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            try{
                let response = await axios.post(V1API.update_password, payload, config).then(result=>{
                    
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
            
                return response

            }catch (e) {
                
                commit('SUBMIT_ERROR', true);
            }finally {
              
                commit('SUBMIT_LOADING', false);
            }
        },
        
    },

    getters: {}
}
