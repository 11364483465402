<template>
<div>
    <Breadcrumbs main="Project" title="Projects" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 project-list">
                <div class="card">
                    <div class="row m-0">
                        <div class="col-sm-12 p-0">
                            <b-tabs content-class="mt-3" v-model="tabIndex">
                                <b-tab title="All" :title-link-class="linkClass(0)">
                                    <template v-slot:title>
                                        <div @click="categoryType('ALL')">
                                            <feather type="target" class="tab-title"></feather> All
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card">
                                                <div class="card-body">
                                                  
                                                    <div class="row" v-if="allprojects.length > 0">
                                                        <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                    <h6>{{ project.title }}</h6>
                                                                </router-link>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->

                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>You're not assigned to any project yet</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Approved" :title-link-class="linkClass(1)">
                                    <template v-slot:title>
                                        <div @click="categoryType('APPROVED')">
                                            <feather type="disc"></feather> Approved
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row" v-if="allprojects.length>0">
                                                        <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>
                                                                <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                    <h6>{{ project.title }}</h6>
                                                                </router-link>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>You're not assigned to any project yet</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Pending" :title-link-class="linkClass(2)">
                                    <template v-slot:title>
                                        <div @click="categoryType('PENDING')">
                                            <feather type="info"></feather> Pending
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row" v-if="allprojects.length>0">
                                                        <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <router-link :to="{ path: '/project/details/' + project.id }" class="link-style"> 
                                                                    <h6>{{ project.title }}</h6>
                                                                </router-link>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>You're not assigned to any project yet</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Working" :title-link-class="linkClass(3)">
                                    <template v-slot:title>
                                        <div @click="categoryType('WORKING')">
                                            <feather type="compass"></feather> Working
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row" v-if="allprojects.length>0">
                                                        <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                    <h6>{{ project.title }}</h6>
                                                                </router-link>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>You're not assigned to any project yet</h6>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="Completed" :title-link-class="linkClass(4)">
                                    <template v-slot:title>
                                        <div @click="categoryType('COMPLETED')">
                                            <feather type="compass"></feather> Completed
                                        </div>
                                    </template>
                                    <b-card-text>
                                        <div class="col-sm-12 p-0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row" v-if="allprojects.length>0">
                                                        <div class="col-xl-4 col-lg-6" v-for="(project, index) in allprojects" :key="index" :class="index < 3 ? 'mb-4' : 'mb-4'">
                                                            <div class="project-box">
                                                                <div class="my-4">
                                                                    <span v-if="project.status == 'APPROVED'" class="badge" :class="'badge-primary'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'PENDING'" class="badge" :class="'badge-danger'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'WORKING'" class="badge" :class="'badge-info'">{{ project.status }}</span>
                                                                    <span v-if="project.status == 'COMPLETED'" class="badge" :class="'badge-success'">{{ project.status }}</span>
                                                                </div>

                                                                <router-link :to="{ path: '/project/details/' + project.id }" class="link-style">
                                                                    <h6>{{ project.title }}</h6>
                                                                </router-link>
                                                                <div style="display: flex; align-items: start; justify-content: flex-start;">
                                                                    <h7 class="badge-pill badge-danger ">{{ project.deadline }}</h7>
                                                                </div>

                                                                <!-- <div v-if="project.details.length < 200" v-html="project.details"></div> -->
                                                                <div class="mt-3" v-html="project.details" style="height: 200px; overflow: hidden; text-overflow: ellipsis;"></div>

                                                                <div class="mt-3 row details">
                                                                    <div class="col-6">
                                                                        <span>Tasks</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.task }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.task }}</div>
                                                                    <div class="col-6">
                                                                        <span>Resolved</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.resolved }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.resolved }}</div>
                                                                    <div class="col-6">
                                                                        <span>Remaining</span>
                                                                    </div>
                                                                    <div v-if="project.status == 'APPROVED'" class="col-6" style="color: #1c1c1c;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'PENDING'" class="col-6" :class="'text-danger'">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'WORKING'" class="col-6" style="color: #a426f2;">{{ project.remaining }}</div>
                                                                    <div v-if="project.status == 'COMPLETED'" class="col-6" :class="'text-success'">{{ project.remaining }}</div>
                                                                </div>

                                                                <div class="customers mt-3" v-if="project.teammates.length > 0">
                                                                    <ul v-for="(team, index) in project.teammates" :key="index">
                                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                                            <img class="img-30 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div class="project-status mt-3">
                                                                    <div class="media mb-0">
                                                                        <p>{{projectDoneTaskPercentage(project)}}%</p>
                                                                        <div class="media-body text-right">
                                                                            <span>Done</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="progress" style="height: 5px">
                                                                        <div v-if="project.status == 'APPROVED'" class="progress-bar-animated progress-bar-striped" :class="'bg-primary'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'PENDING'" class="progress-bar-animated progress-bar-striped" :class="'bg-danger'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'WORKING'" class="progress-bar-animated progress-bar-striped" :class="'bg-info'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        <div v-if="project.status == 'COMPLETED'" class="progress-bar-animated progress-bar-striped" :class="'bg-success'" role="progressbar" :style="{'width': projectDoneTaskPercentage(project) + '%'}" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div v-else class="text-center">
                                                        <h6>You're not assigned to any project yet</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
                            </b-tabs>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import {myHost} from "../../../_helpers/host";
export default {
    data() {
        return {
            host:myHost.host,
            tabIndex: 0,
            activeItem: 'ALL',
        }
    },

    computed: {
        ...mapState({
            allprojects: state => state.project.my_project,
            // doingprojects: state => state.common.doingprojects,
            // doneprojects: state => state.common.doneprojects
        }),
        // approvedProjects() {
        //     return this.allprojects.filter(project => project.status === 'APPROVED');
        // },
        // pendingProjects() {
        //     return this.allprojects.filter(project => project.status === 'PENDING');
        // },
        // workingProjects() {
        //     return this.allprojects.filter(project => project.status === 'WORKING');
        // },
        // completedProjects() {
        //     return this.allprojects.filter(project => project.status === 'COMPLETED');
        // },

        projectDoneTaskPercentage() {
            return (project) => {
                if (project.task == 0) {
                    const percentage = 0;
                    return percentage
                } else {
                    const percentage = ((project.task - project.remaining) / project.task) * 100;

                    return Math.floor(percentage);
                }

            };
        }

    },

    methods: {

        categoryType(item) {
            this.activeItem = item
            console.log(item)
            if (item === 'ALL') {
                this.$store.dispatch("project/fetchMyProject")
            } else {
                this.$store.dispatch("project/fetchMyProject", item)
            }

        },

        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['text-success']
            } else {

            }
        },
    },

    mounted() {
        this.$store.dispatch("project/fetchMyProject")
    }
};
</script>
<style scoped>
.img-30 {
    width: 30px !important;
    height: 30px !important;
}
</style>
