<template>
<div>
    <Breadcrumbs main="My Workspace" submain="Requsition" title="Requisition View" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-8">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Title</label>
                                        <input class="form-control" type="text" placeholder="Enter requisition title" disabled v-model="requisition.title" style="height: 44px;">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Refer To</label>
                                        <multiselect disabled v-model="assignedMembers" tag-placeholder="Add member" placeholder="Search or add a member" label="first_name" :max="user_list.length" track-by="id" :options="user_list" :multiple="true" :taggable="true"></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Requisition Type</label>
                                            <select style="height: 44px;" class="form-control digits" v-model="requisition.type" disabled>
                                                <option v-for="(type, index) in requ_types" :key="index" :value="type.id">{{ type.name }}</option>
                                            </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Project</label>
                                        <select style="height: 44px;" class="form-control digits" disabled v-model="requisition.project">
                                            <option v-for="(project, index) in projects" :key="index" :value="project.id">{{ project.title }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Items</label>
                                        <ItemTable v-if="requisition.details" style="margin-bottom: 10px;" id="item-table" :tableData="requisition.details" @itemsUpdated="updateItems" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <!-- Grand Total -->
                                    <div class="form-group" style="display: flex; gap: 25px; align-items: center;">
                                        <label style="margin-top: 8px; font-weight: 500; width: 120px;">Grand Total</label>
                                        <input style="height: 44px;" class="form-control" type="number" placeholder="Enter grand total" disabled v-model="requisition.grand_total" />
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Note</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea4" placeholder="Enter note..." rows="3" disabled v-model="requisition.note"></textarea>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3">Submit</button>
                                        <router-link to="/finance-and-accounts/account/requisition/list">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </px-card>
            </div>

            <div class="col-sm-4">
                <px-card>
                    <div class="content-container-attahment w-100">
                        <div>
                            <div class="d-flex justify-content-between" style="margin-bottom: 8px;">
                                <h6>Supporting Documents</h6>
                                <feather type="plus-square" size="22px" style="color: #ff595d; cursor: pointer;" v-b-modal.modal-scrollable-add-document v-b-tooltip.hover title="Add Documents"> </feather>
                            </div>

                            <b-table sticky-header :items="detail.requisition_attach" :fields="Attachment" head-variant="light" v-if="detail.requisition_attach.length>0">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template #cell(title)="data">
                                    <a :href="host+data.item.requisition_attachment" target="_blank">{{ truncateText(data.item.attach_title, 100) }}</a>
                                </template>

                                <template #cell(action)="data">
                                    <div>
                                        <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="Delete" @click="deleteAttach(data.item.id)"></feather>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </px-card>

                <px-card>
                    <div class="content-container-attahment w-100">
                        <div>
                            <div class="d-flex justify-content-between" style="margin-bottom: 8px;">
                                <h6>Payments</h6>
                            </div>

                            <b-table show-empty sticky-header :items="paymentList" :fields="payments" head-variant="light">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template #cell(status)="row">
                                    <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'APPROVED'">
                                        <b-badge variant="success" v-b-modal.modal-prevent-closing-status>{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'PENDING'">
                                        <b-badge variant="primary" v-b-modal.modal-prevent-closing-status>{{ row.value }}</b-badge>
                                    </span>
                                    <span @click="getStatus(row.value, row.item.id)" v-if="row.value == 'REJECTED'">
                                        <b-badge variant="danger" v-b-modal.modal-prevent-closing-status>{{ row.value }}</b-badge>
                                    </span>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>

    <!-- Project Attachment Create -->
    <b-modal id="modal-scrollable-add-document" ref="modal" title="Add document" @ok="handleAttachOk">
        <form ref="form" @submit.stop.prevent="handleAttahmentSubmit">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label>Upload Documents</label>
                        <b-form-file placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." @change="uploadAttachFile"></b-form-file>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import {
    computed
} from "vue";
import ItemTable from "../../requisition/edit-item-table.vue";
import Multiselect from 'vue-multiselect';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    myHost
} from "../../../../_helpers/host";
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        ItemTable,
        Multiselect
    },

    data() {
        return {
            host: myHost.host,

            add_attachment: {
                requisition_attachment: null,
                requisition_id: '',
            },

            assignedMembers: [],

            requisition: {
                title: '',
                refering_to: [],
                details: null,
                type: null,
                project: null,
                total: computed(() => {
                    let total = 0;
                    if (this.requisition.details.length >= 1) {
                        this.requisition.details.map((item) => {
                            total = total + item.amount;
                        });
                    }
                    return total;
                }),
                // grand_total: computed(() => {
                //     if (this.requisition.total !== 0) {
                //         const tax = this.requisition.vat_tax || 0;
                //         const discount = this.requisition.discount || 0;
                //         let gtotal =
                //             this.requisition.total +
                //             tax * (this.requisition.total / 100) -
                //             discount * (this.requisition.total / 100);
                //         return Math.round((gtotal + Number.EPSILON) * 100) / 100;
                //     }
                //     return 0;
                // }),
                grand_total: 0,
                note: ''
            },
            Attachment: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: true
                },

            ],

            payments: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'requested_by.username',
                    label: 'Requested By',
                    sortable: true
                },
                {
                    key: 'paid_amount',
                    label: 'Request Amount',
                    sortable: true
                },

                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },

            ],

            client: {
                name: '',
                company: '',
                designation: '',
                phone: '',
                title: '',
                status: 'Active'
            }
        };
    },

    computed: {
        ...mapState({
            user_list: state => state.employee.employee_list,
            detail: state => state.requisition.requisition_detail,
            projects: state => state.project.project,
            requ_types: state => state.requisition.requisition_type,
            paymentList: state => state.requisition.requisitionPaymentList
        })
    },

    mounted() {
        this.$store.dispatch("employee/fetchEmployeeList");
        this.$store.dispatch("project/fetchProject");
        this.$store.dispatch("requisition/fetchRequisitionType")
        this.$store.dispatch("requisition/fetchRequisitionDetail", this.$route.params.id).then(() => {
            this.$store.dispatch("requisition/fetchRequisitionPaymentByRequisition", this.detail.id)
        });
    },

    watch: {
        detail(oldValue, newValue) {
            this.requisition.id = this.detail.id
            this.requisition.title = this.detail.title
            if(this.detail.type) {
                this.requisition.type = this.detail.type.id
            } else {
                this.requisition.type = this.detail.type
            }
            this.requisition.details = this.detail.details
            this.requisition.total = this.detail.total
            this.requisition.grand_total = this.detail.grand_total
            this.requisition.note = this.detail.note
            this.requisition.project = this.detail.project.id
            this.assignedMembers = this.detail.refering_to
        }
    },

    methods: {
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            } else {
                return text;
            }
        },
        handleAttachOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleAttahmentSubmit()
        },

        handleAttahmentSubmit() {
            this.add_attachment.requisition_id = this.$route.params.id
            this.$store.dispatch("requisition/addRequistionAttachment", {
                attachment: this.add_attachment,
                requisition_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.add_attachment.requisition_attachment = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-scrollable-add-document')
            })
        },
        deleteAttach(id) {
            this.$store.dispatch("requisition/deleteAttachmentFromRequisition", {
                id: id,
                requisition_id: this.$route.params.id,

            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },
        uploadAttachFile(e) {
            this.add_attachment.requisition_attachment = e.target.files[0]

        },
        updateItems(data) {
            if (data) {
                this.requisition.details = data;
            }
        },

        onSubmit() {
            this.assignedMembers.map(item => this.requisition.refering_to.push(item.id));

            this.$store.dispatch("requisition/updateRequisition", this.requisition).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
                setTimeout(() => this.$router.push('/my-workspace/my-requisition'), 2000)
            })
        },

        resetModal() {
            this.name = ''
            this.nameState = null
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            // Push the name to submitted names
            alert(JSON.stringify(this.client))

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        }
    }
};
</script>

<style scoped>
.content-container-attahment {
    /* background-color: white; */
    padding: 16px;
}
</style>
